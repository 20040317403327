import { ModulePageConfig } from '@libs/common';

export const analysisModuleConfig: ModulePageConfig = {
    ...new ModulePageConfig(),
    enableAqi: false,

    enableMap: true,
    enableTopElements: false,
    fullScreen: true,
    mapClassName: 'analysis',
};
