import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { getAssetPath } from '@cityair/config';

@Component({
    selector: 'indoor-faq',
    templateUrl: './indoor-faq.component.html',
    styleUrls: ['./indoor-faq.component.less'],
})
export class IndoorFaqComponent implements OnInit {
    @Output() closeModal = new EventEmitter<void>();

    selectedSection = 0;

    canClose = false;

    menuItems = [
        'How do we calculate Indoor Air Quality Index?',
        'The system calculates an index for each pollutant',
        'Air pollutant concentrations and index levels',
        'IAQI formula',
        'Why PM and CO2 data is important?',
    ];

    ngOnInit() {
        setTimeout(() => (this.canClose = true), 100); // for fix clickOutside
    }

    close() {
        if (this.canClose) this.closeModal.emit();
    }

    showSection(index: number) {
        this.selectedSection = index;
    }

    getAsset = getAssetPath;
}
