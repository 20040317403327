export enum ANALYSIS_PAGES {
    analysis = 'analysis',
    summary = 'summary',
    links = 'links',
    posts = 'posts',
}
export const ANALYSIS_INIT_PAGE = ANALYSIS_PAGES.summary;
export enum AnalysisIntervalType {
    day = '1d',
    month = '1m',
    quarter = '3m',
    halfYear = '6m',
    year = '1y',
}
