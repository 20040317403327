<div class="main-analysis">
    <div class="ca-header-wrapper with-tabs">
        <div class="header">
            <header-of-panel
                [title]="textAnalysis.mainTitle"
            ></header-of-panel>
        </div>
        <ca-page-tabs
            [tabs]="tabs"
            [selectedTab]="getActiveTab()"
            (showTab)="openPage($event)"
        ></ca-page-tabs>
    </div>
    <div class="analysis-body">
        <router-outlet></router-outlet>
    </div>
</div>
