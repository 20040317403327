<div class="summary-wrapper scroll">
     <div *ngIf="store.select(selectIsLoadingData) | async; else contentTemplate" style="padding-top: 60px">
        <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
    </div>
    <ng-template #contentTemplate>
        <div class="content">
            <ng-container *ngIf="store.select(selectErrorLoadCorrelationData) | async; else bodyTemplate">
                <div class="load-error-message" [innerHtml]="text.correlation.notFoundCorrelationData"></div>
            </ng-container>
            <ng-template #bodyTemplate>
                <div class="body">
                    <div class="density-block" *ngIf="store.select(selectNetworkAnalysisData) | async as networkData">
                        <div class="wrapper">
                            <div class="header">
                                <div>{{text.summary.header}}</div>
                                <ca-info-icon class="icon-info" [withGradient]="true" (click)="showInfoPopup = !showInfoPopup"></ca-info-icon>
                            </div>
                            <div class="percent-block">
                                <div class="status">
                                    <div class="title">{{ networkData | networkDensity : text.summary.density }}</div>
                                    <div class="desc" *ngIf="networkData.stations_count && networkData.network_area_total">
                                        {{postCountText(networkData.stations_count)}} {{textMonitoring}}
                                        <span [innerHtml]="text.summary.squareText(formatSquare(networkData.network_area_total))"></span></div>
                                </div>
                                <div class="value-block">
                                    <div class="value" *ngIf="networkData.stations_coverage > 1 else persent">
                                        {{100 | number}}
                                    </div>
                                    <ng-template #persent>
                                          <div class="value"> {{networkData.stations_coverage * 100 | number}}</div>
                                    </ng-template>
                                    <div class="percent">%</div>
                                </div>
                            </div>
                            <div class="distance" *ngIf="networkData.stations_avg_min_distance">
                                <div class="value">{{networkData.stations_avg_min_distance * 1000 | distance : currentLang }}</div>
                                <div class="desc">{{text.summary.averageDist}}</div>
                            </div>
                            <div class="density-status" *ngIf="networkData?.recommendations?.length">
                                <div *ngIf="networkData?.recommendations?.length > 1; else oneStatusTempale">
                                    {{text.summary.recommendTextTitle}}:
                                    <ul>
                                        <li *ngFor="let item of networkData.recommendations">{{text.summary.recommendByKey[item]}}</li>
                                    </ul>
                                </div>
                                <ng-template #oneStatusTempale>
                                    <div *ngIf="networkData?.recommendations?.length === 1">
                                        {{text.summary.recommendTextTitle}} {{text.summary.recommendByKey[networkData?.recommendations[0]]}}</div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div class="table" *ngIf="store.select(selectBedCorrelationPosts) |async as postInfo">
                        <div class="attention-wrapper">
                            {{text.summary.infoAttention}}
                        </div>
                        <div class="date">
                            <div class="interval-text" *ngIf="store.select(selectAnalysisDateRange) | async as dateRange">
                                {{text.summary.byPeriod}}&nbsp;
                                <span>
                                    {{{start: dateRange.startDate, end: dateRange.finishDate} | dateRangeText : true}}
                                </span>
                                <div *ngIf="store.select(selectCurrentMmt)| async as mmt">&nbsp;{{text.correlation.onMMt}}
                                    <span class="param" [innerHtml]="textNames[mmt]"></span>
                                </div>
                            </div>
                        </div>
                        <div class="table-header">
                            <div class="name">
                                {{text.summary.nameHeader}}
                            </div>
                            <div class="distance">
                                 {{text.summary.distHeader}}
                            </div>
                        </div>
                        <div class="post-wrapper" *ngFor="let post of postInfo"
                            (click)="selectPost(post)"
                            (mouseenter)="onMouseEnterPost(post.id)"
                            (mouseleave)="onMouseLeavePost(post.id)"
                        >
                            <div class="name">
                                <div class="post-name" *ngIf="store.select(selectPostNameById(post.id))|async as name"
                                >
                                    {{name}}
                                </div>
                                <div class="post-location" *ngIf="store.select(selectPostParentNameIfNeed(post.id)) |async as parent"
                                >
                                    {{parent}}
                                </div>
                            </div>
                            <div class="dist" *ngIf="store.select(selectNearistDistanceByPost(post.id))|async as dist">
                                {{dist | distance : currentLang}}
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </ng-template>
</div>
<div class="popup-wrapper" *ngIf="showInfoPopup">
    <div class="density-popup">
        <cross-button
            class="close"
            size="large"
            (click)="showInfoPopup=false"
        ></cross-button>
        <div class="title" [innerHTML]="text.summary.header"></div>
        <div class="desc">{{text.summary.popup.desc}}</div>
        <div class="table"  style="padding-bottom: 40px">
            <div class="row">
                <div class="item grey"
                     [ngClass]="{ 'last': last }"
                     *ngFor="let item of text.summary.density; let last=last;"
                >
                    {{item}}
                </div>
            </div>
            <div class="row">
                <div class="item"
                     [ngClass]="{ 'last': last }"
                     *ngFor="let item of text.summary.popup.increaseValue; let last=last;"
                >
                    {{item}}
                </div>
            </div>
        </div>
        <div class="title">{{text.summary.popup.subTitle}}</div>
        <div class="desc">{{text.summary.popup.subDesc}}</div>
        <div class="table">
            <div class="row">
                <div class="item grey"
                     [ngClass]="{ 'last': last }"
                     *ngFor="let item of text.summary.popup.averageStatus; let last=last;"
                >
                    {{item}}
                </div>
            </div>
            <div class="row">
                <div class="item"
                     [ngClass]="{ 'last': last }"
                     *ngFor="let item of text.summary.popup.averageDist;  let last=last;"
                >
                    {{item}}
                </div>
            </div>
        </div>
    </div>
</div>
