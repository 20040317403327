import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from '@cityair/libs/common/calendar/calendar.module';
import { LittleComponentsModule } from '@libs/shared-ui';
import { PipesModule } from '@libs/shared-ui';
import { DirectivesModule } from '@libs/shared-ui';
import { TooltipsModule } from '@libs/shared-ui';

import { PageTabsModule } from '@libs/shared-ui';
import { CardModule, CardActionsModule } from '@libs/shared-ui';
import { InputModule } from '@libs/shared-ui';
import { LoadersModule } from '@libs/shared-ui';
import { ButtonAddModule } from '@libs/shared-ui';
import { CityscreenComponentsModule } from '@cityair/components/cityscreen-components.module';
import { OverlayModule } from '@libs/shared-ui';
import { SwitchItemModule } from '@libs/shared-ui';
import { KebabMenuModule } from '@libs/shared-ui';
import { ColorLegendModule } from '@libs/shared-ui';
import { SwitchButtonModule } from '@libs/shared-ui';
import { InfoIconModule } from '@libs/shared-ui';
import { SortListItemModule } from '@libs/shared-ui';
import { ButtonModule } from '@libs/shared-ui';
import { ButtonsSaveCancelModule } from '@libs/shared-ui';
import { MapControlModule } from '@libs/shared-ui';
import { MmtSelectorModule } from '@libs/shared-ui';
import { CrossButtonModule } from '@libs/shared-ui';
import { InputForDropdownModule } from '@libs/shared-ui';
import { InputDropdownModule } from '@libs/shared-ui';
import { SelectboxRadioModule } from '@libs/shared-ui';
import { MapStyleSelectorComponent } from '@libs/shared-ui';
import { NonDataScreenModule } from '@libs/shared-ui';
import { NetworkMapComponent } from '@cityair/modules/network/components/network-map/network-map.component';
import { NetworkMainComponent } from '@cityair/modules/network/components/network-main/network-main.component';
@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        DirectivesModule,
        LittleComponentsModule,
        CardModule,
        TooltipsModule,
        ButtonAddModule,
        CalendarModule,
        PageTabsModule,
        CardActionsModule,
        InputModule,
        LoadersModule,
        CityscreenComponentsModule,
        OverlayModule,
        SwitchItemModule,
        KebabMenuModule,
        ColorLegendModule,
        ButtonModule,
        ButtonsSaveCancelModule,
        SwitchButtonModule,
        MapControlModule,
        MmtSelectorModule,
        InputForDropdownModule,
        InputDropdownModule,
        SelectboxRadioModule,
        MapStyleSelectorComponent,
        NonDataScreenModule,
        InfoIconModule,
        CrossButtonModule,
        SortListItemModule,
    ],
    exports: [NetworkMainComponent, NetworkMapComponent],
    declarations: [NetworkMainComponent, NetworkMapComponent],
})
export class NetworkModule {}
