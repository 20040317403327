import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import moment from 'moment-timezone';
import { ColorZone, MAIN_PAGES } from '@libs/common';
import { IMPACT_PAGES } from '@cityair/modules/impact/models';
import {
    ControlPointImpact,
    DateRangeImpact,
    SourceImpact,
    TEXTS,
    CheckboxItem,
} from '@libs/common';
import {
    ImpactActions,
    selectControlPoints,
    selectIsInitControlPointPage,
    selectMmtsListByControlPoint,
} from '@cityair/modules/impact/store/impact.feature';
import {
    ImpactStatActions,
    selectExcessStatistics,
    selectDateRangeControlPoint,
    selectIsLoadingStatControlPoint,
    selectImpactStatsPointsSchemaZones,
} from './../../../../../store/statistic.feature';
import { selectCurrentMeasureScheme, selectExtConfig } from '@libs/shared-store';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { MAX_CONCENTRATION_DEMO_DATA } from '@cityair/modules/impact/consts';
import MapboxActions from '@cityair/modules/map/components/mapbox/mapboxActions';

@Component({
    selector: 'control-point-detail',
    templateUrl: './control-point-detail.component.html',
    styleUrls: ['./control-point-detail.component.less'],
})
export class ControlPointDetailComponent implements OnInit, OnDestroy {
    public textImpact = TEXTS.IMPACT;
    public textNames = TEXTS.NAMES;
    public textsScheme = TEXTS.MEASURES_SCHEME;
    public currentMeasure: string;
    public calendarIsOpened = false;
    public controlPoint: ControlPointImpact;
    public sources: SourceImpact[];
    handleUpdateDays: (numberOfFutureDays?: number, minDate?: string) => void;
    public numberOfFutureDays = 1;
    public dateRange: DateRangeImpact;
    public dateTimeBegin: number;
    public dateTimeEnd: number;
    public minDate: string;
    public ngDestroyed$ = new Subject<void>();
    public mmtList: CheckboxItem[] = [];
    public selectedSourceMmt: CheckboxItem;
    public isShowDropdownForMmtList: boolean = false;
    selectMeasureScheme = selectCurrentMeasureScheme;
    selectControlPoints = selectControlPoints;
    isLoadingStatControlPoint = selectIsLoadingStatControlPoint;
    selectExcessStatistics = selectExcessStatistics;
    loading = true;
    currentId: number;
    sourcesOrder: number[];
    MAX_CONCENTRATION_DEMO_DATA = MAX_CONCENTRATION_DEMO_DATA;
    zone: ColorZone;

    constructor(
        public store: Store,
        private _changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private route: ActivatedRoute,
        private mapboxActions: MapboxActions
    ) {
        this.currentId = Number(this.route.snapshot.paramMap.get('id'));
        if (this.currentId) {
            this.store
                .select(selectExtConfig)
                .pipe(
                    takeUntil(this.ngDestroyed$),
                    filter((data) => data !== null),
                    take(1)
                )
                .subscribe((data) => {
                    if (data?.impactSettings?.startDate) {
                        this.minDate = data.impactSettings.startDate;
                    }
                });
            this.store
                .select(selectIsInitControlPointPage)
                .pipe(
                    takeUntil(this.ngDestroyed$),
                    filter((data) => data !== null),
                    take(1)
                )
                .subscribe((data) => {
                    this.controlPoint = data?.controlPoints.find((v) => v.id === this.currentId);
                    this.sources = data?.sources;
                    if (this.controlPoint) {
                        this.loading = false;
                        this.store.dispatch(
                            ImpactStatActions.setControlPoint({ payload: this.controlPoint })
                        );
                        this.store.dispatch(
                            ImpactActions.setActivePin({ payload: this.controlPoint.id.toString() })
                        );
                        this.mapboxActions.centringOnMarker(
                            this.controlPoint.lat,
                            this.controlPoint.lon,
                            false
                        );
                    } else {
                        this.backToPoints();
                    }
                    _changeDetectorRef.markForCheck();
                });
        } else {
            this.backToPoints();
        }
        this.store
            .select(selectDateRangeControlPoint)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((dates) => {
                this.dateRange = dates;
                this.dateTimeBegin = moment(this.dateRange.start).valueOf();
                this.dateTimeEnd = moment(this.dateRange.end).valueOf();
            });
        this.store
            .select(selectImpactStatsPointsSchemaZones)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.zone = data?.zone;
            });
    }

    ngOnInit(): void {
        this.store
            .select(selectMmtsListByControlPoint)
            .pipe(
                takeUntil(this.ngDestroyed$),
                filter((v) => v !== null)
            )
            .subscribe(({ list, mmt }) => {
                if (list?.length) {
                    list.map((item) =>
                        this.mmtList.push({
                            id: item,
                            label: this.textNames[item],
                            selected: item === mmt,
                            value: item,
                        })
                    );
                    this.selectedSourceMmt = this.mmtList.find((v) => v.selected);
                    this._changeDetectorRef.markForCheck();
                }
            });
    }

    ngOnDestroy(): void {
        this.ngDestroyed$.next();
        this.ngDestroyed$.complete();
        this.store.dispatch(ImpactStatActions.setExcessStatistics({ payload: null }));
        this.store.dispatch(ImpactStatActions.setControlPoint({ payload: null }));
    }

    toggleCalendar() {
        if (this.calendarIsOpened) {
            if (this.minDate) {
                this.handleUpdateDays(this.numberOfFutureDays, this.minDate);
            } else {
                this.handleUpdateDays(this.numberOfFutureDays);
            }
        }
    }

    public updateDateRange = (begin: number, finish: number) => {
        this.calendarIsOpened = false;
        const start = moment(begin).startOf('day').toISOString();
        const end = moment(finish).endOf('day').toISOString();
        this.store.dispatch(
            ImpactStatActions.updateDateRangeControlPoint({ payload: { start, end } })
        );
    };

    updateDays = (fn: (numberOfFutureDays?: number, minDate?: string) => void) =>
        (this.handleUpdateDays = fn);

    selectSourceMmt(list: CheckboxItem[]) {
        const selectedMmt = list.find((v) => v.selected)?.value;
        this.store.dispatch(
            ImpactStatActions.updateControlPointMmt({
                payload: selectedMmt,
            })
        );
    }

    backToPoints() {
        this.router.navigate([
            `/${MAIN_PAGES.impact}/${IMPACT_PAGES.controlPoints}/${IMPACT_PAGES.points}`,
        ]);
    }
}
