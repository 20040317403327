<div class="timeline-player">
    <div class="timeline-container__wrapper">
        <div class="timeline-container">
            <div class="close-btn" data-cy="close-chart" (click)="closeChart()" *ngIf="chartEnabled">
                {{ textClose }}
                <cross-button style="padding-top: 2px"
                  [size]="'small'"
                  [color]="'#ffffff'"
                  [needHover]="false"
                ></cross-button>
            </div>
            <data-list
                [isCityMode]="isCityMode"
                [timeIndex]="timeIndex"
                [data]="pointSeriesData"
                [showCompare]="comparisonEnabled"
                [isCompare]="isComparisonModeActive"
                [groupTooltip]="groupTooltip"
                [labelMode]="labelMode"
                [pdk]="pdk"
                [aqiTooltipTemplate]="aqiTooltipTemplate"
                [initSelectMeasurement]="initSelectMeasurement"
                [measureScheme]="measureScheme"
                (removeFromComparison)="removeFromComparison.emit($event)"
                (goToCity)="goCity($event)"
                (setCompareMode)="setCompareMode.emit()"
                (setMmts)="updateMmts($event)"
            ></data-list>
            <ca-chart-timeline
                [timeIndex]="timeIndex"
                [data]="pointSeriesData"
                [showGridLines]="true"
                [mmts]="selectedMeasurements"
                [chartMinMax]="chartMinMax"
                [measureScheme]="measureScheme"
                [hasDataByIndex]="hasDataByIndex"
                [qualityDataMode]="qualityDataMode"
                [dataQuality]="dataQualityTimeline"
                (setPosition)="onTrackClick($event)"
                (sizeUpdate)="updateChartSize($event)"
            >
                <!-- Project legacy buttons -->
                <ng-content></ng-content>
            </ca-chart-timeline>
            <div class="panel-with-chart">
                <div
                    class="panel-wrapper"
                    [style.width.px]="(charSize$ | async)?.width"
                    [style.marginLeft.px]="(charSize$ | async)?.left"
                >
                    <ca-track-panel
                        #timelineTrack
                        [dates]="dateArray"
                        [index]="timeIndex"
                        [hasDataByIndex]="hasDataByIndex"
                        [chartEnabled]="chartEnabled && !!this.pointSeriesData?.length"
                        [showNow]="showNow"
                        [qualityDataMode]="qualityDataMode"
                        [dataQuality]="dataQualityTimeline"
                        [timezone]="timezone"
                        (setPosition)="onTrackClick($event)"
                        (showQualityDataInfo)="setShowQualityDataInfo($event)"
                    ></ca-track-panel>

                    <ca-time-runner
                        class="timeline-player__runner"
                        #timelineRunner
                        [time]="currentTimeStrs"
                        [isLastTime]="showGoTOEnd()"
                        [ngClass]="{'timeline-player__runner--extended': chartEnabled && !!this.pointSeriesData?.length }"
                        [style.visibility]="positionPercent >= 0 ? 'visible' : 'hidden'"
                        [style.left.%]="positionPercent"
                        [flagDirection]="dateArray?.length / 2 < timeIndex ? 'left' : 'right'"
                        [chartEnabled]="chartEnabled && !!this.pointSeriesData?.length"
                        (goToEnd)="goToEndTime()"
                        (mousedown)="dragStart($event)"
                        (touchstart)="dragStart($event)"
                    ></ca-time-runner>

                    <ca-button-play
                        class="timeline-player__play-button"
                        *ngIf="showPlayButton && playerState$ | async as playerState"
                        [state]="playerState.playing ? 'pause' : 'play'"
                        [loading]="playerState.loading"
                        [loadingProgress]="playerState.progress"
                        (click)="playPause(playerState.playing, playerState.loading)"
                    ></ca-button-play>
                </div>
            </div>
        </div>
    </div>
</div>
