import type {
    DomainConfigType,
    DataType,
    TileType,
} from '@cityair/modules/map/components/mapbox/domain-tiles-player/domain-config.type';
import { Substance } from '@cityair/modules/map/components/mapbox/domain-tiles-player/substance.enum';

export class DomainConfig {
    dateFormat = 'YYYYMMDD_HHmmss';

    timeStep = 3600 * 1000; // 1 hour

    opacity = 0.5;

    url?: string;

    minzoom?: number;

    maxzoom?: number;

    domain: DomainConfigType;

    altitudeIndex = '0';

    constructor(options: Partial<DomainConfig> & { domain: DomainConfigType }) {
        Object.assign(this, options);
        if (options?.domain?.altitudeIndex) {
            this.altitudeIndex = options?.domain?.altitudeIndex;
        }
    }

    getImagePath(
        substance: Substance,
        dataType: DataType,
        tileType: TileType,
        altitudeIndex: string
    ) {
        return [this.url, this.domain.slug, dataType, tileType, altitudeIndex, substance].join('/');
    }
}
