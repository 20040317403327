import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { setCurrentTab, setPostHover } from '@cityair/modules/analysis/store/actions';
import { ANALYSIS_PAGES } from '@cityair/modules/analysis/models';
import { selectPostNameById, selectPostParentNameIfNeed } from '@libs/shared-store';
import {
    selectAnalysisDateRange,
    selectBedCorrelationPosts,
    selectErrorLoadCorrelationData,
    selectIsLoadingData,
    selectCurrentMmt,
    selectNearistDistanceByPost,
    selectNetworkAnalysisData,
} from '@cityair/modules/analysis/store/selectors';
import { LANGUAGE, TEXTS } from '@libs/common';
import { Router } from '@angular/router';
import { NgLocalization } from '@angular/common';
import { ValueFormatNumberPipe } from '@libs/shared-ui';

@Component({
    selector: 'cityair-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryComponent {
    text = TEXTS.ANALYSIS;
    textMonitoring = TEXTS.ANALYSIS_EVENTS.monitoring;
    public textNames = TEXTS.NAMES;
    public currentLang = LANGUAGE;
    showInfoPopup = false;
    selectIsLoadingData = selectIsLoadingData;
    selectErrorLoadCorrelationData = selectErrorLoadCorrelationData;
    selectCurrentMmt = selectCurrentMmt;
    selectAnalysisDateRange = selectAnalysisDateRange;
    selectBedCorrelationPosts = selectBedCorrelationPosts;
    selectNearistDistanceByPost = selectNearistDistanceByPost;
    selectPostNameById = selectPostNameById;
    selectPostParentNameIfNeed = selectPostParentNameIfNeed;
    selectNetworkAnalysisData = selectNetworkAnalysisData;

    constructor(
        readonly store: Store,
        private router: Router,
        private ngLocalization: NgLocalization,
        private numberFormatPipe: ValueFormatNumberPipe
    ) {
        this.store.dispatch(setCurrentTab({ payload: ANALYSIS_PAGES.summary }));
    }

    selectPost(post) {
        this.router.navigate([`/${ANALYSIS_PAGES.analysis}/${ANALYSIS_PAGES.posts}/${post.id}`]);
    }

    onMouseEnterPost(id: string) {
        this.store.dispatch(setPostHover({ payload: [id] }));
    }

    onMouseLeavePost(id: string) {
        this.store.dispatch(setPostHover({ payload: [] }));
    }

    public postCountText(num: number = 0) {
        const { post } = TEXTS.NOTIFICATIONS;
        const category = this.ngLocalization.getPluralCategory(num, LANGUAGE);
        return [num, post[category]].join(' ');
    }

    formatSquare(val: number) {
        return this.numberFormatPipe.transform(val, 1);
    }

    @HostListener('window:keydown.esc', ['$event'])
    handleKeyDownESC(event: KeyboardEvent) {
        if (this.showInfoPopup) {
            this.showInfoPopup = false;
        }
    }
}
