import { Injectable } from '@angular/core';
import { Actions as NgRxActions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { concatMap, forkJoin, iif, interval, Observable, of } from 'rxjs';
import {
    catchError,
    filter,
    map,
    switchMap,
    take,
    takeUntil,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import {
    changeQualityDataMode,
    cityModeOff,
    clickFromApToMo,
    clickOnCityMarker,
    destroyMainMap,
    doNothing,
    initMainMap,
    intervalUpdate,
    isLoadingTimeline,
    loadCity,
    loadQualityData,
    mapMarkerClick,
    removeFromComparison,
    selectCity,
    setAllMmts,
    setChartData,
    setCityMode,
    setComparisonMode,
    setGlobalMeasurement,
    setQualityData,
    setQualityDataMarkers,
    setTimelineDateTimes,
    setTypeInterval,
    setWorldMode,
    timelineLoaded,
    updateChartData,
    updateTimeRangeData,
    updateUserSettings,
} from './actions';
import {
    AppState,
    getIntervalUpdateData,
    getDataParams,
    isCompareMode,
    selectIsCityMode,
    selectNearestCity,
    selectParamsForTimeline,
    selectParamsForTimelineOne,
    selectQualityDataMode,
    selectQualityDataPostId,
    defaultMapIsActive,
} from './selectors';

import {
    selectAllPosts,
    getActionBasicError,
    selectAllCities,
    SharedGroupActions,
    SharedPostActions,
    selectCurrentGroup,
    selectGroupId,
    selectExtConfig,
    SharedCoreActions,
    getDefaultMmt,
} from '@libs/shared-store';
import {
    detectMobile,
    IQualityDataParams,
    IExtraTimelineResponse,
    DataObjType,
    InfoMessage,
    MINUTE5_MS,
    GLOBAL_ZOOM_LEVEL,
    getAllMeasurements,
    DEFAULT_AQI_TYPE,
    AQI_IN_ANALYTICS_GROUP_IDS,
    IntervalEnum,
} from '@libs/common';

import { onIsEnabledChart, setMainChartChartLoading } from '@libs/shared-ui';
import {
    clearCompareList,
    compareListRemove,
    compareListAddUnique,
} from './compared-list/compared-list.actions';
import {
    isComparedListLimited,
    selectComparedItems,
} from './compared-list/compared-list.selectors';
import {
    clearCurrentCity,
    selectCurrentCity,
    setCurrentCity,
} from './current-city/current-city.feature';
import {
    isLoadingSummaryData,
    loadSummaryData,
    selectAllSummaryDic,
    setCityCard,
    setCitySummary,
} from '@libs/shared-store';
import MapboxActions from '@cityair/modules/map/components/mapbox/mapboxActions';
import { actionsBeforeCompare } from './helpers/actions-before-compare';
import { getShiftMapCityCard, getShiftMapMobile } from '@cityair/config';
import { DataQualityApi } from '@cityair/modules/core/services/api/data-quality-api';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { BasicApiService } from '@libs/shared-store';
import { currentEvent } from '@cityair/modules/notifications/store/selectors';
import {
    selectMapCenter,
    selectMapZoom,
    setMapCenter,
    setMapZoom,
} from '@cityair/modules/core/store/map/map.feature';

@Injectable()
export class CommonEffects {
    constructor(
        private actions$: NgRxActions,
        private dataQualityApi: DataQualityApi,
        private basicApi: BasicApiService,
        private store: Store<AppState>,
        private mapActions: MapboxActions
    ) {}

    _startInterval$ = createEffect(() =>
        this.actions$.pipe(
            ofType(initMainMap),
            switchMap(() =>
                interval(MINUTE5_MS).pipe(takeUntil(this.actions$.pipe(ofType(destroyMainMap))))
            ),
            switchMap(() => this.store.select(getIntervalUpdateData).pipe(take(1))),
            switchMap(({ isAllowUpdate, newTimeRange }) => {
                if (isAllowUpdate)
                    return [
                        intervalUpdate({
                            begin: newTimeRange.begin,
                            end: newTimeRange.end,
                        }),
                    ];

                return [doNothing()];
            })
        )
    );

    changeInterval$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SharedGroupActions.groupInfoLoad),
            withLatestFrom(this.store.select(selectCurrentGroup)),
            filter(
                ([_, group]) => group !== null && AQI_IN_ANALYTICS_GROUP_IDS.includes(group?.id)
            ),
            map(() => setTypeInterval({ payload: IntervalEnum.hour }))
        )
    );

    isEnabledChartOff$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onIsEnabledChart),
            filter(({ payload }) => !payload),
            switchMap((_) => [clearCompareList()])
        )
    );

    onRemoveFromComparison = createEffect(() =>
        this.actions$.pipe(
            ofType(removeFromComparison),
            withLatestFrom(
                this.store.select(selectComparedItems),
                this.store.select(selectQualityDataMode)
            ),
            switchMap(([{ id }, comparedItems, dataQualityMode]) => {
                const actions = [];

                const newSelected = comparedItems.filter((item) => item.id != id);
                if (!newSelected.length) {
                    actions.push(setComparisonMode({ payload: false }));
                    actions.push(onIsEnabledChart({ payload: false }));
                } else if (
                    dataQualityMode &&
                    newSelected.length === 1 &&
                    newSelected[0].obj === DataObjType.post
                ) {
                    actions.push(loadQualityData({ id: newSelected[0].id }));
                }

                return [...actions, compareListRemove({ id: id })];
            })
        )
    );

    setDefaultMeasurement$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SharedCoreActions.setGroupInfo),
            withLatestFrom(this.store.select(defaultMapIsActive)),
            // filter(([_, isActive]) => isActive),
            switchMap(([{ payload, currentGroup, myRole }, isActive]) => {
                const allMeasurements = getAllMeasurements(payload[0]?.data, currentGroup);
                const defaultMmt = getDefaultMmt(DEFAULT_AQI_TYPE, currentGroup, allMeasurements);
                return [
                    setAllMmts({ payload: allMeasurements }),
                    setGlobalMeasurement({ mmt: defaultMmt }),
                ];
            })
        )
    );

    getTimelineData = createEffect(() =>
        this.actions$.pipe(
            ofType(
                SharedCoreActions.setGroupInfo,
                setGlobalMeasurement,
                updateTimeRangeData,
                setTypeInterval
            ),
            withLatestFrom(this.store.select(selectParamsForTimelineOne)),
            filter(([_, data]) => data !== null),
            tap(() => this.store.dispatch(isLoadingTimeline({ payload: true }))),
            switchMap(([action, { groupId, params }]) =>
                this.basicApi.getDataTimeline(groupId, params).pipe(
                    switchMap((response) => [
                        isLoadingTimeline({ payload: false }),
                        timelineLoaded({ payload: response }),
                    ]),
                    catchError((errorResponse: HttpErrorResponse) => {
                        if (errorResponse?.status === HttpStatusCode.Forbidden) {
                            location.reload();
                        } else {
                            const errorAction = getActionBasicError(errorResponse);
                            return of(errorAction, isLoadingTimeline({ payload: false }));
                        }
                    })
                )
            )
        )
    );

    updateDates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(timelineLoaded),
            map((action) => {
                const extraResponse = action.payload.meta.extra as IExtraTimelineResponse;
                const dates = extraResponse?.dates ?? [];
                return setTimelineDateTimes({ dateTimes: dates });
            })
        )
    );

    cityModeOff$ = createEffect(() =>
        this.actions$.pipe(
            ofType(cityModeOff),
            switchMap((action) => [
                clearCurrentCity(),
                setComparisonMode({ payload: false }),
                setWorldMode(),
            ])
        )
    );

    clearChartData = createEffect(() =>
        this.actions$.pipe(
            ofType(setComparisonMode),
            filter((action) => !action.payload),
            map((action) => setChartData({ payload: null, feature: null }))
        )
    );

    selectCity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectCity),
            withLatestFrom(this.store.select(selectAllCities)),
            switchMap(([{ cityId, centringMap }, cities]) => {
                const actions = [];
                const city = cities.find((c) => c.id === cityId);
                actions.push(setCityMode());

                if (city) {
                    actions.push(setCurrentCity({ city }));
                    if (centringMap) {
                        this.mapActions.centringMap(city, true);
                    }
                }

                return actions;
            })
        )
    );

    loadCity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadCity),
            switchMap(({ cityId, centringMap }) => [selectCity({ cityId, centringMap })])
        )
    );

    clickFromApToMo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clickFromApToMo),
            withLatestFrom(this.store.select(selectIsCityMode), this.store.select(selectAllPosts)),
            switchMap(([{ moObjId }, isCityMode, markers]) => {
                const actions = [];

                const marker = markers.find((el) => el.id === moObjId);

                if (marker) {
                    if (!isCityMode && marker.ancestor?.id) {
                        actions.push(loadCity({ cityId: marker.ancestor.id, centringMap: true }));
                    }

                    actions.push(mapMarkerClick({ markerId: marker.id }));

                    this.mapActions.centringOnMarker(
                        marker.geometry.coordinates[1],
                        marker.geometry.coordinates[0],
                        true,
                        getShiftMapCityCard()
                    );
                }

                return actions;
            })
        )
    );

    mapMarkerClick$ = createEffect(() =>
        this.actions$.pipe(
            ofType(mapMarkerClick),
            withLatestFrom(
                this.store.select(selectComparedItems),
                this.store.select(isComparedListLimited),
                this.store.select(isCompareMode),
                this.store.select(selectQualityDataMode),
                this.store.select(selectAllPosts)
            ),
            switchMap(
                ([
                    { markerId },
                    comparedItems,
                    isLimitd,
                    isComparedMode,
                    qualityDataMode,
                    posts,
                ]) => {
                    const actions = [];

                    const marker = posts.find((el) => el.id === markerId);
                    const compareActions = actionsBeforeCompare(
                        marker,
                        comparedItems,
                        isLimitd,
                        isComparedMode
                    );
                    if (compareActions.delete) {
                        actions.push(removeFromComparison({ id: markerId }));
                    }

                    if (compareActions.clearBefore) {
                        actions.push(clearCompareList());
                    }

                    if (!compareActions.add) {
                        return actions;
                    }

                    if (marker.obj === DataObjType.outdoorPost && qualityDataMode) {
                        if (!isComparedMode || comparedItems.length === 0) {
                            actions.push(loadQualityData({ id: marker.id }));
                        } else {
                            actions.push(setQualityData({ payload: [] }));
                        }
                    }
                    actions.push(compareListAddUnique({ objects: [marker] }));
                    this.mapActions.centringOnMarker(
                        marker.geometry.coordinates[1],
                        marker.geometry.coordinates[0],
                        true,
                        detectMobile() ? getShiftMapMobile() : getShiftMapCityCard()
                    );
                    return actions;
                }
            )
        )
    );

    clickOnCityMarker$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clickOnCityMarker),
            withLatestFrom(
                this.store.select(selectComparedItems),
                this.store.select(isComparedListLimited),
                this.store.select(isCompareMode)
            ),
            switchMap(([{ cityMarker }, comparedItems, isLimitd, isComparedMode]) => {
                const actions = [];
                const compareActions = actionsBeforeCompare(
                    cityMarker,
                    comparedItems,
                    isLimitd,
                    isComparedMode
                );

                if (compareActions.delete) {
                    actions.push(removeFromComparison({ id: cityMarker.id }));
                    return actions;
                }

                if (compareActions.clearBefore) {
                    actions.push(clearCompareList());
                }

                if (compareActions.add) {
                    actions.push(compareListAddUnique({ objects: [cityMarker] }));
                }

                if (compareActions.add && detectMobile()) {
                    this.mapActions.centringOnMarker(
                        cityMarker.geometry.coordinates[1],
                        cityMarker.geometry.coordinates[0],
                        true,
                        getShiftMapMobile()
                    );
                }

                return actions;
            })
        )
    );

    getQualityDataMarkers = createEffect(() =>
        this.actions$.pipe(
            ofType(changeQualityDataMode),
            switchMap((response) =>
                this.dataQualityApi.getQualityDataMarkers().pipe(
                    switchMap((response) => [setQualityDataMarkers({ payload: response })]),
                    catchError((error: HttpErrorResponse) => of(doNothing()))
                )
            )
        )
    );

    loadChartData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(compareListAddUnique),
            withLatestFrom(
                this.store.select(selectParamsForTimeline),
                this.store.select(selectExtConfig)
            ),
            filter(([action, data]) => data !== null && !!action.objects[0]?.id),
            tap(() => this.store.dispatch(setMainChartChartLoading({ payload: true }))),
            concatMap(([action, { groupId, params }, extConfig]) => {
                const isCity = action.objects[0]?.obj === DataObjType.city;
                const id = action.objects[0].id;
                const paramsWithGroup = Object.assign({}, params, { group_id: groupId });
                return iif(
                    () => isCity,
                    this.basicApi.getCityDataTimeline(id, paramsWithGroup).pipe(
                        tap(() =>
                            this.store.dispatch(setMainChartChartLoading({ payload: false }))
                        ),
                        map((response) =>
                            setChartData({
                                payload: response,
                                feature: action.objects[0],
                                extConfig,
                            })
                        ),
                        catchError((errorResponse: HttpErrorResponse) => {
                            const errorAction = getActionBasicError(errorResponse);
                            return of(errorAction);
                        })
                    ),
                    this.basicApi.getPostDataTimeline(id, paramsWithGroup).pipe(
                        tap(() =>
                            this.store.dispatch(setMainChartChartLoading({ payload: false }))
                        ),
                        map((response) =>
                            setChartData({
                                payload: response,
                                feature: action.objects[0],
                                extConfig,
                            })
                        ),
                        catchError((errorResponse: HttpErrorResponse) => {
                            const errorAction = getActionBasicError(errorResponse);
                            return iif(
                                () => errorResponse.status !== HttpStatusCode.NotFound,
                                of(errorAction),
                                of(
                                    removeFromComparison({ id: action.objects[0]?.id }),
                                    SharedCoreActions.setInfoMessage({
                                        payload: {
                                            id: new Date().valueOf(),
                                            messageKey: 'notFoundPost',
                                            positionX: 'left',
                                            positionY: 'bottom',
                                            iconClass: 'error',
                                            duration: 10000,
                                            showCloseIcon: true,
                                            size: 'lg',
                                        },
                                    }),
                                    SharedPostActions.updatePostsList()
                                )
                            );
                        })
                    )
                );
            })
        )
    );

    getCitySummary$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setCityCard),
            withLatestFrom(this.store.select(selectAllSummaryDic)),
            filter(([_, data]) => !!_.city?.id && !data[_.city.id]),
            map(([action, summary]) => loadSummaryData({ id: action.city.id }))
        )
    );

    loadSummaryData$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(loadSummaryData),
                withLatestFrom(this.store.select(selectGroupId)),
                filter(([action, groupId]) => !!action.id && !!groupId),
                tap(() => this.store.dispatch(isLoadingSummaryData({ payload: true }))),
                switchMap(([action, groupId]) =>
                    this.basicApi.getSummary(groupId, action.id).pipe(
                        map((response) => setCitySummary({ payload: response?.data })),
                        catchError((errorResponse) => {
                            const errorAction = getActionBasicError(errorResponse);
                            return of(errorAction, isLoadingSummaryData({ payload: false }));
                        })
                    )
                )
            ) as Observable<Action>
    );

    updateChartData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(intervalUpdate, setTypeInterval),
            withLatestFrom(
                this.store.select(selectComparedItems),
                this.store.select(selectParamsForTimeline),
                this.store.select(selectExtConfig),
                this.store.select(currentEvent)
            ),
            filter(
                ([action, compredItems, params, extConfig]) =>
                    !!compredItems.length && params !== null
            ),
            tap(() => this.store.dispatch(setMainChartChartLoading({ payload: true }))),
            switchMap(([actionData, comparedItems, { groupId, params }, extConfig, event]) => {
                const paramsWithGroup = Object.assign({}, params, { group_id: groupId });
                let isExistInCompare = false;
                let postId;

                if (event !== null) {
                    postId = event?.post_id ?? event?.service_excess_info?.post_id;
                    isExistInCompare =
                        comparedItems.find((v) => v.id === postId) === undefined ? false : true;
                }
                const query = comparedItems.map((item) =>
                    item?.obj === DataObjType.city
                        ? this.basicApi.getCityDataTimeline(item.id, paramsWithGroup)
                        : this.basicApi.getPostDataTimeline(item.id, paramsWithGroup)
                );

                return forkJoin(query).pipe(
                    switchMap((res: any) => {
                        const actions = [];
                        actions.push(setMainChartChartLoading({ payload: false }));
                        actions.push(
                            updateChartData({ payload: res, features: comparedItems, extConfig })
                        );
                        if (!isExistInCompare && postId) {
                            actions.push(
                                clickFromApToMo({
                                    moObjId: postId,
                                })
                            );
                        }

                        return actions;
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        const errorAction = getActionBasicError(errorResponse);
                        return of(
                            setMainChartChartLoading({ payload: false }),
                            updateChartData({ payload: null, features: null }),
                            clearCompareList(),
                            SharedPostActions.updatePostsList(),
                            errorAction
                        );
                    })
                );
            })
        )
    );

    updateQualityData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(intervalUpdate, setTypeInterval),
            withLatestFrom(this.store.select(selectQualityDataPostId)),
            switchMap(([_, id]) =>
                iif(
                    () => id !== null,
                    [loadQualityData({ id: id })],
                    [setQualityData({ payload: [] })]
                )
            )
        )
    );

    onIsEnabledChart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setChartData),
            filter((action) => action.feature !== null),
            map((actionData) => onIsEnabledChart({ payload: true }))
        )
    );

    loadQualityDataByPost = createEffect(() =>
        this.actions$.pipe(
            ofType(loadQualityData),
            withLatestFrom(this.store.select(getDataParams)),
            filter(([action, params]) => action?.id !== null && params !== null),
            switchMap(([action, params]) =>
                this.dataQualityApi
                    .getQualityDataTimeline(
                        Object.assign(
                            { post_id: action.id.toString() },
                            params
                        ) as IQualityDataParams
                    )
                    .pipe(
                        withLatestFrom(this.store.select(selectComparedItems)),
                        map(([response, data]) =>
                            setQualityData({ payload: data?.length === 1 ? response : [] })
                        ),
                        catchError((error: HttpErrorResponse) =>
                            of(
                                setQualityData({ payload: [] }),
                                SharedCoreActions.setInfoMessage({
                                    payload: {
                                        id: new Date().valueOf(),
                                        messageKey: 'errorQualityDataTimeline',
                                        positionX: 'left',
                                        positionY: 'bottom',
                                        iconClass: 'error',
                                        duration: 10000,
                                        showCloseIcon: true,
                                        size: 'lg',
                                    },
                                })
                            )
                        )
                    )
            )
        )
    );

    clearChartData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clearCompareList),
            map(() => updateChartData({ payload: null, features: null }))
        )
    );

    mapZoomChanges$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setMapZoom, setMapCenter, SharedCoreActions.setGroupInfo),
            withLatestFrom(
                this.store.select(selectIsCityMode),
                this.store.select(selectNearestCity),
                this.store.select(selectMapZoom),
                this.store.select(selectMapCenter),
                this.store.select(selectCurrentCity)
            ),
            switchMap(([action, isCityMode, city, zoom, center, currentCity]) => {
                const actions = [];
                if (zoom < GLOBAL_ZOOM_LEVEL - 1 && isCityMode) {
                    actions.push(cityModeOff());
                }

                if (
                    (!isCityMode && zoom >= GLOBAL_ZOOM_LEVEL - 1 && city) ||
                    ((isCityMode || zoom >= GLOBAL_ZOOM_LEVEL - 1) &&
                        city &&
                        currentCity &&
                        city.id !== currentCity.id)
                ) {
                    actions.push(loadCity({ cityId: city.id, centringMap: false }));
                }

                return actions;
            })
        )
    );

    saveUserSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateUserSettings),
            switchMap((action) =>
                this.basicApi.updateUserSettings(action.payload).pipe(
                    switchMap((response) => {
                        location.reload();
                        return [];
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        if (errorResponse?.status === HttpStatusCode.Forbidden) {
                            location.reload();
                        } else {
                            const errorAction = getActionBasicError(errorResponse);
                            return of(errorAction);
                        }
                    })
                )
            )
        )
    );
}
export function getShowInfoParams(key, isCritical?): InfoMessage {
    if (isCritical) {
        return {
            messageKey: key,
            fullScreen: true,
        } as InfoMessage;
    }
    return {
        id: new Date().valueOf(),
        messageKey: key,
        positionX: 'left',
        positionY: 'bottom',
        iconClass: 'error',
        duration: 10000,
        showCloseIcon: true,
        size: 'lg',
    } as InfoMessage;
}
