import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { CorrelationPeriod, CheckboxItem, TEXTS } from '@libs/common';
import {
    selectCurrentPeriod,
    selectAllMeasurements,
    selectAnalysisDateRange,
    selectCurrentMmt,
} from '@cityair/modules/analysis/store/selectors';
import { setPeriod, setCurrentMmt } from '@cityair/modules/analysis/store/actions';
import { AuthService, selectSidebarIsOpen } from '@libs/shared-store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'cityair-analysis-map-elements',
    templateUrl: './analysis-map-elements.component.html',
    styleUrls: ['./analysis-map-elements.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalysisMapElementsComponent implements OnDestroy {
    texts = TEXTS.ANALYSIS;
    textsForMapControl = TEXTS.MAP_CONTROL;
    public periodList: CheckboxItem[] = [
        {
            label: this.texts.correlation.dateNames.month,
            id: CorrelationPeriod.month,
            selected: false,
        },
        {
            label: this.texts.correlation.dateNames.quarter,
            id: CorrelationPeriod.quarter,
            selected: false,
        },
        {
            label: this.texts.correlation.dateNames.halfYear,
            id: CorrelationPeriod.halfYear,
            selected: false,
        },
        {
            label: this.texts.correlation.dateNames.year,
            id: CorrelationPeriod.year,
            selected: false,
        },
    ];
    selectedPeriod: CorrelationPeriod;
    public isShowDropdownForPeriod = false;
    public intervalTypeListForCheckbox: CheckboxItem[];
    selectAnalysisDateRange = selectAnalysisDateRange;
    selectIsOpenSidebar = selectSidebarIsOpen;
    selectAllMeasurements = selectAllMeasurements;
    selectCurrentMmt = selectCurrentMmt;
    setCurrentMmt = setCurrentMmt;

    public ngDestroyed$ = new Subject<void>();

    constructor(readonly store: Store, readonly authService: AuthService) {
        this.store
            .select(selectCurrentPeriod)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((period) => {
                if (this.selectedPeriod !== period) {
                    this.selectedPeriod = period;
                    this.periodList = this.updateList(this.periodList, period);
                }
            });
    }

    public updatePeriod($event) {
        const selected = $event.find((v) => v.selected);
        this.selectedPeriod = selected.id;
        this.store.dispatch(setPeriod({ payload: this.selectedPeriod }));
    }

    private updateList(items, selected): CheckboxItem[] {
        const result = [];
        items?.forEach((item) => {
            result.push({
                id: item.id,
                label: item.label,
                selected: item.id === selected,
            });
        });
        return result;
    }

    ngOnDestroy(): void {
        this.ngDestroyed$.next();
    }
}
