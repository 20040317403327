import {
    createAction,
    createFeature,
    createFeatureSelector,
    createReducer,
    createSelector,
    on,
    props,
} from '@ngrx/store';
import { LngLat, LngLatBoundsLike } from 'mapbox-gl';
import { STND_CITY_MAX_ZOOM, STND_GLOBAL_MIN_ZOOM } from '@libs/common';
export interface MapUpdatePosition {
    zoom: number;
    center: LngLat;
    offset?: number[];
}
export enum MapActions {
    setMapZoom = '[MapActions] set map zoom',
    setMapCenter = '[MapActions] set map center',
    setToNewPosition = '[MapActions] set to new position',
    setIsMovingMap = '[MapActions] set is moving',
    setLoadingStyle = '[MapActions] set loading style',
}
export const setMapZoom = createAction(MapActions.setMapZoom, props<{ payload: number }>());
export const setMapCenter = createAction(MapActions.setMapCenter, props<{ payload: LngLat }>());
export const setToNewPosition = createAction(
    MapActions.setToNewPosition,
    props<{ payload: MapUpdatePosition }>()
);
export const setIsMovingMap = createAction(
    MapActions.setIsMovingMap,
    props<{ payload: boolean }>()
);
export const setLoadingStyle = createAction(
    MapActions.setLoadingStyle,
    props<{ payload: boolean }>()
);
type MapState = {
    center: LngLat;
    zoom: number;
    minZoom: number;
    maxZoom: number;
    bounds: LngLatBoundsLike;
    position: MapUpdatePosition;
    isMoving: boolean;
    isLoadingStyle: boolean;
};
const initialState: MapState = {
    center: new LngLat(57, 57),
    zoom: 2,
    minZoom: STND_GLOBAL_MIN_ZOOM,
    maxZoom: STND_CITY_MAX_ZOOM - 1.1,
    bounds: [
        {
            lng: -360,
            lat: -84,
        },
        {
            lng: 360,
            lat: 84,
        },
    ],
    position: null,
    isMoving: false,
    isLoadingStyle: false,
};
export const mapFeature = createFeature({
    name: 'map',
    reducer: createReducer(
        initialState,
        on(setMapZoom, (state, { payload }) => ({
            ...state,
            zoom: payload,
        })),
        on(setMapCenter, (state, { payload }) => ({
            ...state,
            center: payload,
        })),
        on(setToNewPosition, (state, { payload }) => ({
            ...state,
            position: payload,
        })),
        on(setIsMovingMap, (state, { payload }) => ({
            ...state,
            isMoving: payload,
        })),
        on(setLoadingStyle, (state, { payload }) => ({
            ...state,
            isLoadingStyle: payload,
        }))
    ),
});

const featureSelector = createFeatureSelector<MapState>('map');

export const selectMapZoom = createSelector(featureSelector, (state) => state.zoom);
export const selectMapCenter = createSelector(featureSelector, (state) => state.center);
export const selectToGoPosition = createSelector(featureSelector, (state) => state.position);
export const selectIsMovingMap = createSelector(featureSelector, (state) => state.isMoving);
export const selectMapPosition = createSelector(selectMapZoom, selectMapCenter, (zoom, center) => ({
    zoom,
    center,
}));
export const selectMapStyleLoading = createSelector(
    featureSelector,
    (state) => state.isLoadingStyle
);
