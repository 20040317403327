import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CapiAqiLegendMobileComponent } from './capi-aqi-legend-mobile.component';
import { AqiIndicatorSimpleModule } from '../aqi-indicator-simple/aqi-indicator-simple.module';

@NgModule({
    imports: [CommonModule, AqiIndicatorSimpleModule],
    exports: [CapiAqiLegendMobileComponent],
    declarations: [CapiAqiLegendMobileComponent],
})
export class CapiAqiLegendMobileModule {}
