import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PageTabsModule } from '@libs/shared-ui';
import { InputDropdownModule } from '@libs/shared-ui';
import { InputDateRangeModule } from '@libs/shared-ui';
import { InputModule } from '@libs/shared-ui';
import { LoadersModule } from '@libs/shared-ui';
import { CrossButtonModule } from '@libs/shared-ui';
import { TooltipsModule } from '@libs/shared-ui';
import { SearchInputBasicModule } from '@libs/shared-ui';
import { SelectboxRadioModule } from '@libs/shared-ui';
import { PopupAskModule } from '@libs/shared-ui';
import { NonDataScreenModule } from '@libs/shared-ui';
import { HeaderOfPanelModule } from '@libs/shared-ui';
import { BaseChartDirective } from 'ng2-charts';
import { PipesModule } from '@libs/shared-ui';
import { AreaChartModule } from '@libs/shared-ui';
import { DirectivesModule } from '@libs/shared-ui';
import { SortListItemModule } from '@libs/shared-ui';
import { SwitchItemModule } from '@libs/shared-ui';
import { MmtMapSelectorComponentModule } from '@libs/shared-ui';
import { RadarChartModule } from '@libs/shared-ui';
import { InputRadioSquareModule } from '@libs/shared-ui';
import { InfoCorrelationPopupModule } from '@libs/shared-ui';
import { MapControlModule } from '@libs/shared-ui';
import { InputForDropdownModule } from '@libs/shared-ui';
import { MmtSelectorModule } from '@libs/shared-ui';
import { ColorLegendModule } from '@libs/shared-ui';
import { InfoIconModule } from '@libs/shared-ui';
import { MapStyleSelectorComponent, SearchResultPlaceholderComponent } from '@libs/shared-ui';

import { AnalysisMainComponent } from './components/analysis-main/analysis-main.component';
import { CorrelationComponent } from './components/analysis-main/correlation/correlation.component';
import { AnalysisMapComponent } from './components/analysis-map/analysis-map.component';
import { AnalysisMapElementsComponent } from './components/analysis-map/analysis-map-elements/analysis-map-elements.component';
import { analysisReducers } from './store/reducers';
import { analysisRoutes } from './analysis-routing';

import { AnalysisEffects } from './store/effects';

import { CorrelationDetailsComponent } from './components/analysis-main/posts/correlation-details/correlation-details.component';
import { LineChartComponent } from './components/analysis-map/line-chart/line-chart.component';
import { SummaryComponent } from './components/analysis-main/summary/summary.component';
import { PostsComponent } from './components/analysis-main/posts/posts.component';

@NgModule({
    declarations: [
        AnalysisMainComponent,
        CorrelationComponent,
        AnalysisMapComponent,
        AnalysisMapElementsComponent,
        CorrelationDetailsComponent,
        LineChartComponent,
        SummaryComponent,
        PostsComponent,
    ],
    exports: [AnalysisMapComponent],
    imports: [
        RouterModule,
        RouterModule.forChild(analysisRoutes),
        CommonModule,
        StoreModule.forFeature('analysis', analysisReducers),
        EffectsModule.forFeature([AnalysisEffects]),
        PageTabsModule,
        InputDropdownModule,
        InputDateRangeModule,
        InputModule,
        LoadersModule,
        CrossButtonModule,
        DirectivesModule,
        SelectboxRadioModule,
        PopupAskModule,
        NonDataScreenModule,
        HeaderOfPanelModule,
        MmtMapSelectorComponentModule,
        PipesModule,
        AreaChartModule,
        BaseChartDirective,
        RadarChartModule,
        TooltipsModule,
        InfoCorrelationPopupModule,
        SearchInputBasicModule,
        SortListItemModule,
        InputRadioSquareModule,
        SearchResultPlaceholderComponent,
        SwitchItemModule,
        MapControlModule,
        InputForDropdownModule,
        MmtSelectorModule,
        ColorLegendModule,
        InfoIconModule,
        MapStyleSelectorComponent,
    ],
})
export class AnalysisModule {}
