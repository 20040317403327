<div class="notifications_panel">
    <div class="ca-header-wrapper with-tabs" *ngIf="currentPage !== pages.SETTINGS">
        <div class="header">
            <header-page
                [textObject]="{
                    titlePage: textAdminPanel.notifications,
                    btnBack: textAdminPanel.back
                }"
            ></header-page>
        </div>
        <ca-page-tabs *ngIf="(store.select(selectMyRole) | async)?.edit_group "
            [tabs]="pageTabs"
            [selectedTab]="selectedTab"
            (showTab)="showSelectedTab($event)"
        ></ca-page-tabs>
    </div>
    <ng-container [ngSwitch]="currentPage">
        <cityair-events *ngSwitchCase="pages.EVENT_FEED"></cityair-events>
        <subscriptions *ngSwitchCase="pages.SUBSCRIPTIONS"></subscriptions>
        <settings *ngSwitchCase="pages.SETTINGS"></settings>
    </ng-container>
</div>
