import type { BBox } from 'geojson';
import {
    NetworkDataModeling,
    NetworkInfoPinData,
    NetworkMapData,
    NetworkPolygonData,
    NetworkPostData,
    NetworkPriorityData,
    PostData,
    PriorityData,
    ScaleInfoNetwork,
} from './service/api-models';
import { getAssetPath } from '@cityair/config';
import { MeasureScheme, Post, TEXTS } from '@libs/common';
import { ColorZone } from '@libs/common';
import { getColorFromZone } from '@cityair/utils/utils';
import { MeasuresZones } from '@libs/common';
import { LABEL_VALUE, PRIORITY_VALUE } from '@cityair/modules/network/constants';
import { ControlPointModel } from '@cityair/namespace';
import { LngLatBounds } from 'mapbox-gl';

export function getGridLineByBbox(
    bbox: BBox,
    data: NetworkDataModeling,
    posts: Post[],
    zones: MeasuresZones
): {
    dataMap: NetworkMapData;
    priorityData: NetworkPriorityData;
    postData: NetworkPostData;
    infoData: NetworkInfoPinData;
} {
    const scales: ScaleInfoNetwork[] = [];
    const latStep = (bbox[3] - bbox[1]) / 25;
    const lngStep = (bbox[2] - bbox[0]) / 21;
    const scalesVert: ScaleInfoNetwork = {
        id: 'network-vert-scale',
        url: getAssetPath('network/vertical-scale.png'),
        coordinates: [
            [bbox[0] - (lngStep * 2) / 3, bbox[3]],
            [bbox[0], bbox[3]],
            [bbox[0], bbox[1]],
            [bbox[0] - (lngStep * 2) / 3, bbox[1]],
        ],
    };
    scales.push(scalesVert);
    const scalesHor: ScaleInfoNetwork = {
        id: 'network-hor-scale',
        url: getAssetPath('network/horizontal-scale.png'),
        coordinates: [
            [bbox[0], bbox[3] + (latStep * 2) / 3],
            [bbox[2], bbox[3] + (latStep * 2) / 3],
            [bbox[2], bbox[3]],
            [bbox[0], bbox[3]],
        ],
    };

    scales.push(scalesHor);
    const keys = Object.keys(data);
    const dataMap: NetworkMapData = {
        PM25: { polygon: null, scales: null },
        CO: { polygon: null, scales: null },
        NO2: { polygon: null, scales: null },
    };
    const priorityData: NetworkPriorityData = {
        PM25: [],
        CO: [],
        NO2: [],
    };

    const postData: NetworkPostData = {
        PM25: [],
        CO: [],
        NO2: [],
    };
    const infoData: NetworkInfoPinData = {
        PM25: [],
        CO: [],
        NO2: [],
    };
    keys.forEach((mmt) => {
        const priorityValue = PRIORITY_VALUE[mmt];
        const prepareData = getDataByMmt(bbox, data[mmt], posts, zones[mmt], priorityValue, mmt);
        const polygonData: NetworkPolygonData = {
            polygon: prepareData.polygon,
            scales: scales,
        };
        dataMap[mmt] = polygonData;
        priorityData[mmt] = prepareData.priorityData;
        postData[mmt] = prepareData.postData;
        infoData[mmt] = prepareData.infoPins;
    });

    return { dataMap, priorityData, postData, infoData };
}

function getDataByMmt(
    bbox: BBox,
    data: number[][],
    posts: Post[],
    zones: ColorZone,
    priorityValue: number,
    mmt: string
): {
    polygon: GeoJSON.FeatureCollection;
    priorityData: PriorityData[];
    postData: PostData[];
    infoPins: ControlPointModel[];
} {
    const polygon: GeoJSON.FeatureCollection = {
        type: 'FeatureCollection',
        features: [],
    };

    const priorityData: PriorityData[] = [];
    const postData: PostData[] = [];
    const infoPins: ControlPointModel[] = [];
    const latStep = (bbox[3] - bbox[1]) / 25;
    const lngStep = (bbox[2] - bbox[0]) / 21;
    let i = 0;
    let id = 1;
    for (let lng = bbox[0]; lng < bbox[2] - lngStep; lng += lngStep) {
        let k = 1;
        for (let lat = bbox[1]; lat < bbox[3]; lat += latStep) {
            const value = data[i][k - 1] ?? null;
            id += 1;
            const coordinates = [
                [
                    [lng, lat],
                    [lng, lat + latStep],
                    [lng + lngStep, lat + latStep],
                    [lng + lngStep, lat],
                    [lng, lat],
                ],
            ];
            const bbox = getBboxByPolygon(coordinates[0]);
            const neighborBbox = getNeighborBbox(bbox, latStep, lngStep);
            const insidePosts = getInsidePosts(posts, bbox);
            const neighbourPosts = getInsidePosts(posts, neighborBbox);
            const postIds = insidePosts.map((v) => v.id);
            const neighbourPostIds = neighbourPosts
                .filter((v) => !postIds.includes(v.id))
                .map((v) => v.id);
            const color = getColorFromZone(zones, value);
            const pointCoor = new LngLatBounds([bbox[0], bbox[1]], [bbox[2], bbox[3]]).getCenter();
            const feature: GeoJSON.Feature<GeoJSON.Geometry> = {
                type: 'Feature',
                geometry: {
                    type: 'Polygon',
                    coordinates,
                },
                properties: {
                    value,
                    color,
                    opacity: 0.4,
                    border: '#6C7484',
                    name: `${LABEL_VALUE[i]}${k}`,
                    lng: pointCoor.lng,
                    lat: pointCoor.lat + latStep / 2,
                    unit: TEXTS.MEASURES_SCHEME[MeasureScheme.default][mmt] ?? '',
                },
                id,
            };
            polygon.features.push(feature);
            if (value > priorityValue) {
                const priority: PriorityData = {
                    name: `${LABEL_VALUE[i]}${k}`,
                    region: null,
                    postIds,
                    neighbourPostIds,
                    value,
                    color,
                    id,
                    lng: pointCoor.lng,
                    lat: pointCoor.lat + latStep / 2,
                    unit: TEXTS.MEASURES_SCHEME[MeasureScheme.default][mmt] ?? '',
                };
                priorityData.push(priority);
                if (postIds.length === 0 && neighbourPostIds.length === 0) {
                    infoPins.push({
                        id: `${LABEL_VALUE[i]}${k}`,
                        name: `${LABEL_VALUE[i]}${k}`,
                        lat: pointCoor.lng,
                        lon: pointCoor.lat,
                        type: 'add',
                        color: '#6945d0',
                    });
                }
            }
            if (insidePosts.length) {
                insidePosts.forEach((post) => {
                    postData.push({
                        id: post.id,
                        region: null,
                        value,
                        color,
                    });
                });
            }
            k++;
        }
        i++;
    }
    return { polygon, priorityData, postData, infoPins };
}

function getInsidePosts(posts, bbox): Post[] {
    const insidePostIds = posts.filter((v) => {
        const pt = v.geometry.coordinates;
        if (bbox[0] <= pt[0] && bbox[1] <= pt[1] && bbox[2] >= pt[0] && bbox[3] >= pt[1]) {
            return v;
        }
    });

    return insidePostIds.length ? insidePostIds : [];
}

function getBboxByPolygon(coordinate) {
    return [coordinate[0][0], coordinate[0][1], coordinate[2][0], coordinate[1][1]];
}

function getNeighborBbox(bbox, latStep, lngStep) {
    return [bbox[0] - lngStep, bbox[1] - latStep, bbox[2] + lngStep, bbox[3] + latStep];
}
