<div class="list-wrapper">
    <div class="correlation-body">
        <div class="date">
            <div class="header">
                {{text.titleDetail}}
            </div>
        </div>
        <div class="chart-wrapper" *ngIf="store.select(selectRadarChartDataById(currentId))| async as chartData">
            <cityair-area-chart
                    [data]="chartData"
                    [legend]="false"
                    [emptyText]="text.emptyDataByPeriod"
            ></cityair-area-chart>
        </div>
        <ng-container *ngIf="store.select(selectWindDataByPost(currentId)) | async as windData">
            <div class="date" style="padding-top: 15px;">
                <div class="header">
                    {{text.titleWind}}
                </div>
                <div class="interval-text">
                    {{text.titleWindDesc}}
                </div>
            </div>
            <div class="chart-wrapper" style="display: block; width: 100%; height: 300px; padding-top: 10px;" >
                <cityair-radar-chart [data]="windData?.daily_stats"></cityair-radar-chart>
            </div>
        </ng-container>
    </div>
</div>

