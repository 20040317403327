import { Injectable, NgZone } from '@angular/core';

import { MapObject } from '@cityair/modules/map/components/mapbox/mapAPI';

import { GLOBAL_ZOOM_LEVEL, STND_CITY_ZOOM, STND_GLOBAL_MIN_ZOOM } from '@libs/common';
import { isFalseNumber } from '@libs/common';
import { GroupMapSettings } from '@libs/common';
import { GroupExtConfigName } from '@libs/common';
import { GroupFeaturesService } from '@libs/shared-store';
import { Store } from '@ngrx/store';
import { selectIsCityMode } from '@cityair/modules/core/store/selectors';
import { first } from 'rxjs';
import { LngLat, Map } from 'mapbox-gl';
import { Locality } from '@libs/common';
import { selectMapZoom, setToNewPosition } from '@cityair/modules/core/store/map/map.feature';
import { MAP_ACTION_DURATION } from '@libs/common';
import { detectMobile } from '@libs/common';

@Injectable({
    providedIn: 'root',
})
export default class MapboxActions {
    private mapObject: MapObject;

    preventZooming = false; // чтобы в момент центрования не отлавливался зум
    currentZoom: number = STND_CITY_ZOOM;
    isMobile = detectMobile();

    constructor(
        private store: Store,
        private zone: NgZone,
        private groupFeaturesService: GroupFeaturesService
    ) {
        this.store.select(selectMapZoom).subscribe((v) => (this.currentZoom = v));
    }

    setMapObject(mapObj: Map): void {
        this.mapObject = new MapObject(mapObj, this.zone);
    }

    async centringMap(city: Locality, isCityMode: boolean) {
        this.preventZooming = true;
        setTimeout(() => {
            this.preventZooming = false;
        }, MAP_ACTION_DURATION);

        const mapSettings = this.groupFeaturesService.getConfig(
            GroupExtConfigName.mapSettings
        ) as GroupMapSettings;
        const minZoom = (mapSettings?.zoom ?? STND_GLOBAL_MIN_ZOOM - 1) + 1; // probably need to exclude global zoom

        const zoom = isCityMode ? STND_CITY_ZOOM : minZoom;

        const newZoom = zoom - 1;

        this.currentZoom = newZoom;
        const lat = city?.geometry.coordinates[1];
        const lng = city?.geometry.coordinates[0];

        if (!isFalseNumber(lat) && !isFalseNumber(lng)) {
            if (this.mapObject) {
                this.mapObject?.centerTo({ lat, lng }, newZoom);
            } else {
                this.store.dispatch(
                    setToNewPosition({
                        payload: {
                            center: new LngLat(lng, lat),
                            zoom: newZoom,
                        },
                    })
                );
            }
        } else {
            if (this.mapObject) {
                this.mapObject?.zoomTo(newZoom);
            } else {
                this.store.dispatch(
                    setToNewPosition({
                        payload: {
                            center: null,
                            zoom: newZoom,
                        },
                    })
                );
            }
        }
    }

    moveMap(center: LngLat, zoom: number, offset?: [number, number]) {
        if (this.mapObject) {
            this.mapObject?.moveTo(center, zoom, offset);
        } else {
            this.store.dispatch(
                setToNewPosition({
                    payload: {
                        center,
                        zoom: zoom,
                    },
                })
            );
        }
    }

    centringOnMarker = (
        lat: number,
        lng: number,
        zooming: boolean,
        shiftMap?: [number, number]
    ) => {
        this.store
            .select(selectIsCityMode)
            .pipe(first())
            .subscribe((isCityMode) => {
                const zoom = isCityMode ? STND_CITY_ZOOM : GLOBAL_ZOOM_LEVEL - 1;
                let newZoom: number;

                if (zooming && this.currentZoom < zoom) {
                    newZoom = zoom - 1;
                }

                this.gotoLocation(lat, lng, zooming, newZoom, shiftMap);
            });
    };

    centringOnLine = (
        lat: number,
        lng: number,
        zooming: boolean,
        zoom: number,
        shiftMap?: [number, number]
    ) => {
        if (zooming && this.currentZoom < zoom) {
            this.preventZooming = true;
            setTimeout(() => (this.preventZooming = false), MAP_ACTION_DURATION);
        }
        if (this.mapObject) {
            this.mapObject?.moveToLine(
                { lat, lng },
                zoom,
                shiftMap?.map((v) => -v) as [number, number]
            );
        } else {
            const offsetY = shiftMap && shiftMap[1] <= 0 ? 0 : 346;
            this.store.dispatch(
                setToNewPosition({
                    payload: {
                        center: new LngLat(lng, lat),
                        zoom,
                        offset: this.isMobile ? [0, 0, 246, 0] : [0, 0, offsetY, 546],
                    },
                })
            );
        }
    };

    private gotoLocation(
        lat: number,
        lng: number,
        zooming: boolean,
        zoomTo?: number,
        panTo?: [number, number]
    ) {
        if (zooming && this.currentZoom < zoomTo) {
            this.preventZooming = true;
            setTimeout(() => (this.preventZooming = false), MAP_ACTION_DURATION);
        }
        const center = new LngLat(lng, lat);
        const offset = panTo?.map((v) => -v) as [number, number];
        if (this.mapObject) {
            this.mapObject?.moveTo({ lat, lng }, zoomTo, offset);
        } else {
            this.store.dispatch(
                setToNewPosition({
                    payload: {
                        center,
                        zoom: zoomTo,
                        offset: this.isMobile ? [0, 0, 246, 0] : [0, 0, 346, 546],
                    },
                })
            );
        }
    }

    getCenter = () => this.mapObject?.getCenter();
}
