import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LANGUAGE, TEXTS } from '@libs/common';
import * as moment from 'moment-timezone';
import { Store } from '@ngrx/store';
import {
    ImpactActions,
    impactFeature,
    selectActiveRun,
    selectRegions,
} from '@cityair/modules/impact/store/impact.feature';
import { Subject, takeUntil } from 'rxjs';
import { NetworkDensityData } from '@cityair/modules/impact/service/api-model-impact';
import { Locality } from '@libs/common';
import {
    selectCurrentSummaryData,
    selectExtConfig,
    selectIsDemoAnalytic,
    selectIsLoadingSummary,
    setCityCard,
} from '@libs/shared-store';
import { selectCurrentCity } from '@cityair/modules/core/store/current-city/current-city.feature';
import { NgLocalization } from '@angular/common';
import { ValueFormatNumberPipe } from '@libs/shared-ui';
import { IMPACT_PAGES } from '@cityair/modules/impact/models';
import { MAIN_PAGES } from '@libs/common';
import { Router } from '@angular/router';
import { declOfNum } from '@libs/common';
import { measureZones } from '@libs/common';
import { AQI } from '@libs/common';
import { map } from 'rxjs/operators';
import { getAssetPath } from '@cityair/config';

@Component({
    selector: 'ca-impact-cities',
    templateUrl: 'impact-cities.component.html',
    styleUrls: ['impact-cities.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpactCitiesComponent implements OnInit, OnDestroy {
    public paramsForLocality$ = this.store.select(impactFeature.selectParamsForLocality);
    public networkDensityData$ = this.store.select(impactFeature.selectNetworkDensity);
    public regions$ = this.store.select(selectRegions);
    public currentDate = () => moment(new Date()).format('D MMMM, HH:mm');
    public sortRegionDirection: number = 1;
    public sortRegionField: string = 'name';
    public showAllRegions: boolean = false;
    public showAllMmt: boolean = false;
    impactSettings$ = this.store.select(selectExtConfig).pipe(map((data) => data?.impactSettings));
    currentLang = LANGUAGE;
    selectCurrentCityData = impactFeature.selectCurrentCityData;
    selectActiveSources = impactFeature.selectActiveSources;
    public currentCity: Locality;
    public distributionSummary$ = this.store.select(selectCurrentSummaryData);
    public isLoadingSummary$ = this.store.select(selectIsLoadingSummary);
    activeRun$ = this.store.select(selectActiveRun);
    public ngDestroyed$ = new Subject<void>();
    TEXTS = TEXTS;
    textNames = TEXTS.NAMES;
    textImpact = TEXTS.IMPACT;
    readonly mainComponentUrl = `/${MAIN_PAGES.impact}/`;

    dataChart: number[] = [];
    labelChart: string[] = [];
    colorsChat: string[] = [];

    sourceMmtList = [];
    measureZones = measureZones;
    AQI = AQI;
    getAsset = getAssetPath;

    constructor(
        private router: Router,
        public store: Store,
        private ngLocalization: NgLocalization,
        private numberFormatPipe: ValueFormatNumberPipe
    ) {
        this.store.dispatch(ImpactActions.setActiveTab({ payload: IMPACT_PAGES.cities }));

        this.store.select(impactFeature.selectRegions).subscribe({
            next: (value) => {
                const data = [...value];
                data.sort((a, b) => (a.contribution > b.contribution ? -1 : 1));

                const commonLowerIndexes = data.slice(5).reduce((sum, el) => {
                    return sum + el.contribution;
                }, 0);

                const resultData = [
                    ...data.slice(0, 5),
                    { name: 'Остальные', contribution: commonLowerIndexes, color: '#9966CB' },
                ];
                resultData.map((region) => {
                    this.dataChart = [...this.dataChart, region.contribution];
                    this.labelChart = [...this.labelChart, region.name];
                    this.colorsChat = [...this.colorsChat, region.color];
                });
            },
        });
    }

    ngOnInit(): void {
        this.store
            .select(selectCurrentCity)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.store.dispatch(setCityCard({ city: data }));
                this.currentCity = data;
            });
    }

    ngOnDestroy() {
        this.ngDestroyed$.next();
        this.ngDestroyed$.complete();
    }

    public setRegionSortingCb(sortCb: string): void {
        if (this.sortRegionField === sortCb) {
            this.sortRegionDirection *= -1;
        } else {
            this.sortRegionField = sortCb;
        }
    }

    getStatusByData(data: NetworkDensityData) {
        const densityValues = [0.4, 0.6, 0.8];
        const distanceValues = [4, 3, 2];
        const value = data?.stations_coverage;
        const dist = data?.stations_avg_min_distance;
        let indexDencity;
        let indexDistance;
        if (value && value >= densityValues[densityValues.length - 1]) {
            indexDencity = densityValues.length;
        } else {
            indexDencity = densityValues.findIndex((v) => value < v);
        }
        if (dist && dist <= distanceValues[distanceValues.length - 1]) {
            indexDistance = distanceValues.length;
        } else {
            indexDistance = distanceValues.findIndex((v) => dist > v);
        }
        const index = Math.min(indexDencity, indexDistance);
        return this.TEXTS.IMPACT.networkDensity.density[index];
    }

    public postCountText(num: number = 0) {
        const { post } = TEXTS.NOTIFICATIONS;
        const category = this.ngLocalization.getPluralCategory(num, LANGUAGE);
        return [num, post[category]].join(' ');
    }

    formatSquare(val: number) {
        return this.numberFormatPipe.transform(val, 1);
    }

    goToSource() {
        this.router.navigate([`${this.mainComponentUrl}${IMPACT_PAGES.sources}`]);
    }

    goToControlPoints() {
        this.router.navigate([`${this.mainComponentUrl}${IMPACT_PAGES.controlPoints}`]);
    }

    getAQIunit(value: number) {
        return declOfNum(value, TEXTS.AQI_DECL2);
    }
}
