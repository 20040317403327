import { createReducer, on } from '@ngrx/store';
import { EntityState } from '@ngrx/entity';
import * as commonActions from './actions';
import {
    BasicDataResponse,
    BasicDeviceSourceResponse,
    DataObjType,
    detectMobile,
    Feature,
    IBasicResponse,
    IExtraTimelineResponse,
    ITimeseriesDataItem,
    DataMarker,
    DataQualityTimeline,
    IntervalEnum,
    IntervalType,
    calcDays,
    findMinInterval,
} from '@libs/common';

import { IMapClick } from '@cityair/namespace';
import { getStndTimeBegin, getStndTimeEnd } from '@cityair/libs/shared/utils/config';
import * as moment from 'moment-timezone';
import { STND_INTERVAL } from '@cityair/config';
import { comparedListReducers } from './compared-list/compared-list.reducers';
import { findNearestTime } from '@cityair/utils/find-nearest-time';
import { timelineAdapter } from '@libs/shared-store';
export interface MapState {
    loaded: boolean;
}

export interface ICoreState {
    map: MapState;
    defaultMapIsActive: boolean;
    time: TimeObject;
    isTimelineLoading: boolean;
    selectedMoId: string;
    selectedDeviceSerialNumber: string;
    allMeasurements: string[];
    currentMeasurement: string;
    isInstantAqiFaqOpen: boolean;
    timelineDateTimes: string[];
    isCityMode: boolean;
    errorMessage: string;
    isCompareMode: boolean;
    comparedItems: BasicDataResponse[];
    currentTypeInterval: IntervalEnum;
    mapClickState: IMapClick;
    qualityDataMode: number;
    isShowQualityDataInfo: any;
    qualityDataMarkers: DataMarker[];
    qualityDataTimeline: DataQualityTimeline[];
    timelinePosts: EntityState<ITimeseriesDataItem>;
    currentTimeIndex: number;
    deviceSources: BasicDeviceSourceResponse[];
    chartData: Feature[];
    isShowPublicForecast: boolean;
}

export interface TimeObject {
    current: number;
    begin: number;
    end: number;
    tzMinutesOffset: number;
}

export type DataSeriesType = {
    name: string;
    data: {
        x: string;
        y: number;
    }[];
};

const stndBeginTime = getStndTimeBegin();
const stndEndTime = getStndTimeEnd();

export const initialState: ICoreState = {
    isTimelineLoading: true,
    map: {
        loaded: false,
    },
    defaultMapIsActive: true,
    time: {
        current: findNearestTime(null, stndBeginTime, stndEndTime),
        begin: stndBeginTime,
        end: stndEndTime,
        tzMinutesOffset: getDefaultTzMinutesOffset(moment.tz.guess(true)),
    },
    selectedMoId: null,
    selectedDeviceSerialNumber: null,
    allMeasurements: [],
    currentMeasurement: null,
    isInstantAqiFaqOpen: false,
    timelineDateTimes: [],
    isCityMode: false,
    errorMessage: null,
    isCompareMode: false,
    comparedItems: [],
    currentTypeInterval: STND_INTERVAL,
    mapClickState: null,
    qualityDataMode: null,
    isShowQualityDataInfo: null,
    qualityDataMarkers: [],
    qualityDataTimeline: [],
    timelinePosts: timelineAdapter.getInitialState(),
    currentTimeIndex: null,
    deviceSources: null,
    chartData: [],
    isShowPublicForecast: false,
};

const _commonReducer = createReducer(
    initialState,
    on(commonActions.initMainMap, (state: ICoreState) => ({
        ...state,
        defaultMapIsActive: true,
    })),
    on(commonActions.destroyMainMap, (state: ICoreState) => ({
        ...state,
        defaultMapIsActive: false,
    })),

    on(commonActions.setErrorMessage, (state: ICoreState, data) => ({
        ...state,
        errorMessage: data.msg,
    })),

    on(commonActions.clearErrorMessage, (state: ICoreState, data) => ({
        ...state,
        errorMessage: null,
    })),

    on(commonActions.selectMo, (state: ICoreState, data: { id: string }) => ({
        ...state,
        selectedMoId: data.id,
    })),

    on(commonActions.selectDevice, (state: ICoreState, data: { serialNumber: string }) => ({
        ...state,
        selectedDeviceSerialNumber: data.serialNumber,
    })),
    on(commonActions.mapLoaded, (state: ICoreState) => ({
        ...state,
        map: {
            ...state.map,
            loaded: true,
        },
    })),
    on(commonActions.setAllMmts, (state: ICoreState, { payload }) => ({
        ...state,
        allMeasurements: payload,
    })),
    on(commonActions.setGlobalMeasurement, (state: ICoreState, { mmt }) => ({
        ...state,
        currentMeasurement: mmt,
    })),

    on(commonActions.openInstantAqiFaq, (state: ICoreState) => ({
        ...state,
        isInstantAqiFaqOpen: true,
    })),

    on(commonActions.closeInstantAqiFaq, (state: ICoreState) => ({
        ...state,
        isInstantAqiFaqOpen: false,
    })),

    on(commonActions.currentTimeUpdate, (state: ICoreState, props) => {
        if (!props.time) return state;

        return {
            ...state,
            time: {
                ...state.time,
                current: props.time,
            },
        };
    }),

    on(commonActions.intervalUpdate, (state: ICoreState, props) => {
        const minInterval = findMinInterval(calcDays(props.begin, props.end))
            ?.interval as IntervalType;

        let currentTypeInterval =
            state.currentTypeInterval < minInterval ? minInterval : state.currentTypeInterval;
        if (detectMobile() && state.currentTypeInterval > minInterval) {
            currentTypeInterval = IntervalEnum.hour;
        }

        return {
            ...state,
            time: {
                ...state.time,
                ...props,
            },
            currentTypeInterval,
            isNeedUpdateIndex: true,
        };
    }),

    on(commonActions.setTzMinutesOffset, (state: ICoreState, props) => ({
        ...state,
        time: {
            ...state.time,
            tzMinutesOffset: props.tz,
        },
    })),

    on(commonActions.resetTzMinutesOffset, (state: ICoreState) => ({
        ...state,
        time: {
            ...state.time,
            tzMinutesOffset: getDefaultTzMinutesOffset(moment.tz.guess(true)),
        },
    })),

    //
    on(commonActions.setTimelineDateTimes, (state: ICoreState, data: { dateTimes: string[] }) => {
        let currentIndex;
        if (state.isShowPublicForecast) {
            currentIndex = 0;
        } else {
            const oldCurrentData = [...state.timelineDateTimes][state.currentTimeIndex]
                ? state.timelineDateTimes[state.currentTimeIndex]
                : '';

            const isLastIndex = state.currentTimeIndex === state.timelineDateTimes.length - 1;
            currentIndex = data.dateTimes.indexOf(oldCurrentData);
            if (currentIndex === -1 || isLastIndex) {
                currentIndex = data.dateTimes.length - 1;
            }
        }
        return {
            ...state,
            timelineDateTimes: data.dateTimes,
            currentTimeIndex: currentIndex,
        };
    }),

    on(commonActions.setCityMode, (state: ICoreState) => ({
        ...state,
        isCityMode: true,
    })),

    on(commonActions.setWorldMode, (state: ICoreState) => ({
        ...state,
        isCityMode: false,
    })),

    on(commonActions.setComparisonMode, (state: ICoreState, props) => ({
        ...state,
        isCompareMode: props.payload,
    })),
    on(commonActions.setTypeInterval, (state: ICoreState, props) => ({
        ...state,
        currentTypeInterval: props.payload,
    })),
    on(commonActions.setMapClickState, (state: ICoreState, data) => ({
        ...state,
        mapClickState: data,
    })),

    on(commonActions.changeQualityDataMode, (state: ICoreState, { payload }) => ({
        ...state,
        qualityDataMode: payload,
    })),
    on(commonActions.toggleShowQualityDataInfo, (state: ICoreState, { payload }) => ({
        ...state,
        isShowQualityDataInfo: payload,
    })),

    on(commonActions.setQualityDataMarkers, (state: ICoreState, { payload }) => ({
        ...state,
        qualityDataMarkers: payload,
    })),
    on(commonActions.setQualityData, (state: ICoreState, { payload }) => {
        const result: DataQualityTimeline[] = [];
        const qualityDataMarkers = state?.qualityDataMarkers ?? [];
        payload.forEach((v) => {
            if (v === null) {
                result.push(null);
            } else {
                const percentGroup = state?.qualityDataMode;
                if (v.isShow(percentGroup) && v.markerCodes.length) {
                    const markers = v.markerCodes.map((v) =>
                        qualityDataMarkers.find((marker) => marker.code === v)
                    );
                    result.push(new DataQualityTimeline(markers));
                } else {
                    result.push(null);
                }
            }
        });

        return {
            ...state,
            qualityDataTimeline: result,
        };
    }),
    on(commonActions.isLoadingTimeline, (state: ICoreState, { payload }) => ({
        ...state,
        isTimelineLoading: payload,
    })),
    on(commonActions.updateTimeIndex, (state: ICoreState, { payload }) => ({
        ...state,
        currentTimeIndex: payload,
    })),
    on(commonActions.timelineLoaded, (state: ICoreState, { payload }) => {
        const response = (payload?.data as ITimeseriesDataItem[]) ?? [];
        const timelinePosts = timelineAdapter.setMany(response, state.timelinePosts);
        return {
            ...state,
            timelinePosts,
        };
    }),

    on(commonActions.setChartData, (state: ICoreState, { payload, feature, extConfig }) => {
        if (!payload || !feature) {
            return {
                ...state,
                chartData: [],
            };
        }
        const currentData = transformToFeature(payload, feature, extConfig);
        const isCompare = state.isCompareMode;
        const result = [...state.chartData];
        if (isCompare) {
            result.push(currentData);
        }
        return {
            ...state,
            chartData: isCompare ? result : [currentData],
        };
    }),
    on(commonActions.updateChartData, (state: ICoreState, { payload, features, extConfig }) => {
        if (payload === null || features === null || !payload.length || !features.length) {
            return {
                ...state,
                chartData: [],
            };
        }
        const result = payload.map((item, index) =>
            transformToFeature(item, features[index], extConfig)
        );
        return {
            ...state,
            chartData: result,
        };
    }),
    on(commonActions.togglePublicForecast, (state: ICoreState, { payload }) => ({
        ...state,
        isShowPublicForecast: payload,
    })),
    ...comparedListReducers
);

function getDefaultTzMinutesOffset(timezone): number {
    return moment().tz(timezone).utcOffset();
}

export function commonReducers(state, action) {
    return _commonReducer(state, action);
}

function transformToFeature(
    response: IBasicResponse,
    feature: BasicDataResponse,
    extConfig
): Feature {
    const extraResponse = response.meta.extra as IExtraTimelineResponse;
    const timelineData = response.data as ITimeseriesDataItem[];
    const excludeIndexes = extConfig?.excludeIndexes ? extConfig?.excludeIndexes.split(',') : [];
    if (feature && timelineData?.[0] && timelineData[0].id === feature.id) {
        const indexes = { ...timelineData[0].data?.indexes };
        if (excludeIndexes.length) {
            excludeIndexes.forEach((index) => {
                if (timelineData[0].data?.indexes.hasOwnProperty(index)) {
                    delete indexes[index];
                }
            });
        }

        const hasTimeseries =
            !!Object.keys(timelineData[0].data?.measurements).length ||
            !!Object.keys(indexes).length;
        const timeseriesData = {
            date: extraResponse.dates,
        };

        const timeseriesDetails = {};
        if (hasTimeseries) {
            Object.entries(timelineData[0].data?.measurements).forEach(([key, value]) => {
                timeseriesData[key] = value.values;
            });
            Object.entries(indexes).forEach(([key, value]) => {
                timeseriesData[key] = value.values;
                timeseriesDetails[key] = value.details;
            });
        }
        return {
            type: 'Feature',
            geometry: feature.geometry,
            properties: {
                uuid: feature.id,
                city_id: `${feature.ancestor?.id}`,
                name: feature.name,
                name_ru: feature.name,
                timeseries: timeseriesData,
                details: timeseriesDetails,
                obj: feature.obj === DataObjType.city ? 'city' : 'station',
                has_any_timeseries: hasTimeseries,
                ancestors: [
                    {
                        name: feature.ancestor?.name,
                        obj: feature.ancestor?.obj,
                        uuid: `${feature.ancestor?.id}`,
                    },
                ],
            },
        } as Feature;
    }
}
