import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ANALYSIS_PAGES } from './models';
import { AnalysisMainComponent } from './components/analysis-main/analysis-main.component';
import { CorrelationComponent } from './components/analysis-main/correlation/correlation.component';
import { SummaryComponent } from './components/analysis-main/summary/summary.component';
import { PostsComponent } from './components/analysis-main/posts/posts.component';

export const analysisRoutes: Routes = [
    {
        path: '',
        component: AnalysisMainComponent,
        children: [
            {
                path: ANALYSIS_PAGES.summary,
                component: SummaryComponent,
            },
            {
                path: ANALYSIS_PAGES.posts,
                component: PostsComponent,
            },
            {
                path: ANALYSIS_PAGES.links,
                component: CorrelationComponent,
            },
            {
                path: ANALYSIS_PAGES.links + '/:pair',
                component: CorrelationComponent,
            },
            {
                path: ANALYSIS_PAGES.posts + '/:id',
                component: PostsComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(analysisRoutes)],
    exports: [RouterModule],
})
export class AnalysisRoutingModule {}
