<div class="wrapper" *ngIf="timeline?.length">
    <div class="close-btn" (click)="close()">
        {{texts.closeChart}} <cross-button style="padding-top: 2px" [size]="'small'" [color]="'#ffffff'" [needHover]="false"></cross-button>
    </div>
    <div class="names" *ngIf="chartTitle"><span *ngIf="!isLoading">{{chartTitle}}</span></div>
    <div class="chart-title" *ngIf="title"><span *ngIf="!isLoading" [innerHtml]="title"></span></div>
    <div class="chart-content" (mouseleave)="hoverLineVisible = false">
        <ng-container *ngIf="isLoading else chartTemplate">
            <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
        </ng-container>
        <ng-template #chartTemplate>
            <ng-container *ngIf="isEmptyData else chart">
                <div class="empty-data" *ngIf="emptyMsg">{{emptyMsg}}</div>
            </ng-container>
            <ng-template #chart>
                <canvas
                    #chart
                    baseChart
                    [type]="chartType"
                    [legend]="false"
                    [datasets]="chartData"
                    [options]="chartOptions"
                    [plugins]="plugins"
                ></canvas>
                <div class="chart-title correlation" *ngIf="title"><span [innerHtml]="texts.correlation.chartTitleCorrelation"></span></div>
                <canvas
                    #chart
                    baseChart
                    [type]="chartType"
                    [legend]="false"
                    [datasets]="correlationChartData"
                    [options]="chartOptionsCorrelation"
                    [plugins]="plugins"
                ></canvas>
                <div
                    class="chart-hover-line"
                    [style.visibility]="hoverLineVisible ? 'visible' : 'hidden'"
                    [style.left.px]="hoverLinePosition"
                ></div>
            </ng-template>
        </ng-template>
    </div>
</div>
