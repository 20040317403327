import type { BBox } from 'geojson';
import { CO, MeasuresZones, NO2, PM25 } from '@libs/common';

export const DOMAIN_BBOX: { [key: string]: BBox } = {
    '213': [82.2975, 54.5, 83.6567625, 55.4036],
    '266': [83.014, 54.42, 83.56, 54.8],
};
export const MMTS_LIST = {
    '213': [PM25, CO, NO2],
    '266': [PM25],
};
export const DEFAULT_POST_COLOR = '#b8bfcc';
export const LIST_PRIORITY_LIMIT = 7;
export const PRIORITY_VALUE = {
    PM25: 38.5,
    CO: 3300,
    NO2: 110,
};

export const LABEL_VALUE = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
];
const DEFAULT_COLORS = [
    '#8CB917',
    '#A2C617',
    '#BEC617',
    '#FFCC33',
    '#FFA33B',
    '#FF7344',
    '#FF494B',
    '#D63B50',
    '#AD2D55',
    '#821E5A',
];
export const ZONE_NETWORK: MeasuresZones = {
    [PM25]: {
        zone: [6, 12, 18, 24, 30, 36, 42, 48, 54],
        color: DEFAULT_COLORS,
    },
    [CO]: {
        zone: [500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500],
        color: DEFAULT_COLORS,
    },
    [NO2]: {
        zone: [16, 32, 48, 64, 80, 96, 112, 144],
        color: DEFAULT_COLORS,
    },
};
export const DATA_MODELING = {
    213: {
        PM25: [
            [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [3, 1, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [2, 3, 2, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [1, 2, 3, 3, 2, 1, 1, 1, 1, 2, 3, 3, 3, 2, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [3, 2, 2, 3, 4, 4, 3, 2, 2, 2, 2, 4, 5, 4, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [4, 5, 3, 2, 3, 4, 5, 5, 4, 3, 3, 5, 7, 7, 6, 5, 1, 0, 0, 0, 0, 0, 0, 0, 0],
            [2, 3, 5, 5, 3, 3, 4, 6, 8, 9, 7, 6, 7, 10, 14, 7, 4, 2, 1, 0, 0, 0, 0, 0, 0],
            [1, 2, 4, 6, 7, 5, 5, 6, 9, 13, 17, 18, 13, 14, 18, 10, 7, 3, 3, 2, 1, 1, 0, 0, 0],
            [2, 2, 3, 5, 6, 7, 6, 5, 7, 10, 17, 30, 45, 41, 35, 20, 8, 2, 2, 1, 1, 1, 1, 1, 0],
            [2, 2, 2, 4, 5, 6, 9, 11, 11, 14, 15, 23, 37, 56, 60, 40, 22, 4, 2, 1, 1, 0, 1, 0, 0],
            [1, 1, 2, 3, 4, 6, 6, 9, 14, 17, 22, 25, 38, 48, 46, 59, 71, 10, 6, 4, 2, 1, 1, 0, 0],
            [
                1, 1, 2, 2, 2, 4, 5, 8, 11, 17, 34, 55, 65, 22, 20, 25, 48, 32, 15, 13, 11, 6, 3, 1,
                1,
            ],
            [1, 1, 2, 1, 2, 2, 4, 7, 8, 10, 15, 18, 49, 25, 14, 20, 23, 25, 13, 6, 9, 12, 9, 5, 3],
            [2, 2, 2, 4, 4, 4, 6, 7, 5, 7, 8, 7, 20, 42, 30, 24, 19, 14, 17, 6, 3, 5, 6, 7, 4],
            [3, 3, 4, 4, 4, 6, 5, 2, 2, 2, 2, 6, 13, 24, 41, 31, 25, 19, 11, 14, 6, 2, 3, 3, 4],
            [2, 4, 10, 21, 20, 7, 2, 2, 3, 2, 4, 5, 8, 13, 21, 23, 17, 15, 10, 10, 9, 4, 2, 1, 2],
            [4, 6, 7, 20, 12, 7, 3, 1, 2, 4, 4, 6, 6, 7, 15, 13, 12, 10, 10, 6, 7, 5, 3, 2, 1],
            [2, 2, 5, 10, 5, 6, 3, 3, 2, 3, 3, 5, 5, 4, 8, 13, 12, 9, 7, 7, 7, 5, 3, 1, 1],
            [1, 2, 4, 6, 3, 4, 4, 2, 3, 2, 2, 4, 3, 3, 4, 7, 8, 11, 7, 5, 5, 5, 4, 2, 1],
            [1, 2, 2, 3, 2, 2, 3, 3, 2, 2, 3, 3, 2, 2, 2, 4, 4, 6, 8, 5, 4, 5, 3, 3, 1],
            [1, 1, 2, 3, 2, 2, 2, 3, 2, 2, 3, 3, 2, 1, 1, 2, 2, 4, 5, 6, 5, 3, 3, 3, 2],
        ],
        CO: [
            [
                197, 78, 92, 39, 43, 82, 106, 119, 140, 161, 156, 47, 13, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                0, 0, 0,
            ],
            [
                440, 262, 145, 84, 61, 56, 92, 119, 146, 178, 201, 145, 37, 0, 0, 0, 0, 0, 0, 0, 0,
                0, 0, 0, 0,
            ],
            [
                286, 410, 402, 235, 122, 95, 89, 109, 169, 202, 260, 271, 119, 5, 0, 7, 0, 0, 0, 0,
                0, 0, 0, 0, 0,
            ],
            [
                156, 235, 388, 483, 430, 262, 165, 136, 129, 213, 291, 444, 319, 64, 3, 36, 0, 0, 0,
                0, 0, 0, 0, 0, 0,
            ],
            [
                435, 186, 197, 355, 490, 636, 522, 378, 230, 182, 281, 477, 701, 315, 99, 118, 0, 0,
                0, 0, 0, 0, 0, 0, 0,
            ],
            [
                408, 612, 319, 247, 334, 495, 683, 791, 707, 420, 319, 408, 921, 684, 496, 437, 18,
                0, 0, 0, 0, 0, 0, 0, 0,
            ],
            [
                163, 297, 591, 619, 369, 341, 487, 760, 1147, 1376, 1086, 617, 666, 1371, 1466, 630,
                162, 1, 0, 0, 0, 0, 0, 0, 0,
            ],
            [
                103, 173, 387, 630, 794, 590, 572, 703, 1160, 1917, 2556, 2837, 1694, 2339, 3107,
                1106, 793, 266, 218, 152, 77, 22, 0, 0, 2,
            ],
            [
                210, 310, 397, 531, 681, 785, 685, 586, 868, 1346, 2469, 4205, 7020, 7478, 6492,
                2918, 1084, 158, 98, 122, 144, 154, 138, 76, 40,
            ],
            [
                195, 229, 279, 453, 590, 712, 1121, 1472, 1550, 2100, 2381, 3339, 5923, 9156, 9502,
                6446, 3394, 870, 477, 196, 74, 50, 38, 17, 9,
            ],
            [
                67, 105, 176, 320, 541, 825, 891, 1169, 1744, 2112, 2609, 3156, 4994, 6478, 6165,
                8291, 9011, 1477, 879, 807, 492, 221, 152, 80, 46,
            ],
            [
                52, 106, 153, 188, 269, 540, 645, 798, 1161, 1832, 3960, 6334, 7087, 3176, 2267,
                3545, 6078, 3945, 1923, 1653, 1399, 877, 548, 246, 113,
            ],
            [
                47, 57, 73, 107, 186, 267, 416, 676, 744, 1080, 1748, 2066, 5578, 3244, 1371, 2485,
                3073, 3055, 1588, 740, 1065, 1426, 1227, 647, 397,
            ],
            [
                77, 91, 118, 209, 335, 406, 432, 475, 550, 868, 899, 838, 2367, 4725, 3244, 2927,
                2380, 1814, 2035, 750, 330, 506, 668, 833, 568,
            ],
            [
                64, 83, 126, 118, 131, 156, 179, 172, 178, 278, 198, 597, 1603, 2612, 4460, 3336,
                2926, 2320, 1393, 1665, 658, 196, 240, 335, 474,
            ],
            [
                26, 40, 55, 61, 53, 24, 16, 34, 118, 182, 395, 540, 1025, 1385, 2300, 2550, 1916,
                1763, 1206, 1172, 1023, 483, 202, 137, 214,
            ],
            [
                6, 6, 6, 3, 3, 5, 10, 49, 120, 286, 334, 626, 689, 708, 1636, 1471, 1455, 1151,
                1243, 736, 875, 602, 266, 162, 126,
            ],
            [
                0, 0, 0, 0, 0, 1, 18, 50, 133, 279, 308, 610, 552, 383, 858, 1353, 1381, 1038, 850,
                790, 813, 618, 359, 145, 74,
            ],
            [
                0, 0, 0, 0, 0, 4, 27, 54, 182, 201, 249, 503, 339, 278, 387, 796, 821, 1223, 790,
                581, 604, 625, 525, 211, 71,
            ],
            [
                0, 0, 0, 0, 0, 7, 26, 91, 143, 150, 304, 365, 232, 162, 208, 389, 394, 684, 938,
                597, 451, 542, 416, 357, 139,
            ],
            [
                0, 0, 0, 0, 1, 7, 49, 80, 124, 120, 275, 288, 130, 108, 133, 203, 231, 412, 598,
                658, 531, 376, 392, 348, 237,
            ],
        ],
        NO2: [
            [5, 3, 7, 10, 11, 10, 10, 8, 6, 7, 11, 19, 6, 3, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [7, 5, 2, 4, 9, 13, 13, 14, 14, 11, 11, 16, 20, 9, 5, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [3, 5, 7, 5, 4, 5, 11, 16, 19, 21, 21, 18, 31, 25, 11, 8, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [6, 5, 4, 6, 6, 5, 6, 7, 14, 20, 28, 33, 41, 51, 26, 18, 2, 0, 0, 0, 0, 0, 0, 0, 0],
            [9, 8, 6, 5, 9, 13, 11, 10, 11, 13, 20, 37, 52, 63, 73, 34, 5, 0, 0, 0, 0, 0, 0, 0, 0],
            [
                6, 9, 10, 8, 7, 12, 16, 19, 19, 22, 33, 73, 91, 121, 152, 115, 24, 19, 8, 3, 1, 0,
                0, 0, 0,
            ],
            [
                3, 7, 13, 15, 12, 15, 21, 26, 39, 55, 63, 86, 122, 163, 228, 165, 156, 93, 42, 22,
                14, 11, 3, 0, 0,
            ],
            [
                4, 6, 10, 16, 17, 18, 21, 26, 33, 44, 70, 112, 158, 87, 66, 127, 108, 106, 87, 62,
                37, 23, 19, 14, 10,
            ],
            [
                4, 6, 10, 17, 19, 19, 24, 30, 41, 51, 70, 155, 249, 118, 110, 103, 35, 63, 51, 37,
                36, 32, 23, 14, 8,
            ],
            [
                4, 6, 8, 12, 14, 16, 15, 22, 27, 39, 51, 81, 85, 88, 106, 73, 38, 27, 46, 31, 19,
                12, 15, 13, 12,
            ],
            [
                4, 6, 8, 10, 10, 13, 10, 18, 17, 27, 37, 69, 67, 72, 80, 40, 29, 17, 23, 35, 21, 12,
                8, 4, 4,
            ],
            [
                5, 8, 7, 8, 5, 7, 7, 18, 22, 30, 47, 63, 116, 64, 58, 30, 24, 14, 13, 23, 30, 14, 7,
                4, 1,
            ],
            [
                13, 11, 16, 12, 7, 4, 10, 20, 23, 16, 23, 22, 26, 38, 75, 43, 31, 21, 15, 13, 19,
                22, 10, 6, 3,
            ],
            [
                25, 19, 15, 29, 16, 8, 29, 41, 9, 9, 6, 6, 17, 30, 57, 46, 26, 20, 15, 14, 13, 18,
                14, 7, 4,
            ],
            [
                40, 37, 41, 50, 47, 81, 56, 10, 3, 7, 5, 7, 11, 22, 37, 48, 30, 18, 12, 11, 9, 13,
                13, 8, 4,
            ],
            [
                33, 65, 156, 335, 344, 106, 34, 28, 33, 18, 10, 6, 8, 17, 19, 19, 23, 20, 12, 8, 8,
                8, 8, 6, 5,
            ],
            [
                55, 86, 112, 326, 193, 107, 44, 16, 22, 29, 21, 14, 11, 14, 17, 10, 12, 14, 12, 7,
                4, 6, 7, 6, 3,
            ],
            [
                32, 25, 83, 161, 87, 100, 53, 34, 12, 9, 11, 13, 11, 10, 12, 12, 12, 9, 11, 8, 5, 3,
                5, 5, 4,
            ],
            [11, 26, 63, 84, 52, 58, 55, 30, 24, 9, 6, 7, 7, 7, 5, 7, 11, 10, 10, 7, 6, 3, 3, 4, 4],
            [11, 25, 34, 49, 38, 36, 42, 29, 16, 17, 10, 5, 4, 3, 3, 4, 4, 8, 8, 8, 7, 4, 2, 2, 3],
            [11, 19, 24, 35, 30, 26, 28, 31, 15, 11, 14, 8, 5, 2, 1, 2, 2, 5, 7, 6, 5, 7, 3, 2, 2],
        ],
    },
    266: {
        PM25: [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 3, 5],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 3, 4, 7],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 2, 2, 11, 14],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 3, 3, 3, 2, 3, 13, 30, 46],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3, 2, 2, 4, 25, 52, 66, 49, 24],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 2, 2, 2, 25, 68, 72, 43, 15, 5, 3],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 2, 2, 5, 55, 70, 31, 6, 3, 3, 3, 2],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 2, 10, 58, 42, 5, 4, 3, 3, 2, 2, 2],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 4, 8, 14, 7, 4, 3, 3, 3, 4, 4, 4, 3],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 2, 6, 5, 1, 8, 4, 4, 5, 4, 3, 3, 2, 2, 3],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 3, 6, 3, 1, 0, 3, 5, 2, 3, 6, 6, 4, 4, 4, 4],
            [0, 0, 0, 0, 0, 0, 0, 1, 3, 4, 3, 1, 0, 0, 0, 1, 4, 3, 2, 4, 4, 6, 5, 2, 2],
            [0, 0, 0, 0, 0, 1, 3, 4, 2, 1, 1, 0, 0, 0, 0, 1, 4, 4, 2, 2, 3, 4, 4, 7, 3],
            [0, 0, 0, 1, 2, 3, 2, 1, 1, 0, 0, 0, 0, 0, 0, 0, 2, 4, 3, 1, 1, 2, 3, 3, 5],
            [0, 1, 2, 2, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 3, 3, 2, 1, 1, 2, 2, 2],
            [1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 3, 2, 1, 1, 1, 1, 2],
            [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3, 1, 1, 0, 1, 1],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 2, 1, 0, 0, 0],
        ],
    },
};
