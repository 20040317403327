import { createAction, props } from '@ngrx/store';
import {
    CorrelationAnalysisData,
    CorrelationData,
    CorrelationPeriod,
    ITimeseriesDataItem,
    NetworkAnalysisData,
    Post,
    SortingData,
    WindData,
} from '@libs/common';
import { ANALYSIS_PAGES } from '@cityair/modules/analysis/models';
import { PinModel } from '@cityair/namespace';
import { Feature } from 'geojson';

export enum AnalysisActions {
    toggleModule = '[AnalysisActions] toggleModule',
    setAllMeasurements = '[AnalysisActions] set all measurements',
    initAnalysisModule = '[AnalysisActions] init Analysis Module',
    setCurrentMmt = '[AnalysisActions] set current mmt',
    setPeriod = '[AnalysisActions] set period',
    isLoadingData = '[AnalysisActions] is loading data',
    setTimelineDates = '[AnalysisActions] set timeline dates',
    setCorrelationData = '[AnalysisActions] set correlation data',
    setCorrelationDataList = '[AnalysisActions] set correlation data list',
    setActiveCorrelationPost = '[AnalysisActions] set active correlation post',
    setActiveCorrelationPair = '[AnalysisActions] set active correlation pair',
    isLoadingPostTimeline = '[AnalysisActions] is loading post timeline',
    timelinePostsLoaded = '[AnalysisActions] timeline Post Loaded',
    setErrorCorrelationData = '[AnalysisActions] set error correlation data',
    setCorrelationChartDataPair = '[AnalysisActions] set Correlation Chart Data Pair',
    setIsLoadingChartCorrelationData = '[AnalysisActions] set Is Loading Chart Correlation Data',
    setCurrentTab = '[AnalysisActions] set current tab',
    setPostDistance = '[AnalysisActions] set posts distance',
    setPostHover = '[AnalysisActions] set post hover',
    setActiveLine = '[AnalysisActions] set active line',
    setSortingPosts = '[AnalysisActions] set sorting posts',
    setSortingLinks = '[AnalysisActions] set sorting links',
    clickToPinForLinks = '[AnalysisActions] click to pin for links',
    setComparedItems = '[AnalysisActions] set compare items',
    setNetworkAnalysisData = '[AnalysisActions] set network analysis data',
    setActiveHoverLine = '[AnalysisActions] set active hover line',
    setSearchQueryPosts = '[AnalysisActions] set search query posts',
    setSearchQueryLinks = '[AnalysisActions] set search query links',
    setWindData = '[AnalysisActions] set wind data',
}
export const toggleAnalysisModule = createAction(
    AnalysisActions.toggleModule,
    props<{ payload: boolean }>()
);
export const initAnalysisModule = createAction(AnalysisActions.initAnalysisModule);

export const setAllMeasurements = createAction(
    AnalysisActions.setAllMeasurements,
    props<{ payload: string[] }>()
);
export const setCurrentMmt = createAction(
    AnalysisActions.setCurrentMmt,
    props<{ payload: string }>()
);
export const setPeriod = createAction(
    AnalysisActions.setPeriod,
    props<{ payload: CorrelationPeriod }>()
);
export const isLoadingData = createAction(
    AnalysisActions.isLoadingData,
    props<{ payload: boolean }>()
);
export const isLoadingPostTimeline = createAction(
    AnalysisActions.isLoadingPostTimeline,
    props<{ payload: boolean }>()
);
export const setTimelineDates = createAction(
    AnalysisActions.setTimelineDates,
    props<{ payload: string[] }>()
);
export const setCorrelationData = createAction(
    AnalysisActions.setCorrelationData,
    props<{ payload: { data: CorrelationData; posts: Post[] } }>()
);
export const setCorrelationDataList = createAction(
    AnalysisActions.setCorrelationDataList,
    props<{ payload: CorrelationAnalysisData[] }>()
);
export const setActiveCorrelationPost = createAction(
    AnalysisActions.setActiveCorrelationPost,
    props<{ postId: string }>()
);
export const setActiveCorrelationPair = createAction(
    AnalysisActions.setActiveCorrelationPair,
    props<{ payload: CorrelationAnalysisData }>()
);
export const setCorrelationChartDataPair = createAction(
    AnalysisActions.setCorrelationChartDataPair,
    props<{ payload: number[] }>()
);
export const setIsLoadingChartCorrelationData = createAction(
    AnalysisActions.setIsLoadingChartCorrelationData,
    props<{ payload: boolean }>()
);
export const timelinePostsLoaded = createAction(
    AnalysisActions.timelinePostsLoaded,
    props<{ payload: ITimeseriesDataItem[] }>()
);
export const setErrorCorrelationData = createAction(
    AnalysisActions.setErrorCorrelationData,
    props<{ payload: boolean }>()
);
export const setCurrentTab = createAction(
    AnalysisActions.setCurrentTab,
    props<{ payload: ANALYSIS_PAGES }>()
);
export const setPostDistance = createAction(
    AnalysisActions.setPostDistance,
    props<{ payload: any }>()
);
export const setPostHover = createAction(
    AnalysisActions.setPostHover,
    props<{ payload: string[] }>()
);
export const setActiveLine = createAction(
    AnalysisActions.setActiveLine,
    props<{ payload: GeoJSON.Feature<GeoJSON.Geometry> }>()
);
export const setSortingPosts = createAction(
    AnalysisActions.setSortingPosts,
    props<{ payload: SortingData }>()
);
export const setSortingLinks = createAction(
    AnalysisActions.setSortingLinks,
    props<{ payload: SortingData }>()
);
export const clickToPinForLinks = createAction(
    AnalysisActions.clickToPinForLinks,
    props<{ payload: PinModel }>()
);
export const setComparedItems = createAction(
    AnalysisActions.setComparedItems,
    props<{ payload: PinModel[] }>()
);
export const setNetworkAnalysisData = createAction(
    AnalysisActions.setNetworkAnalysisData,
    props<{ payload: NetworkAnalysisData }>()
);
export const setActiveHoverLine = createAction(
    AnalysisActions.setActiveHoverLine,
    props<{ payload: Feature[] }>()
);
export const setSearchQueryPosts = createAction(
    AnalysisActions.setSearchQueryPosts,
    props<{ payload: string }>()
);
export const setSearchQueryLinks = createAction(
    AnalysisActions.setSearchQueryLinks,
    props<{ payload: string }>()
);
export const setWindData = createAction(
    AnalysisActions.setWindData,
    props<{ payload: WindData }>()
);
