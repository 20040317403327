<forecast-top-elements></forecast-top-elements>

<div class="timeline-temp-wrapper">
    <div
        class="timeline__wrapper timeline__wrapper-displayFlex"
        *ngIf="store.select(stationLoaded) | async"
    >
        <div
            class="timeline__top"
            [class.timeline-panel]="store.select(selectSidebarIsOpen) | async"
        >
            <color-legend
                class="legend-timeline"
                *ngIf="store.select(selectForecastSchemaZones) | async as val"
                [unit]="TEXTS.MEASURES_SCHEME[val.scheme][val.mmt]"
                [measure]="TEXTS.NAMES[val.mmt]"
                [colors]="(store.select(selectForecastZone) | async)?.color"
                [values]="(store.select(selectForecastZone) | async)?.zone"
            ></color-legend>
            <map-style-selector *ngIf="store.select(selectMapStyleTypes) | async as types"
                [types]="types"
                [currentType]="store.select(selectCurrentMapStyleType) | async"
                [loading]="store.select(selectMapStyleLoading) | async"
                (setType)="store.dispatch(setMapStyleType({payload: $event}))"
            ></map-style-selector>
            <timeline-panel
                [isCityMode]="store.select(selectIsCityMode) | async"
                [currentTime]="store.select(selectCurrentTimeForecast) | async"
                [dates]="store.select(selectDatesForecast) | async"
                [features]="store.select(selectChartData) | async"
                [initSelectMeasurement]="store.select(currentForecastMmt) | async"
                [labelMode]="true"
                [showNow]="true"
                [pdk]="store.select(selectPdkForChart) | async"
                [chartMinMax]="chartMinMax"
                [measureScheme]="store.select(selectMeasureScheme) | async"
                [timezone]="store.select(selectUserTimezoneLabel) | async"
                (changeTimeIndex)="store.dispatch(updateTimeIndex({ payload: $event }))"
                (goToCity)="goToCity($event)"
            ></timeline-panel>
        </div>
    </div>
</div>
