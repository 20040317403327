<cityair-map-control [isOpenSidebar]="store.select(selectIsOpenSidebar) | async">
    <div map-control-period *ngIf="store.select(selectAnalysisDateRange) | async as dateRange">
        <div (clickOutside)="isShowDropdownForPeriod = false" class="input_date_range analysis-map-elements">
            <input-for-dropdown
                [inputValue]="{start: dateRange.startDate, end: dateRange.finishDate} | dateRangeText : true"
                [(isOpenDropdown)]="isShowDropdownForPeriod"
            ></input-for-dropdown>
            <div class="selectbox-wrapper" *ngIf="isShowDropdownForPeriod">
                <selectbox-radio
                    class="mo-view-select-box"
                    [listItems]="periodList"
                    (listItemsChange)="
                        updatePeriod($event); isShowDropdownForPeriod = false
                    "
                ></selectbox-radio>
            </div>
        </div>
    </div>
    <div map-control-mmts>
        <shared-ui-mmt-selector
            class="analysis-mmt"
            [title]="textsForMapControl.on"
            [mmts]="store.select(selectAllMeasurements) | async"
            [activeMmt]="store.select(selectCurrentMmt) | async"
            (selectMmt)="store.dispatch(setCurrentMmt({ payload: $event }))"
        ></shared-ui-mmt-selector>
    </div>
</cityair-map-control>
