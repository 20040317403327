import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { networksRoutes } from './posts-and-devices.route';

import { LittleComponentsModule, SearchResultPlaceholderComponent } from '@libs/shared-ui';

import { PostsAndDevicesPageComponent } from './components/posts-and-devices-page/posts-and-devices-page.component';
import { PostsListComponent } from './components/posts-list/posts-list.component';
import { DevicesListComponent } from './components/devices-list/devices-list.component';
import { PostDetailsComponent } from './components/post-details/post-details.component';
import { PostEditComponent } from './components/post-edit/post-edit.component';
import { PostCreateComponent } from './components/post-create/post-create.component';
import { PostReportTzaComponent } from './components/post-report-tza/post-report-tza.component';
import { DeviceDetailsComponent } from './components/device-details/device-details.component';
import { PostsAndDevicesHeaderComponent } from './components/posts-and-devices-header/posts-and-devices-header.component';
import { DeviceReportComponent } from './components/device-report/device-report.component';
import { DetailsHeaderComponent } from './components/details-header/details-header.component';
import { HeaderOfPanelModule } from '@libs/shared-ui';
import { ButtonAddModule } from '@libs/shared-ui';
import { SwitchV2Module } from '../../components/switch-v2/switch-v2.module';
import { MonitoringObjectPlateModule } from '../../components/monitoring-object-plate/monitoring-object-plate.module';
import { DeviceCardModule } from '../../components/device-card/device-card.module';
import { SortListItemModule } from '@libs/shared-ui';
import { PageTabsModule } from '@libs/shared-ui';
import { SearchInputModule } from '@libs/shared-ui';
import { SearchInputBasicModule } from '@libs/shared-ui';
import { CardActionsModule } from '@libs/shared-ui';
import { ButtonDownloadDataModule } from '@libs/shared-ui';
import { NonDataScreenModule } from '@libs/shared-ui';
import { KebabMenuModule } from '@libs/shared-ui';
import { InformationColumnModule } from '@cityair/components/information-column/information-column.module';
import { SectionLineModule } from '@libs/shared-ui';
import { ArrowModule } from '@libs/shared-ui';
import { StaticTagsModule } from '@libs/shared-ui';
import { InformationStringModule } from '@cityair/components/information-string/information-string.module';
import { LinkOnDocumentModule } from '@cityair/components/link-on-document/link-on-document.module';
import { AlertTagsModule } from '@libs/shared-ui';
import { InputModule } from '@libs/shared-ui';
import { InputForCoordinateModule } from '@libs/shared-ui';
import {
    TextareaModule,
    InputDropdownModule,
    SelectboxModule,
    SelectboxRadioModule,
    InputDateRangeModule,
} from '@libs/shared-ui';
import { GrayBackgroundModule } from '@cityair/components/gray-background/gray-background.module';
import { BtnAcceptModule } from '@libs/shared-ui';
import { BtnCancelModule } from '@libs/shared-ui';
import { BtnExtraModule } from '@libs/shared-ui';
import { TagsListModule } from '@libs/shared-ui';
import { SwitchItemModule } from '@libs/shared-ui';
import { CheckboxModule } from '@libs/shared-ui';
import { PipesModule } from '@libs/shared-ui';
import { PopupAskModule } from '@libs/shared-ui';
import { BtnAccordionModule } from '@cityair/components/btn-accordion/btn-accordion.module';
import { TooltipsModule } from '@libs/shared-ui';
import { AqiDetailsTooltipModule } from '@libs/shared-ui';
import { DirectivesModule } from '@libs/shared-ui';
import { ButtonModule } from '@libs/shared-ui';
import { ButtonsSaveCancelModule } from '@libs/shared-ui';
import { LoadersModule } from '@libs/shared-ui';

@NgModule({
    imports: [
        RouterModule.forRoot(networksRoutes),
        CommonModule,
        FormsModule,
        ScrollingModule,
        ReactiveFormsModule,
        LittleComponentsModule,
        HeaderOfPanelModule,
        SearchInputBasicModule,
        SearchInputModule,
        ButtonAddModule,
        SwitchV2Module,
        MonitoringObjectPlateModule,
        SortListItemModule,
        DeviceCardModule,
        PageTabsModule,
        CardActionsModule,
        DirectivesModule,
        ButtonDownloadDataModule,
        SearchResultPlaceholderComponent,
        NonDataScreenModule,
        KebabMenuModule,
        InformationColumnModule,
        SectionLineModule,
        ArrowModule,
        StaticTagsModule,
        InformationStringModule,
        LinkOnDocumentModule,
        AlertTagsModule,
        InputModule,
        TextareaModule,
        InputForCoordinateModule,
        InputDropdownModule,
        SelectboxModule,
        SelectboxRadioModule,
        InputDateRangeModule,
        GrayBackgroundModule,
        BtnAcceptModule,
        BtnCancelModule,
        BtnExtraModule,
        TagsListModule,
        SwitchItemModule,
        CheckboxModule,
        PipesModule,
        PopupAskModule,
        BtnAccordionModule,
        TooltipsModule,
        AqiDetailsTooltipModule,
        ButtonModule,
        ButtonsSaveCancelModule,
        LoadersModule,
    ],
    exports: [
        PostsAndDevicesPageComponent,
        PostsListComponent,
        DevicesListComponent,
        PostDetailsComponent,
        PostEditComponent,
        PostCreateComponent,
        PostReportTzaComponent,
        DeviceDetailsComponent,
        DeviceReportComponent,
        PostsAndDevicesHeaderComponent,
    ],
    declarations: [
        PostsAndDevicesPageComponent,
        PostsListComponent,
        DevicesListComponent,
        PostDetailsComponent,
        PostEditComponent,
        PostCreateComponent,
        PostReportTzaComponent,
        DeviceDetailsComponent,
        DeviceReportComponent,
        PostsAndDevicesHeaderComponent,
        DetailsHeaderComponent,
    ],
})
export class PostsAndDevicesModule {}
