import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AQI, measureZones, TIME_FORMAT, TEXTS } from '@libs/common';
import * as moment from 'moment-timezone';

@Component({
    selector: 'cs-aqi-indicator',
    templateUrl: './aqi-indicator.component.html',
    styleUrls: ['./aqi-indicator.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AqiIndicatorComponent implements OnChanges {
    @Input() value: number;
    @Input() dateTime?: number;
    @Input() mmt: string;

    date: string;
    time: string;

    measureZones = measureZones;
    AQI = AQI;
    TEXTS = TEXTS;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.dateTime || changes.tzOffset) {
            const { dateTime } = this;

            if (dateTime) {
                const d = moment(dateTime);
                this.time = d.format(TIME_FORMAT);
                this.date = `${d.format('D')} ${d.format('MMM').substring(0, 3)}`;
            }
        }
    }
}
