import { ActionReducerMap, createReducer, on } from '@ngrx/store';
import * as analysisActions from './actions';
import {
    CorrelationAnalysisData,
    CorrelationData,
    CorrelationPeriod,
    ITimeseriesDataItem,
    NetworkAnalysisData,
    SortingData,
    getCorrelationData,
    WindData,
} from '@libs/common';
import { EntityState } from '@ngrx/entity';
import { timelineAdapter } from '@libs/shared-store';
import { ANALYSIS_PAGES } from '@cityair/modules/analysis/models';
import { PinModel } from '@cityair/namespace';
import { Feature } from 'geojson';

export interface DateRange {
    startDate: string;
    finishDate: string;
}
export interface AnalysisState {
    currentTab: ANALYSIS_PAGES;
    currentPeriod: CorrelationPeriod;
    isActiveModule: boolean;
    allMeasurements: string[];
    currentMmt: string;
    isLoadingData: boolean;
    dates: string[];
    correlationData: CorrelationData;
    correlationDataList: CorrelationAnalysisData[];
    activePost: string;
    activePair: CorrelationAnalysisData;
    isLoadingPostTimeline: boolean;
    postsTimeLine: EntityState<ITimeseriesDataItem>;
    errorLoadCorrelationData: boolean;
    correlationPairDataChart: number[];
    isLoadingPairChartData: boolean;
    postsDistance: any;
    hoverPosts: string[];
    sortingPosts: SortingData;
    sortingLinks: SortingData;
    comparedItems: PinModel[];
    networkAnalysisData: NetworkAnalysisData;
    hoverLine: Feature[];
    searchQueryPosts: string;
    searchQueryLinks: string;
    windData: WindData;
}

export const initialAnalysisState: AnalysisState = {
    currentTab: null,
    currentPeriod: CorrelationPeriod.month,
    isActiveModule: false,
    allMeasurements: [],
    currentMmt: null,
    isLoadingData: true,
    dates: [],
    correlationData: null,
    correlationDataList: null,
    activePost: null,
    activePair: null,
    isLoadingPostTimeline: true,
    postsTimeLine: timelineAdapter.getInitialState(),
    errorLoadCorrelationData: false,
    correlationPairDataChart: null,
    isLoadingPairChartData: false,
    postsDistance: null,
    hoverPosts: [],
    sortingPosts: {
        name: 'name',
        dir: -1,
    },
    sortingLinks: {
        name: 'value',
        dir: 1,
    },
    comparedItems: [],
    networkAnalysisData: null,
    hoverLine: [],
    searchQueryPosts: null,
    searchQueryLinks: null,
    windData: null,
};

const _analysisReducer = createReducer(
    initialAnalysisState,
    on(analysisActions.setCurrentTab, (state: AnalysisState, { payload }) => ({
        ...state,
        currentTab: payload,
    })),
    on(analysisActions.toggleAnalysisModule, (state: AnalysisState, props) => ({
        ...state,
        isActiveModule: props.payload,
    })),
    on(analysisActions.setAllMeasurements, (state: AnalysisState, props) => ({
        ...state,
        allMeasurements: props.payload,
    })),
    on(analysisActions.setCurrentMmt, (state: AnalysisState, props) => ({
        ...state,
        currentMmt: props.payload,
    })),
    on(analysisActions.setPeriod, (state: AnalysisState, { payload }) => ({
        ...state,
        currentPeriod: payload,
    })),
    on(analysisActions.isLoadingPostTimeline, (state: AnalysisState, { payload }) => ({
        ...state,
        isLoadingPostTimeline: payload,
    })),
    on(analysisActions.isLoadingData, (state: AnalysisState, { payload }) => ({
        ...state,
        isLoadingData: payload,
    })),
    on(analysisActions.setTimelineDates, (state: AnalysisState, { payload }) => ({
        ...state,
        dates: payload,
    })),
    on(analysisActions.setCorrelationData, (state: AnalysisState, { payload }) => {
        const correlationDataList = getCorrelationData(
            payload.posts,
            payload.data[state.currentMmt][state.currentPeriod]?.coefficients
        );
        return {
            ...state,
            correlationData: payload.data,
            correlationDataList,
        };
    }),
    on(analysisActions.setCorrelationDataList, (state: AnalysisState, { payload }) => ({
        ...state,
        correlationDataList: payload,
        isLoadingData: false,
    })),
    on(analysisActions.setActiveCorrelationPost, (state: AnalysisState, { postId }) => ({
        ...state,
        activePost: postId,
    })),
    on(analysisActions.setActiveCorrelationPair, (state: AnalysisState, { payload }) => ({
        ...state,
        activePair: payload,
        correlationPairDataChart: null,
    })),
    on(analysisActions.timelinePostsLoaded, (state: AnalysisState, { payload }) => {
        const postsTimeLine = timelineAdapter.upsertMany(payload, state.postsTimeLine);
        return {
            ...state,
            postsTimeLine,
        };
    }),
    on(analysisActions.setErrorCorrelationData, (state: AnalysisState, { payload }) => ({
        ...state,
        errorLoadCorrelationData: payload,
    })),
    on(analysisActions.setCorrelationChartDataPair, (state: AnalysisState, { payload }) => ({
        ...state,
        correlationPairDataChart: payload,
        isLoadingPairChartData: false,
    })),
    on(analysisActions.setIsLoadingChartCorrelationData, (state: AnalysisState, { payload }) => ({
        ...state,
        isLoadingPairChartData: payload,
    })),
    on(analysisActions.setPostDistance, (state: AnalysisState, { payload }) => ({
        ...state,
        postsDistance: payload,
    })),
    on(analysisActions.setPostHover, (state: AnalysisState, { payload }) => ({
        ...state,
        hoverPosts: payload,
    })),
    on(analysisActions.setSortingPosts, (state: AnalysisState, { payload }) => ({
        ...state,
        sortingPosts: payload,
    })),
    on(analysisActions.setSortingLinks, (state: AnalysisState, { payload }) => ({
        ...state,
        sortingLinks: payload,
    })),
    on(analysisActions.setComparedItems, (state: AnalysisState, { payload }) => ({
        ...state,
        comparedItems: payload,
    })),
    on(analysisActions.setNetworkAnalysisData, (state: AnalysisState, { payload }) => ({
        ...state,
        networkAnalysisData: payload,
    })),
    on(analysisActions.setActiveHoverLine, (state: AnalysisState, { payload }) => ({
        ...state,
        hoverLine: payload,
    })),
    on(analysisActions.setSearchQueryPosts, (state: AnalysisState, { payload }) => ({
        ...state,
        searchQueryPosts: payload,
    })),
    on(analysisActions.setSearchQueryLinks, (state: AnalysisState, { payload }) => ({
        ...state,
        searchQueryLinks: payload,
    })),
    on(analysisActions.setWindData, (state: AnalysisState, { payload }) => ({
        ...state,
        windData: payload,
    }))
);
export interface IAnalysisState {
    core: AnalysisState;
}
export function analysisReducer(state, action): any {
    return _analysisReducer(state, action);
}

export const analysisReducers: ActionReducerMap<IAnalysisState> = { core: analysisReducer };
