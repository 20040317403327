import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { TEXTS } from '@libs/common';
import { ANIMATION_OPACITY } from '@libs/common';
@Component({
    selector: 'capi-aqi-legend',
    templateUrl: './capi-aqi-legend.component.html',
    styleUrls: ['./capi-aqi-legend.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: ANIMATION_OPACITY,
})
export class CapiAqiLegendComponent implements OnChanges {
    @Input() aqi: number;

    TEXTS = TEXTS;

    currentAqiDangerLevel: number;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.aqi) {
            this.currentAqiDangerLevel = this.aqiToDangerLevel(this.aqi);
        }
    }

    aqiToDangerLevel(i: number) {
        if (i >= 0 && i <= 4) {
            return 0;
        } else if (i >= 5 && i <= 6) {
            return 1;
        } else if (i >= 7 && i <= 13) {
            return 2;
        } else if (i >= 14) {
            return 3;
        }
    }
}
