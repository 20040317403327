import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AnalysisState, DateRange, IAnalysisState } from './reducers';
import { QueryParams } from '@ngrx/data';
import * as moment from 'moment-timezone';
import {
    CorrelationPeriod,
    getLatLngCenter,
    getZoomLevelByDistance,
    getZoomLevelByGroup,
    MeasureScheme,
} from '@libs/common';
import { selectMapLoaded } from '@cityair/modules/core/store/selectors';
import { ANALYSIS_PAGES } from '@cityair/modules/analysis/models';
import { getLineData, getPostAreaData } from '@cityair/modules/analysis/utils';
import {
    NETWORK_EXTEND_BORDER,
    NETWORK_EXTEND_COLOR,
    NETWORK_EXTEND_OPACITY,
} from '@cityair/modules/analysis/constants';
import { ControlPointModel } from '@cityair/namespace';
import { WIND_DIR_BY_POST } from '@libs/common';
import {
    selectCurrentMeasureScheme,
    selectExtConfig,
    selectGroupId,
    selectAllPosts,
    selectAllPostsDic,
    timelineAdapter,
} from '@libs/shared-store';

export const analysisState = createFeatureSelector<IAnalysisState>('analysis');

export const selectAnalysis = createSelector(
    analysisState,
    (state: IAnalysisState): AnalysisState => state.core
);
export const selectAllPostsAnalysis = createSelector(
    selectAllPosts,
    selectExtConfig,
    (allPosts, extConfig) => {
        if (!extConfig) {
            return allPosts;
        } else if (
            extConfig?.analysisModuleSettings === null ||
            extConfig?.analysisModuleSettings?.config === null
        ) {
            return allPosts;
        } else {
            const config = extConfig.analysisModuleSettings?.config;
            if (config?.posts_include?.length) {
                const postsInclude = config.posts_include?.map((v) => `post_${v}`);
                return allPosts.filter((v) => postsInclude.includes(v.id));
            }
            if (config?.posts_exclude?.length) {
                const postsExclude = config.posts_exclude?.map((v) => `post_${v}`);
                return allPosts.filter((v) => !postsExclude.includes(v.id));
            }

            return allPosts;
        }
    }
);
export const selectAllMeasurements = createSelector(
    selectAnalysis,
    (state: AnalysisState) => state.allMeasurements
);
export const selectCurrentMmt = createSelector(
    selectAnalysis,
    (state: AnalysisState) => state.currentMmt
);
export const selectCurrentPeriod = createSelector(
    selectAnalysis,
    (state: AnalysisState) => state.currentPeriod
);
export const selectIsLoadingData = createSelector(
    selectAnalysis,
    (state: AnalysisState) => state.isLoadingData
);
export const selectAnalysisDates = createSelector(
    selectAnalysis,
    (state: AnalysisState) => state.dates
);
export const selectCorrelationAnalysisData = createSelector(
    selectAnalysis,
    (state: AnalysisState) => state.correlationDataList
);
export const selectErrorLoadCorrelationData = createSelector(
    selectAnalysis,
    (state: AnalysisState) => state.errorLoadCorrelationData
);
export const selectCorrelationActivePost = createSelector(
    selectAnalysis,
    (state: AnalysisState) => state.activePost
);
export const selectCorrelationActivePair = createSelector(
    selectAnalysis,
    (state: AnalysisState) => state.activePair
);
export const selectPostsTimelineDic = createSelector(selectAnalysis, (state: AnalysisState) =>
    timelineAdapter.getSelectors().selectEntities(state.postsTimeLine)
);
export const selectCorrelationChartDataPair = createSelector(
    selectAnalysis,
    (state: AnalysisState) => state.correlationPairDataChart
);
export const selectIsLoadingPostTimeline = createSelector(
    selectAnalysis,
    (state: AnalysisState) => state.isLoadingPostTimeline
);
export const selectIsLoadingChartPair = createSelector(
    selectAnalysis,
    (state: AnalysisState) => state.isLoadingPairChartData || state.isLoadingPostTimeline
);
export const selectCurrentTab = createSelector(
    selectAnalysis,
    (state: AnalysisState) => state.currentTab
);
export const selectPostsDistance = createSelector(
    selectAnalysis,
    (state: AnalysisState) => state.postsDistance
);
export const selectHoverCorrelationPosts = createSelector(
    selectAnalysis,
    (state: AnalysisState) => state.hoverPosts
);
export const selectSortingDataPosts = createSelector(
    selectAnalysis,
    (state: AnalysisState) => state.sortingPosts
);
export const selectSortingDataLinks = createSelector(
    selectAnalysis,
    (state: AnalysisState) => state.sortingLinks
);
export const selectComparedItems = createSelector(
    selectAnalysis,
    (state: AnalysisState) => state.comparedItems
);
export const selectNetworkAnalysisData = createSelector(
    selectAnalysis,
    (state: AnalysisState) => state.networkAnalysisData
);
export const selectSearchQueryPosts = createSelector(
    selectAnalysis,
    (state: AnalysisState) => state.searchQueryPosts
);
export const selectSearchQueryLinks = createSelector(
    selectAnalysis,
    (state: AnalysisState) => state.searchQueryLinks
);
export const selectWindDataByPost = (id) =>
    createSelector(selectAnalysis, selectExtConfig, (state: AnalysisState, config) =>
        state.windData ? state.windData[id] : config?.isDemoModeForEvents ? WIND_DIR_BY_POST[id] : null
    );
export const selectActiveHoverLine = createSelector(selectAnalysis, (state: AnalysisState) =>
    state.currentTab === ANALYSIS_PAGES.posts ? state.hoverLine : []
);

export const selectIsShowTimelineChart = createSelector(
    selectCorrelationActivePair,
    selectCurrentTab,
    (activePair, tab) => activePair !== null && tab === ANALYSIS_PAGES.links
);
export const selectCorrelationData = createSelector(
    selectAnalysis,
    (state: AnalysisState) => state.correlationData
);
export const selectDataCorrelationByPeriodAndMmt = createSelector(
    selectCorrelationData,
    selectCurrentPeriod,
    selectCurrentMmt,
    (correlationData, period, mmt) =>
        correlationData &&
        correlationData.hasOwnProperty(mmt) &&
        correlationData[mmt].hasOwnProperty(period)
            ? correlationData[mmt][period] ?? null
            : null
);
export const selectAnalysisDateRange = createSelector(
    selectDataCorrelationByPeriodAndMmt,
    (data) => {
        if (data) {
            return {
                startDate: data?.start_date,
                finishDate: data?.end_date,
            } as DateRange;
        }
        return null;
    }
);

export const selectInitCorrelationData = createSelector(
    selectAllPostsAnalysis,
    selectDataCorrelationByPeriodAndMmt,
    (posts, data) => {
        if (posts.length && data) {
            return { posts, data };
        }
        return null;
    }
);
export const selectCorrelationDataById = (id) =>
    createSelector(selectDataCorrelationByPeriodAndMmt, (data) => data[id] ?? null);
export const selectActiveCorrelationPostsForMap = createSelector(
    selectCorrelationActivePost,
    selectCorrelationActivePair,
    selectCurrentTab,
    (postId, activePair, currentTab) => {
        const result = [];
        if (postId && currentTab === ANALYSIS_PAGES.posts) {
            result.push(postId);
        } else if (activePair && currentTab === ANALYSIS_PAGES.links) {
            result.push(activePair.first_post);
            result.push(activePair.second_post);
        }

        return result;
    }
);
export const selectCorrelationPostValue = (postId) =>
    createSelector(
        selectActiveCorrelationPostsForMap,
        selectDataCorrelationByPeriodAndMmt,
        selectCurrentTab,
        (selectedIds, data, currentTab) => {
            if (currentTab === ANALYSIS_PAGES.posts && selectedIds.length === 1 && data) {
                const value =
                    selectedIds[0] === postId
                        ? null
                        : data.coefficients.hasOwnProperty(selectedIds[0])
                        ? data.coefficients[selectedIds[0]][postId]
                        : null;
                return value ?? null;
            }
            return null;
        }
    );
export const selectDataForRouting = createSelector(
    selectCorrelationActivePost,
    selectCorrelationActivePair,
    selectCurrentPeriod,
    selectCurrentMmt,
    (activePost, activePair, period, mmt) => ({ activePost, activePair, period, mmt })
);
export const selectSourceLineData = createSelector(
    selectActiveCorrelationPostsForMap,
    selectAllPostsDic,
    selectCorrelationAnalysisData,
    selectDataCorrelationByPeriodAndMmt,
    selectCurrentTab,
    selectPostsDistance,
    selectAllPostsAnalysis,
    (selectedIds, posts, data, dataCorrelation, tab, distData, allowPosts) => {
        const result = [];
        if (selectedIds.length && dataCorrelation) {
            if (selectedIds.length === 1 && tab === ANALYSIS_PAGES.posts) {
                const currentPost = posts[selectedIds[0]];
                if (currentPost) {
                    const isAllow = allowPosts.find((v) => v.id === currentPost.id);
                    const data = dataCorrelation?.coefficients[currentPost.id];
                    if (isAllow && data) {
                        Object.keys(data).forEach((id) => {
                            const value = data[id];
                            const isAllowPost = allowPosts.find((v) => v.id === id);
                            if (isAllowPost && value) {
                                const secondPost = posts[id];
                                const dist = distData[currentPost.id][id] ?? null;
                                const dataLine = getLineData(currentPost, secondPost, value, dist);
                                result.push(dataLine);
                            }
                        });
                    }
                }
            } else if (selectedIds.length === 2 && tab === ANALYSIS_PAGES.links) {
                const firstPost = posts[selectedIds[0]];
                const secondPost = posts[selectedIds[1]];
                if (firstPost && secondPost) {
                    const value = dataCorrelation?.coefficients[firstPost.id][secondPost.id];
                    if (value) {
                        const dist = distData[firstPost.id][secondPost.id] ?? null;
                        const data = getLineData(firstPost, secondPost, value, dist);
                        result.push(data);
                    }
                }
            }
        }

        return result;
    }
);

export const selectHoverPinsForMap = createSelector(
    selectHoverCorrelationPosts,
    selectActiveHoverLine,
    (hoverPosts, hoverLine) => {
        if (hoverLine?.length) {
            return [hoverLine[0].properties.first, hoverLine[0].properties.second];
        }
        return hoverPosts;
    }
);
export const selectGeoParamsByActivePair = createSelector(
    selectCorrelationActivePair,
    selectAllPostsDic,
    selectMapLoaded,
    (activePair, posts, mapLoaded) => {
        if (activePair && mapLoaded) {
            const firstPost = posts[activePair.first_post];
            const secondPost = posts[activePair.second_post];
            if (firstPost?.geometry?.coordinates && secondPost?.geometry?.coordinates) {
                const center = getLatLngCenter(
                    firstPost.geometry.coordinates,
                    secondPost.geometry.coordinates
                );
                const zoomLevel = getZoomLevelByDistance(activePair.dist);
                return {
                    center,
                    zoomLevel,
                };
            }

            return null;
        }

        return null;
    }
);
export const selectGeoParamsByActivePost = createSelector(
    selectCorrelationActivePost,
    selectAllPostsDic,
    selectPostsDistance,
    (activePost, posts, postsDist) => {
        if (activePost) {
            const coordinates = [];
            const post = posts[activePost];
            if (post?.geometry?.coordinates && postsDist) {
                const center = post.geometry.coordinates;
                const postDist = postsDist[activePost];
                const allDistance = Object.values(postDist) as number[];
                const maxDist = Math.max(...allDistance);
                const zoomLevel = getZoomLevelByGroup(maxDist);
                return {
                    center,
                    zoomLevel,
                };
            }

            return null;
        }

        return null;
    }
);
export const selectParamsForTimeline = createSelector(
    selectAnalysisDateRange,
    selectGroupId,
    selectCurrentMeasureScheme,
    selectCurrentMmt,
    selectCurrentPeriod,
    (dateRange, groupId, scheme, mmt, period) => {
        if (dateRange && period && groupId && scheme && mmt) {
            const params: QueryParams = {
                date__gt: dateRange.startDate,
                date__lt: dateRange.finishDate,
                interval: period === CorrelationPeriod.month ? '3' : '4',
                measure_scheme: scheme !== MeasureScheme.mpc ? scheme : MeasureScheme.default,
                concentration_in_mpc: (scheme === MeasureScheme.mpc).toString(),
                packet_type: mmt,
                obj_filter: 'outdoor_post',
            };
            return {
                groupId,
                params,
            };
        }

        return null;
    }
);

export const selectParamsForCorrelationDataByPosts = createSelector(
    selectAnalysisDateRange,
    selectGroupId,
    selectCurrentMmt,
    selectCurrentPeriod,
    selectCorrelationActivePair,
    (dateRange, groupId, mmt, period, activePair) => {
        if (dateRange && period && groupId && mmt && activePair) {
            const first = activePair ? activePair.first_post : null;
            const second = activePair ? activePair.second_post : null;
            if (first && second) {
                const params: QueryParams = {
                    date__gt: dateRange.startDate,
                    date__lt: dateRange.finishDate,
                    interval: period === CorrelationPeriod.month ? '3' : '4',
                    group_id: groupId,
                    packet_type: mmt,
                    posts: `${first},${second}`,
                };
                return params;
            }
            return null;
        }
        return null;
    }
);

export const selectRadarChartDataById = (id) =>
    createSelector(
        selectAllPostsDic,
        selectAllPostsAnalysis,
        selectDataCorrelationByPeriodAndMmt,
        (allPosts, posts, dataCorrelation) => {
            const data = dataCorrelation?.coefficients[id];
            const allowPostsIds = posts.map((v) => v.id);
            if (data && id && allPosts[id]) {
                const posts = [allPosts[id]];
                const labels = [allPosts[id].name];
                const values = Object.values(data);
                Object.keys(data).forEach((post_id, index) => {
                    const post = allPosts[post_id];
                    const isExcludePost = allowPostsIds.includes(post_id);
                    if (isExcludePost && post && post_id !== id && values[index] !== null) {
                        posts.push(post);
                        labels.push(post.name);
                    }
                });

                const datasets = [];
                posts.forEach((post) => {
                    if (post.id !== id && data[post.id] !== null) {
                        const value = data[post.id] ?? null;
                        datasets.push(value);
                    }
                });
                return {
                    datasets,
                    labels,
                };
            }

            return {
                datasets: [],
                labels: [],
            };
        }
    );

export const selectPostsLineChartData = createSelector(
    selectPostsTimelineDic,
    selectCorrelationActivePair,
    (timelineData, activePair) => {
        if (timelineData && activePair) {
            const timeline = [];
            const first = activePair ? activePair.first_post : null;
            const second = activePair ? activePair.second_post : null;
            if (timelineData[first]) {
                timeline.push(timelineData[first]);
            }
            if (timelineData[second]) {
                timeline.push(timelineData[second]);
            }
            return timeline;
        }

        return null;
    }
);
export const selectBedCorrelationPosts = createSelector(
    selectAllPostsAnalysis,
    selectDataCorrelationByPeriodAndMmt,
    (posts, data) => {
        const result = [];
        const dataFilter = {};
        for (const key in data?.coefficients) {
            if (data?.coefficients.hasOwnProperty(key)) {
                const values = Object.values(data.coefficients[key]);
                const checkNotEmpty = values?.some((v) => v !== null);
                if (checkNotEmpty) {
                    dataFilter[key] = data?.coefficients[key];
                }
            }
        }

        if (dataFilter) {
            const values = Object.values(dataFilter);
            const keys = Object.keys(dataFilter);
            posts.forEach((post) => {
                if (keys.indexOf(post.id) >= 0) {
                    const sum = values.reduce(
                        (accumulator, currentValue) => accumulator + currentValue[post.id],
                        0
                    );
                    result.push({ id: post.id, sum });
                }
            });
        }
        result.sort((a, b) => {
            if (a.sum < b.sum) {
                return -1;
            }
            if (a.sum > b.sum) {
                return 1;
            }
            return 0;
        });
        if (result.length > 3) {
            result.length = 3;
        }
        return result;
    }
);
export const selectNearistDistanceByPost = (id) =>
    createSelector(selectPostsDistance, (distance) => {
        if (distance && distance[id]) {
            const values = Object.values(distance[id]) as number[];
            return Math.min(...values);
        }
    });
export const selectIsCompareModeAnalysis = createSelector(
    selectCurrentTab,
    selectCorrelationActivePair,
    (tab, activePair) => tab === ANALYSIS_PAGES.links && activePair === null
);
export const selectPolygonDataMap = createSelector(
    selectExtConfig,
    selectCurrentTab,
    selectAllPostsAnalysis,
    (config, tab, posts) => {
        const features = [];
        if (tab === ANALYSIS_PAGES.summary) {
            if (config?.analysisModuleSettings?.polygon?.features?.length) {
                config.analysisModuleSettings.polygon.features.forEach((feature) => {
                    const newFeature = {
                        ...feature,
                        properties: {
                            color: NETWORK_EXTEND_COLOR,
                            opacity: NETWORK_EXTEND_OPACITY,
                            border: NETWORK_EXTEND_BORDER,
                        },
                    };
                    features.push(newFeature);
                });
            }
            if (posts?.length) {
                posts.forEach((post) => {
                    features.push(getPostAreaData(post.geometry.coordinates));
                });
            }
        }

        return features.length
            ? {
                  type: 'FeatureCollection',
                  features,
              }
            : null;
    }
);
export const selectInfoPins = (textDefault, colorDefault) =>
    createSelector(selectExtConfig, selectCurrentTab, (config, tab) => {
        const result: ControlPointModel[] = [];
        if (tab === ANALYSIS_PAGES.summary && config?.analysisModuleSettings?.infoPins) {
            config.analysisModuleSettings.infoPins.forEach((pin) => {
                if (pin?.coordinates) {
                    result.push({
                        name: pin.name ?? textDefault,
                        lat: pin.coordinates[0],
                        lon: pin.coordinates[1],
                        color: pin.color ?? colorDefault,
                    });
                }
            });
        }
        return result;
    });
export const selectPinsTooltip = (pin, distText, unitsText) =>
    createSelector(
        selectCorrelationActivePair,
        selectCorrelationActivePost,
        selectPostsDistance,
        selectCurrentTab,
        (pair, activePost, distData, currentTab) => {
            let dist;
            if (
                (activePost ||
                    (pair && (pair.first_post === pin.id || pair.second_post === pin.id))) &&
                distData
            ) {
                let dist =
                    activePost && currentTab === ANALYSIS_PAGES.posts
                        ? distData[activePost][pin.id]
                        : pair &&
                          currentTab === ANALYSIS_PAGES.links &&
                          (pair.first_post === pin.id || pair.second_post === pin.id)
                        ? distData[pair.first_post][pair.second_post]
                        : null;
                if (dist) {
                    let distanceName = unitsText[1];
                    if (dist < 1000) {
                        distanceName = unitsText[0];
                        dist = dist.toFixed();
                    } else {
                        dist = Math.round(dist / 1000);
                    }
                    return dist ? `${pin.name}<br/>${distText}: ${dist} ${distanceName}` : pin.name;
                }
                return pin.name;
            }
            return pin?.name;
        }
    );
export const selectCorrelationIsValueMode = createSelector(
    selectCorrelationActivePost,
    selectCurrentTab,
    (post, tab) => !!post && tab === ANALYSIS_PAGES.posts
);
export const selectParamsForWindQuery = createSelector(selectGroupId, (groupId) => {
    if (groupId) {
        const params: QueryParams = {
            date: moment().startOf('hours').subtract(1, 'days').toISOString(),
            group: groupId,
        };
        return params;
    }
    return null;
});
