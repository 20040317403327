import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    selectActiveCorrelationPostsForMap,
    selectActiveHoverLine,
    selectAnalysisDates,
    selectCorrelationActivePair,
    selectCorrelationChartDataPair,
    selectCorrelationIsValueMode,
    selectCorrelationPostValue,
    selectCurrentMmt,
    selectHoverPinsForMap,
    selectInfoPins,
    selectIsLoadingChartPair,
    selectIsLoadingData,
    selectIsShowTimelineChart,
    selectPinsTooltip,
    selectPolygonDataMap,
    selectPostsLineChartData,
    selectSourceLineData,
    selectAnalysisDateRange,
    selectCurrentPeriod,
    selectCurrentTab,
    selectAllPostsAnalysis,
} from '@cityair/modules/analysis/store/selectors';
import {
    setActiveHoverLine,
    setActiveLine,
    setActiveCorrelationPair,
    setPeriod,
} from '@cityair/modules/analysis/store/actions';
import { Store } from '@ngrx/store';
import { GroupFeaturesService } from '@libs/shared-store';
import {
    COLORS_CORRELATION,
    CORRELATION_VALUE,
    getDigitsAfterDot,
    GroupExtConfigName,
    MeasureScheme,
    LANGUAGE,
    TEXTS,
} from '@libs/common';
import { MapAdapterService } from '@cityair/modules/map/services/map-adapter.service';
import { selectUserTimezoneLabel } from '@cityair/modules/core/store/selectors';

import {
    ControlPointModel,
    InfoPins,
    MapPins,
    MapPolygonInfo,
    PinModel,
    SourceLine,
} from '@cityair/namespace';
import { BehaviorSubject, fromEvent, Observable, of, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ANALYSIS_PAGES } from '@cityair/modules/analysis/models';
import { debounceTime, takeUntil, throttleTime } from 'rxjs/operators';
import { GeoJSON } from 'geojson';
import { INFO_PIN_DEFAULT_COLOR } from '@cityair/modules/analysis/constants';
import {
    SharedCoreActions,
    selectMapStyleTypes,
    selectCurrentMapType,
    selectSidebarIsOpen,
} from '@libs/shared-store';
import { selectMapStyleLoading } from '@cityair/modules/core/store/map/map.feature';

@Component({
    selector: 'cityair-analysis-map',
    templateUrl: './analysis-map.component.html',
    styleUrls: ['./analysis-map.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalysisMapComponent implements OnInit, OnDestroy {
    text = TEXTS.ANALYSIS.correlation;
    public textNames = TEXTS.NAMES;
    public noDataText = TEXTS.STATION_BLOCK.noData;
    currentLang = LANGUAGE;
    CORRELATION_VALUE = CORRELATION_VALUE;
    COLORS_CORRELATION = COLORS_CORRELATION;
    public showInfoPopup = false;
    selectCurrentMmt = selectCurrentMmt;
    selectSidebarIsOpen = selectSidebarIsOpen;
    selectAnalysisDates = selectAnalysisDates;
    selectPostsLineChartData = selectPostsLineChartData;
    selectCorrelationChartDataPair = selectCorrelationChartDataPair;
    selectIsLoadingData = selectIsLoadingData;
    selectIsLoadingChartPair = selectIsLoadingChartPair;
    selectCorrelationActivePair = selectCorrelationActivePair;
    selectIsShowTimelineChart = selectIsShowTimelineChart;
    selectAnalysisDateRange = selectAnalysisDateRange;
    selectCurrentPeriod = selectCurrentPeriod;
    selectCurrentTab = selectCurrentTab;
    selectUserTimezoneLabel = selectUserTimezoneLabel;
    setMapStyleType = SharedCoreActions.setMapStyleType;
    selectCurrentMapStyleType = selectCurrentMapType;
    selectMapStyleTypes = selectMapStyleTypes;
    selectMapStyleLoading = selectMapStyleLoading;
    ANALYSIS_PAGES = ANALYSIS_PAGES;

    currentMmt: string;
    charSize$ = new BehaviorSubject<{ width: number; left: number }>(null);
    currentWidthTimeline;
    public dates: string[] = [];
    public ngDestroyed$ = new Subject<void>();
    public activeLine: GeoJSON.Feature[] = [];
    mouseEnterLineTooltip = false;
    timeOutActiveLine = 700;
    public textConfig = TEXTS.CONFIG.timeZone;
    @ViewChild('timelineTrack', { read: ElementRef }) timelineTrack: ElementRef<HTMLElement>;
    @ViewChild('tooltipLine', { read: ElementRef }) tooltipLine: ElementRef<HTMLElement>;

    @HostListener('window:keydown.esc', ['$event'])
    handleKeyDownESC(event: KeyboardEvent) {
        if (this.showInfoPopup) {
            this.showInfoPopup = false;
        }
    }

    constructor(
        readonly store: Store,
        private groupFeaturesService: GroupFeaturesService,
        private mapAdapterService: MapAdapterService,
        private router: Router,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        this.store
            .select(selectActiveHoverLine)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((activeLine) => {
                this.activeLine = activeLine;
                _changeDetectorRef.markForCheck();
            });
    }

    getDigits = (measureScheme: MeasureScheme) => (mmt) => getDigitsAfterDot(measureScheme, mmt);

    ngOnInit() {
        this.store
            .select(selectAllPostsAnalysis)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                const distText = TEXTS.ANALYSIS.correlation.sortHeader.dist;
                const unitsDist = TEXTS.ANALYSIS.correlation.unitsDist;
                const correlationPins: MapPins = {
                    getPins: this.store.select(selectAllPostsAnalysis) as Observable<PinModel[]>,
                    getSelectedPinIds: this.store.select(selectActiveCorrelationPostsForMap),
                    getValue: (pin) => this.store.select(selectCorrelationPostValue(pin.id)),
                    clickCb: (pin) => {
                        this.router.navigate([
                            `/${ANALYSIS_PAGES.analysis}/${ANALYSIS_PAGES.posts}/${pin.id}`,
                        ]);
                    },
                    getColor: (pin) => of('#404655'),
                    getHover: this.store.select(selectHoverPinsForMap),
                    getTooltip: (pin) =>
                        this.store.select(selectPinsTooltip(pin, distText, unitsDist)),
                    isValueMode: this.store.select(selectCorrelationIsValueMode),
                };
                const sourceLine: SourceLine = {
                    getLine: this.store.select(selectSourceLineData) as Observable<
                        GeoJSON.Feature<GeoJSON.Geometry>[]
                    >,
                    mouseEnter: ($event, line) => {
                        this.updateLine($event, line);
                    },
                    mouseLeave: () => {
                        setTimeout(() => {
                            if (!this.mouseEnterLineTooltip) {
                                this.store.dispatch(setActiveHoverLine({ payload: [] }));
                            }
                        }, this.timeOutActiveLine);
                    },
                    mouseMove: ($event, line) => {
                        this.updateLine($event, line);
                    },
                    getHoverLine: this.store.select(selectActiveHoverLine) as Observable<
                        GeoJSON.Feature[]
                    >,
                };
                const moduleSettings = this.groupFeaturesService.getConfig(
                    GroupExtConfigName.analysisModuleSettings
                );

                const groupFeaturesLayer = this.groupFeaturesService.getConfig(
                    GroupExtConfigName.featuresLayer
                );

                const polygon: MapPolygonInfo = {
                    getPolygon: this.store.select(selectPolygonDataMap) as Observable<
                        GeoJSON.FeatureCollection<GeoJSON.Geometry>
                    >,
                };
                const infoPins: InfoPins = {
                    getPins: this.store.select(
                        selectInfoPins(TEXTS.ANALYSIS.mapLabelInfoPin, INFO_PIN_DEFAULT_COLOR)
                    ) as Observable<ControlPointModel[]>,
                };

                this.mapAdapterService.set({
                    zoom: moduleSettings?.mapSettings?.zoom || undefined,
                    center: moduleSettings?.mapSettings?.center || undefined,
                    groupFeaturesLayer,
                    correlationPins,
                    sourceLine,
                    polygon,
                    infoPins,
                });
            });
        this.store
            .select(selectCurrentMmt)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((mmt) => (this.currentMmt = mmt));
        this.store
            .select(selectAnalysisDates)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((dates) => {
                this.dates = dates;
                this._changeDetectorRef.detectChanges();
            });
        fromEvent(window, 'resize')
            .pipe(throttleTime(500), debounceTime(500), takeUntil(this.ngDestroyed$))
            .subscribe(() => {
                this.updateTrackPanel();
            });
    }

    ngOnDestroy(): void {
        this.mapAdapterService.set({
            correlationPins: null,
            sourceLine: null,
            polygon: null,
            infoPins: null,
        });
        this.ngDestroyed$.next();
    }

    updateChartSize(value: { width: number; left: number }) {
        this.charSize$.next(value);
        this.currentWidthTimeline = value?.width;
        this._changeDetectorRef.detectChanges();
    }

    updateTrackPanel(): void {
        if (this.timelineTrack) {
            this.currentWidthTimeline =
                this.timelineTrack.nativeElement.getBoundingClientRect().width;
            this._changeDetectorRef.detectChanges();
        }
    }

    public updatePeriod($event) {
        const selected = $event.find((v) => v.selected);

        this.store.dispatch(setPeriod({ payload: selected.id }));
    }

    closeChart() {
        this.store.dispatch(setActiveCorrelationPair({ payload: null }));
        this.router.navigate([`/${ANALYSIS_PAGES.analysis}/${ANALYSIS_PAGES.links}`]);
    }

    closeLineTooltip() {
        this.store.dispatch(setActiveHoverLine({ payload: [] }));
    }

    onMouseEnterLineTooltip() {
        this.mouseEnterLineTooltip = true;
    }

    onMouseLeaveLineTooltip() {
        this.mouseEnterLineTooltip = false;
        this.store.dispatch(setActiveHoverLine({ payload: [] }));
    }

    goToLink(line) {
        this.store.dispatch(setActiveLine({ payload: line }));
    }

    private updateLine(event, line: GeoJSON.Feature<GeoJSON.Geometry>) {
        const currentPos = event?.originalEvent ? event?.originalEvent : event;
        const position = this.getPosition(currentPos);
        if (position) {
            line = {
                ...line,
                properties: { ...line.properties, position },
            };
        }
        this.store.dispatch(setActiveHoverLine({ payload: [line] }));
    }

    private getPosition(event: MouseEvent): { top: number; left: number } {
        let widthTooltip = 250;
        let heightTooltip = 175;
        if (this.tooltipLine) {
            const { width, height } = this.tooltipLine?.nativeElement?.getBoundingClientRect();
            widthTooltip = width;
            heightTooltip = height;
        }
        const left =
            widthTooltip + event.x < window.innerWidth ? event?.x + 20 : event?.x - widthTooltip;
        const top =
            heightTooltip + event.y < window.innerHeight
                ? event?.y - 20
                : event?.y - (heightTooltip + event.y - window.innerHeight + 20);
        return {
            top,
            left,
        };
    }
}
