<div class="forecast-wrapper">
    <div class="header">
        <div class="title-row">
            <div class="title">{{ translateText.mainTitle }}</div>
        </div>
        <div class="tabs-wrapper">
            <ca-page-tabs
                class="page__page-tabs"
                [tabs]="tabs"
                [selectedTab]="getActiveTab()"
                (showTab)="openPage($event)"
            ></ca-page-tabs>
        </div>
    </div>
    <div class="content">
        <router-outlet></router-outlet>
    </div>
</div>
