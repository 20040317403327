import { Observable } from 'rxjs';
import { LngLat } from 'mapbox-gl';
import { ColorZone, Geometry, BasicDataResponse, RegionApi, MeasureScheme } from '@libs/common';
import { Source } from '@cityair/modules/plumes/services/run/models';
import { GeoJSON } from 'geojson';
import { NetworkArea } from '@cityair/modules/network/models';

export interface PinModel extends BasicDataResponse {
    id: string;
    name: string;
    geometry: Geometry;
    location_id?: number;
}

export class ControlPointModel {
    id?: string;
    name: string;
    lat: number;
    lon: number;
    color?: string;
    type?: string;
}

export class UserItems {
    email: string;
    login: string;
    userId: number;
    roleId?: number;

    constructor() {
        this.userId = 0;
        this.email = '';
        this.login = '';
    }
}

export enum MoExportType {
    mo = 1,
    tza4 = 2,
    moIndoor = 3,
}

export class DownloadPopupData {
    type: MoExportType;
    title: string;
    ids: string[];
    mos?: { id: string; name: string }[];
    devices?: { id: number; serialNumber: string }[];
    currentTabInterval?: number;
}

export enum ModulesIds {
    forecast = 1,
    plumes = 2,
    indoor = 3,
}

export type ModelDownloadPopup = {
    timeBegin: number;
    timeEnd: number;
    downloadType: MoExportType;
    downloadTitle: string;
};

export class MoItemsDataToExcelRequestProps {
    timeBegin: number;
    timeEnd: number;
    moIds: string[];
    interval: number;
    type: number;
    unitsType: number;
    insertPdk: boolean;
}

export class StationDataToExcelRequestProps {
    type: number;
    timeBegin: number;
    timeEnd: number;
    ids: number[];
}

export class MapPins {
    getPins: Observable<PinModel[]>;
    selectDigitsAfterDot?: Observable<number>;
    getSelectedPinIds: Observable<string[]>;
    getValue: (pin: PinModel) => Observable<number>;
    getColor: (pin: PinModel) => Observable<string>;
    clickCb: (pin: PinModel) => void;
    getHover?: Observable<string[]>;
    getTooltip?: (pin: PinModel) => Observable<string>;
    isValueMode?: Observable<boolean>;
    isCollapsed?: (id: string) => Observable<boolean>;
}
export class SourceLine {
    getLine: Observable<GeoJSON.Feature<GeoJSON.Geometry>[]>;
    clickCb?: (line) => void;
    mouseEnter?: ($event, line) => void;
    mouseLeave?: () => void;
    mouseMove?: ($event, line) => void;
    getHoverLine?: Observable<GeoJSON.Feature[]>;
}
export class MapControlPins {
    getPins: Observable<any[]>;
    selectDigitsAfterDot?: Observable<number>;
    getSelectedPinIds: Observable<string[]>;
    getValue?: (pin: ControlPointModel) => Observable<number>;
    getColor?: (pin: ControlPointModel) => Observable<string>;
    isWD?: Observable<boolean>;
    clickCb: (pin: ControlPointModel) => void;
    dragEnd?: ($event, pin?: ControlPointModel) => void;
    isDraggable?: (pin: any) => Observable<boolean>;
    isCollapsed?: Observable<boolean>;
}
export class InfoPins {
    getPins: Observable<ControlPointModel[]>;
}
export class SourcePins {
    getPins: Observable<any[]>;
    isCollapsed?: (id: string) => Observable<boolean>;
}
export class RegionPins {
    getPins: Observable<RegionApi[]>;
}
export class MapPolygonInfo {
    getPolygon: Observable<GeoJSON.FeatureCollection<GeoJSON.Geometry>>;
}
export class MapInfoPin {
    getPins: Observable<ControlPointModel[]>;
}
export class MapCenterAndZoom {
    center: LngLat;
    zoom: number;
}
export class MapGridPolygon {
    get: Observable<GeoJSON.FeatureCollection<GeoJSON.Geometry>>;
    getScaleImg: any;
    mouseEnter?: ($event, grid) => void;
    mouseLeave?: () => void;
    mouseMove?: ($event, grid) => void;
    getHover?: Observable<NetworkArea>;
}
export interface IMapClick {
    isAllow: boolean;
    coordinates?: {
        lat: number;
        lon: number;
    };
}
