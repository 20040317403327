import {
    createFeature,
    createReducer,
    createSelector,
    createActionGroup,
    props,
    on,
    createFeatureSelector,
    emptyProps,
} from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
    BasicDeviceSourceResponse,
    BasicRolesResponse,
    DEFAULT_MAP_STYLES,
    DEFAULT_MEASURE_SCHEME,
    Device,
    getDeviceSourceName,
    Group,
    GroupUser,
    IBasicResponse,
    IExtraGroupInfoResponse,
    IExtraUserInfo,
    InfoMessage,
    Locality,
    MAIN_PAGES,
    MapStyleType,
    MeasureScheme,
    MeasuresZones,
    ITimeseriesDataItem,
    Post,
    User,
    UserSettings,
    ZONES,
} from '@libs/common';

import { getAvailableModules } from '../utils/utils';
import { selectGroupId } from './group.feature';
import { selectAllPosts, selectAllPostsDic } from './post.feature';

export type StoreSettingsPart = {
    measuresZones?: { [key in MeasureScheme]: MeasuresZones };
};

const SHARED_STATE_FEATURE_KEY = 'sharedCore';

function sortByNameLocality(a: Locality, b: Locality): number {
    return a.name.localeCompare(b.name);
}
const cityAdapter: EntityAdapter<Locality> = createEntityAdapter<Locality>({
    sortComparer: sortByNameLocality,
});
function sortBySerialNumberDevice(a: Device, b: Device): number {
    return a.serial_number.localeCompare(b.serial_number);
}
const deviceAdapter: EntityAdapter<Device> = createEntityAdapter<Device>({
    sortComparer: sortBySerialNumberDevice,
});
export const timelineAdapter: EntityAdapter<ITimeseriesDataItem> =
    createEntityAdapter<ITimeseriesDataItem>();
export const usersAdapter: EntityAdapter<GroupUser> = createEntityAdapter<GroupUser>();
export const SharedCoreActions = createActionGroup({
    source: 'SharedCore',
    events: {
        Init: emptyProps(),
        Noop: emptyProps(),
        'Set Current User': props<{ payload: User }>(),
        'Set User Info': props<{ payload: IBasicResponse }>(),
        'Set Group Info': props<{
            payload: any[];
            currentGroup: Group;
            myRole: BasicRolesResponse;
        }>(),
        'Set Measure Scheme': props<{ payload: MeasureScheme }>(),
        'Set Map Style Type': props<{ payload: MapStyleType }>(),
        'Set Info Message': props<{ payload: InfoMessage }>(),
        'Remove Info Message': props<{ payload: InfoMessage }>(),
        'Set Measure Zone Group': props<{ payload: StoreSettingsPart }>(),
        'Load Users List': emptyProps(),
        'Set Users List': props<{ payload: IBasicResponse }>(),
        'Set Is Loading Users': props<{ payload: boolean }>(),
        'Update User Settings': props<{ payload: UserSettings }>(),
        'Vanga Token Refresh': emptyProps(),
        'Vanga Token Updated': emptyProps(),
        'Vanga Token Update Error': emptyProps(),
        'Toggle Sidebar': props<{ payload: boolean }>(),
        'Log Out': emptyProps(),
    },
});
interface SharedCoreState {
    currentUser: User;
    roles: BasicRolesResponse[];
    measuresZones: { [key in MeasureScheme]: MeasuresZones };
    currentMeasureScheme: MeasureScheme;
    infoMessage: InfoMessage[];
    availableModule: MAIN_PAGES[];
    deviceSources: BasicDeviceSourceResponse[];
    cities: EntityState<Locality>;
    devices: EntityState<Device>;
    users: EntityState<GroupUser>;
    isLoadingUsers: boolean;
    currentMapType: MapStyleType;
    mapStyleTypes: MapStyleType[];
    sidebarIsOpen: boolean;
    vangaTokenIsLoading: boolean;
}
const initialState: SharedCoreState = {
    currentUser: null,
    roles: [],
    measuresZones: ZONES,
    currentMeasureScheme: DEFAULT_MEASURE_SCHEME,
    infoMessage: [],
    availableModule: [],
    deviceSources: null,
    cities: cityAdapter.getInitialState(),
    devices: deviceAdapter.getInitialState(),
    users: usersAdapter.getInitialState(),
    isLoadingUsers: true,
    currentMapType: MapStyleType.cityair,
    mapStyleTypes: DEFAULT_MAP_STYLES,
    sidebarIsOpen: false,
    vangaTokenIsLoading: false,
};
const featureSelector = createFeatureSelector<SharedCoreState>(SHARED_STATE_FEATURE_KEY);
export const selectAllCities = createSelector(featureSelector, (state) =>
    cityAdapter.getSelectors().selectAll(state.cities)
);
export const selectCityById = (id) =>
    createSelector(featureSelector, (state) => state.cities.entities[id] ?? null);

export const selectAllDevices = createSelector(featureSelector, (state) =>
    deviceAdapter.getSelectors().selectAll(state.devices)
);
export const selectDeviceByPostId = (id) =>
    createSelector(selectAllDevices, (devices) => devices.find((d) => d.ancestor?.id === id));

export const selectTotalDevices = createSelector(featureSelector, (state) =>
    deviceAdapter.getSelectors().selectTotal(state.devices)
);
export const selectDeviceList = createSelector(
    selectAllDevices,
    selectAllPostsDic,
    (devices, posts) => {
        const result = [];
        devices?.forEach((device) => {
            const post = device?.ancestor?.id ? posts[device?.ancestor?.id] ?? null : null;
            const item = Object.assign(
                { ...device },
                { post: post },
                {
                    arrMoName: [post?.name],
                    arrSerialNumber: [device.serial_number],
                }
            );
            result.push(item);
        });

        return result;
    }
);
export const selectAllUsers = createSelector(featureSelector, (state) =>
    usersAdapter.getSelectors().selectAll(state.users)
);

export const sharedStateFeature = createFeature({
    name: SHARED_STATE_FEATURE_KEY,
    reducer: createReducer(
        initialState,
        on(SharedCoreActions.setMapStyleType, (state, { payload }) => ({
            ...state,
            currentMapType: payload,
        })),
        on(SharedCoreActions.setMeasureScheme, (state, { payload }) => ({
            ...state,
            currentMeasureScheme: payload,
        })),
        on(SharedCoreActions.setUserInfo, (state, { payload }) => {
            const rolesResponse = payload?.meta?.extra as IExtraUserInfo;
            const roles = rolesResponse?.group_roles
                ? [...rolesResponse.group_roles].sort((a, b) => Number(a.id) - Number(b.id))
                : [];
            return {
                ...state,
                roles,
                currentUser: payload?.data as User,
            };
        }),
        on(SharedCoreActions.setInfoMessage, (state, { payload }) => ({
            ...state,
            infoMessage: state.infoMessage.concat(payload),
        })),
        on(SharedCoreActions.removeInfoMessage, (state, { payload }) => {
            const current = [...state.infoMessage].filter((item) => item.id !== payload?.id);
            return {
                ...state,
                infoMessage: current,
            };
        }),
        on(SharedCoreActions.setGroupInfo, (state, { payload, currentGroup, myRole }) => {
            const postsResponse = payload[0]?.data as Post[];
            const citiesResponse = payload[1]?.data as Locality[];
            const cities = cityAdapter.setMany(citiesResponse, state.cities);
            const devicesResponse = payload[2]?.data as Device[];
            const devices = deviceAdapter.setMany(devicesResponse, state.devices);
            const extraData = payload[2]?.meta.extra as IExtraGroupInfoResponse;
            const availableModule = getAvailableModules(
                currentGroup,
                myRole,
                postsResponse,
                citiesResponse,
                devicesResponse
            );
            const deviceSources = extraData.device_sources;
            return {
                ...state,
                availableModule: availableModule,
                cities,
                devices,
                deviceSources: deviceSources ?? null,
            };
        }),
        on(SharedCoreActions.setMeasureZoneGroup, (state, { payload }) => ({
            ...state,
            measuresZones: payload.measuresZones || state.measuresZones,
        })),
        on(SharedCoreActions.vangaTokenRefresh, (state) => ({
            ...state,
            vangaTokenIsLoading: true,
        })),
        on(SharedCoreActions.vangaTokenUpdated, (state) => ({
            ...state,
            vangaTokenIsLoading: false,
        })),
        on(SharedCoreActions.setIsLoadingUsers, (state, { payload }) => ({
            ...state,
            isLoadingUsers: payload,
        })),
        on(SharedCoreActions.setUsersList, (state, { payload }) =>  {
            const response = payload.data as GroupUser[];
            const users = usersAdapter.setMany(response, state.users);
            return {
                ...state,
                users,
                isLoadingUsers: false,
            };
        }),

        on(SharedCoreActions.toggleSidebar, (state, { payload }) => ({
            ...state,
            sidebarIsOpen: payload === null ? !state.sidebarIsOpen : payload,
        }))
    ),
    extraSelectors: ({ selectCurrentUser, selectRoles, selectDeviceSources }) => ({
        selectUserId: createSelector(selectCurrentUser, (user) => {
            return user?.id ?? null;
        }),
        selectUserScheme: createSelector(selectCurrentUser, (user) => {
            return user?.settings?.measure_scheme ?? null;
        }),
        selectUserTimezone: createSelector(selectCurrentUser, (user) => {
            return user?.settings?.timezone ?? null;
        }),
        selectDeviceSourceNameByType: (type: string) =>
            createSelector(
                selectDeviceSources,
                (sources) => sources?.find((source) => source.source_type === type)?.name
            ),
    }),
});
export const {
    selectCurrentUser,
    selectUserId,
    selectUserScheme,
    selectUserTimezone,
    selectRoles,
    selectMeasuresZones,
    selectCurrentMeasureScheme,
    selectInfoMessage,
    selectAvailableModule,
    selectDeviceSources,
    selectCurrentMapType,
    selectSidebarIsOpen,
    selectDeviceSourceNameByType,
    selectVangaTokenIsLoading,
} = sharedStateFeature;

export const selectUserRoleId = createSelector(selectCurrentUser, selectGroupId, (iAm, groupId) => {
    if (iAm && groupId) {
        return iAm?.group_roles[groupId] ?? null;
    }
    return null;
});
export const selectMyRole = createSelector(selectUserRoleId, selectRoles, (roleId, roles) => {
    if (roleId && roles !== null) {
        return roles.find((role) => role.id === roleId);
    }
    return null;
});
export const selectCurrentUserGroupId = createSelector(
    selectGroupId,
    selectCurrentUser,
    (groupId, iAm) => (groupId && iAm ? `${groupId}_${iAm.id}` : null)
);
export const selectPostList = createSelector(
    selectAllPosts,
    selectDeviceSources,
    selectAllDevices,
    selectAllCities,
    (posts, sources, devices, cities) => {
        const result = [];
        if (posts?.length && sources?.length) {
            posts.forEach((post) => {
                const device = devices.find((d) => d.ancestor?.id === post.id);
                result.push({
                    id: post.id,
                    name: post.name,
                    serialNumber: device?.serial_number ?? null,
                    city: cities && cities?.length > 1 ? post.ancestor?.name : '',
                    device: device ?? null,
                    isNoDataSources: device === undefined,
                    deviceSourcesName: getDeviceSourceName(device, sources),
                });
            });

            return result;
        }
        return null;
    }
);
export const selectUsersForPost = (id) =>
    createSelector(selectAllUsers, (users) => {
        if (users.length) {
            return users
                .filter((user) => user?.group_role?.details?.posts.indexOf(id) >= 0)
                ?.map((user) => user?.login);
        }
        return [];
    });
export const selectRoleNameById = (roleId, lang) =>
    createSelector(selectRoles, (roles) => {
        if (roleId && roles !== null) {
            const role = roles.find((role) => role.id === roleId);
            return lang === 'ru' ? role.name_ru : role.name;
        }
        return null;
    });