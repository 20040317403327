import {
    calculateHexagon,
    getDistanceInMeters,
    getCorrelationColor,
    getCorrelationLineWidth,
    Post,
} from '@libs/common';

import {
    PIN_AREA_COLOR,
    PIN_AREA_OPACITY,
    POLYGON_RADIUS,
} from './constants';
import { Feature } from 'geojson';

export function getLineData(
    firstPost: Post,
    secondPost: Post,
    value: number,
    dist: number
): Feature {
    return {
        type: 'Feature',
        geometry: {
            type: 'LineString',
            coordinates: [firstPost.geometry.coordinates, secondPost.geometry.coordinates],
        },
        properties: {
            id: `${firstPost.id}${secondPost.id}`,
            color: getCorrelationColor(value),
            width: getCorrelationLineWidth(value),
            first: firstPost.id,
            firstName: firstPost.name,
            second: secondPost.id,
            secondName: secondPost.name,
            dist,
        },
    };
}

export function getPostAreaData(coordinates) {
    const geometry = calculateHexagon(coordinates, POLYGON_RADIUS);
    return {
        type: 'Feature',
        properties: { color: PIN_AREA_COLOR, opacity: PIN_AREA_OPACITY, border: PIN_AREA_COLOR },
        geometry,
    };
}

export function getDistanceAllPosts(posts: Post[]) {
    let data = {};
    posts?.forEach((post) => {
        let result = {};
        posts?.forEach((current) => {
            if (current.id !== post.id) {
                const dist = getDistanceInMeters(
                    post.geometry.coordinates,
                    current.geometry.coordinates
                );
                result = { ...result, [current.id]: dist };
            }
        });
        data = { ...data, [post.id]: result };
    });
    return data;
}
