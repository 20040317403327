<div class="posts-wrapper">
     <div *ngIf="store.select(selectIsLoadingData) | async; else contentTemplate" style="padding-top: 60px">
        <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
    </div>
    <ng-template #contentTemplate>
        <div class="content">
            <ng-container *ngIf="store.select(selectErrorLoadCorrelationData) | async; else bodyTemplate">
                <div class="load-error-message" [innerHtml]="text.correlation.notFoundCorrelationData"></div>
            </ng-container>
            <ng-template #bodyTemplate>
                <div class="body">
                    <div class="search-wrapper">
                        <search-input-basic
                            #searchInput
                            [textPlaceholderInner]="text.posts.searchPlaceholder"
                            [textInner]="searchQuery"
                            [debounceTimeInner]="300"
                            (textChangeInner)="textChangeIn($event)"
                        ></search-input-basic>
                    </div>
                    <div class="table" *ngIf="store.select(selectAllPostsAnalysis) | async as postInfo">
                        <ng-container *ngIf="postInfo | multipleSearchfilter : ['name'] : searchQuery as result">
                            <ng-container *ngIf="!result.length else listTemplate">
                                <ng-container *ngIf="searchQuery; else noPosts">
                                    <div class="no-data-wrapper">
                                        <search-result-placeholder [clearText]="textClear" (clear)="searchQuery = ''"></search-result-placeholder>
                                    </div>
                                </ng-container>
                                <ng-template #noPosts>
                                    <non-data-screen
                                    [text]="text.posts.noFoundPost"
                                ></non-data-screen>
                                </ng-template>
                            </ng-container>
                            <ng-template #listTemplate>
                                <div class="list scroll" #contentWrapper>
                                    <div class="table-header">
                                        <shared-sort-list-item
                                            class="point_list__sort_name"
                                            (click)="setSortingCb('name')"
                                            [sortingDirection]="sortField === 'name' ? sortDirection : 0"
                                            [text]="text.summary.nameHeader"
                                        ></shared-sort-list-item>
                                    </div>
                                    <div class="post-wrapper"
                                         *ngFor="let post of result | orderBy : sortField : sortDirection"
                                         (click)="selectPost(post)"
                                         (mouseenter)="onMouseEnterPost(post.id)"
                                         (mouseleave)="onMouseLeavePost(post.id)"
                                         [ngClass]="{'active-post': currentId === post.id }"
                                    >
                                        <div class="row">
                                            <div class="name">
                                                <div class="post-name" *ngIf="store.select(selectPostNameById(post.id)) | async as name"
                                                >
                                                    {{name}}
                                                </div>
                                                <div class="post-location" *ngIf="store.select(selectPostParentNameIfNeed(post.id)) |async as parent"
                                                >
                                                    {{parent}}
                                                </div>
                                            </div>
                                            <div class="close-wrapper" *ngIf="currentId === post.id">
                                                    <cross-button [size]="'small'" [color]="'#ffffff'" [bgGradient]="true" (click)="close($event)"></cross-button>
                                            </div>
                                        </div>
                                        <cityair-correlation-details *ngIf="currentId === post.id"></cityair-correlation-details>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>
                    </div>
                </div>
            </ng-template>
        </div>
    </ng-template>
</div>
<div class="popup-wrapper" *ngIf="showInfoPopup">
    <shared-ui-info-correlation-popup (closePopup)="showInfoPopup = false"></shared-ui-info-correlation-popup>
</div>
