import * as moment from 'moment-timezone';
import { EventImpact, Region } from '@cityair/modules/impact/service/api-model-impact';
import { DateRangeImpact, CheckboxItem } from '@libs/common';
import { ImpactMapObjectType } from '@cityair/modules/impact/models';
import { TEXTS } from '@libs/common';
import { AQI, HUM, PRES, TEMP } from '@libs/common';
import { DomainConfigType } from '@cityair/modules/map/components/mapbox/domain-tiles-player/domain-config.type';
import { Substance } from '@cityair/modules/map/components/mapbox/domain-tiles-player/substance.enum';
export const NEW_CONTROL_POINT_OBJ_TYPE = 'new_control_point';
export const NUMBER_ROUND_COORDINATES = 6;
export const TIME_PREPARE_PLUMES = 240; // min
export const INIT_IMPACT_DATE: DateRangeImpact = {
    start: moment().startOf('day').subtract(3, 'months').toISOString(),
    end: moment().add(1, 'day').endOf('day').toISOString(),
};
export const INIT_IMPACT_EVENT_DATE: DateRangeImpact = {
    start: moment().startOf('day').subtract(21, 'days').toISOString(),
    end: moment().add(1, 'day').endOf('day').toISOString(),
};
export const INIT_IMPACT_SOURCE_DATE: DateRangeImpact = {
    start: moment().startOf('day').subtract(8, 'days').toISOString(),
    end: moment().add(1, 'day').endOf('day').toISOString(),
};

export const INIT_IMPACT_REGION_DATE: DateRangeImpact = {
    start: moment().startOf('day').subtract(8, 'days').toISOString(),
    end: moment().add(1, 'day').endOf('day').toISOString(),
};

export const INIT_IMPACT_CONTROL_POINT_DATE: DateRangeImpact = {
    start: moment().startOf('day').subtract(8, 'days').toISOString(),
    end: moment().add(1, 'day').endOf('day').toISOString(),
};
export const USE_IMPACT_API = false;
export const SOURCE_NUMBER_LIMIT_STATS_REGION = 5;
export const SOURCE_NUMBER_LIMIT_STATS_CONTROL_POINT = 5;

export const IMPACT_CHART_COLOR_MMT = {
    PM25: '#9966CB',
    PM10: '#66B333',
    CO: '#FF6319',
    SO2: '#F1C21F',
    NO: '#FF7344',
    NO2: '#448EEC',
    H2S: '#FDA7CE',
    default: '#808183',
};
const coordinateNskLens = [
    [82.60930331812233, 55.15564485440982],
    [83.23883947538047, 55.159953110156955],
    [83.24354615175935, 54.800249620260885],
    [82.61954524020439, 54.79597871410759],
];
const coordinateIskitimLens = [
    [83.00637715551545, 54.80079788239517],
    [83.56025466394215, 54.80300563369517],
    [83.56189637352202, 54.48642737499425],
    [83.01227256039476, 54.48423640985169],
];
const coordinateIskitimMrr = [
    [83.014, 54.80079788239517],
    [83.56, 54.80300563369517],
    [83.56, 54.41],
    [83.014, 54.41],
];

export const LENS_NSK: DomainConfigType = {
    timeStep: 1200 * 1000, // 20 min
    slug: 'ru_novosibirsk_lens',
    substances: [Substance.PM25],
    coordinates: coordinateNskLens,
    start: '2024-11-20T17:20:00.00Z',
    end: '2024-11-21T16:00:00.00Z',
    altitudeIndex: '01',
    extent: [
        coordinateNskLens[0][0],
        coordinateNskLens[2][1],
        coordinateNskLens[1][0],
        coordinateNskLens[0][1],
    ],
};
export const LENS_ISKITIM: DomainConfigType = {
    timeStep: 1200 * 1000, // 20 min
    slug: 'ru_iskitim_lens',
    substances: [Substance.PM25],
    coordinates: coordinateIskitimLens,
    start: '2024-11-23T17:20:00.00Z',
    end: '2024-11-24T16:00:00.00Z',
    altitudeIndex: '01',
    extent: [
        coordinateIskitimLens[0][0],
        coordinateIskitimLens[2][1],
        coordinateIskitimLens[1][0],
        coordinateIskitimLens[0][1],
    ],
};
export const MRR_ISKITIM_SOURCE: DomainConfigType = {
    timeStep: 1200 * 1000, // 20 min
    slug: 'ru_iskitim_source',
    substances: [Substance.PM25],
    coordinates: coordinateIskitimMrr,
    start: '2024-11-24T06:20:00Z',
    end: '2024-11-24T12:00:00Z',
    altitudeIndex: '01',
    extent: [
        coordinateIskitimMrr[0][0],
        coordinateIskitimMrr[2][1],
        coordinateIskitimMrr[1][0],
        coordinateIskitimMrr[0][1],
    ],
};
export const MRR_ISKITIM: DomainConfigType = {
    timeStep: 1200 * 1000, // 20 min
    slug: 'ru_iskitim_mrr',
    substances: [Substance.PM25],
    coordinates: coordinateIskitimMrr,
    start: '2024-11-24T06:20:00Z',
    end: '2024-11-24T12:00:00Z',
    altitudeIndex: '01',
    extent: [
        coordinateIskitimMrr[0][0],
        coordinateIskitimMrr[2][1],
        coordinateIskitimMrr[1][0],
        coordinateIskitimMrr[0][1],
    ],
};
export const TEST_ANALYSIS_EVENTS_1: EventImpact = {
    id: 'AWZR-OZWA',
    start: '2024-09-18T08:20:00Z',
    end: '2024-09-18T14:00:00Z',
    forecast_end: null,
    param: 'PM25',
    pdk_value: 160,
    run_id: 39800,
    events: [
        {
            post_id: 'post_5',
            start: '2024-09-18T10:00:00Z',
            end: '2024-09-18T11:40:00Z',
            max_value: 163.525,
            data_source: [224, 221],
        },
        {
            post_id: 'post_1076',
            start: '2024-09-18T08:20:00Z',
            end: '2024-09-18T14:00:00Z',
            max_value: 238.4,
            data_source: [224, 221, 223],
        },
    ],
};
export const TEST_ANALYSIS_EVENTS_2: EventImpact = {
    id: 'BPSW-ABCB',
    start: '2024-11-28T06:20:00.000Z',
    end: '2024-11-28T12:00:00.000Z',
    forecast_end: null,
    param: 'PM25',
    pdk_value: 160,
    run_id: 41269,
    events: [
        {
            post_id: 'post_1123',
            start: '2024-11-28T10:20:00.000Z',
            end: '2024-11-28T12:00:00.000Z',
            max_value: 499,
            data_source: [
                251, 259, 263, 266, 268, 270, 273, 277, 279, 282, 283, 287, 288, 290, 293, 296,
            ],
        },
        {
            post_id: 'post_1124',
            start: '2024-11-28T08:20:00.000Z',
            end: '2024-11-28T12:00:00Z',
            max_value: 123.4,
            data_source: [259, 260, 261, 266, 268, 277, 283, 285, 287, 290, 292, 294, 297],
        },
        {
            post_id: 'post_1122',
            start: '2024-11-28T06:20:00.000Z',
            end: '2024-11-28T10:00:00.000Z',
            max_value: 194.2,
            data_source: [266, 270, 283, 290],
        },
    ],
};
export const REGISTER_EVENTS = [TEST_ANALYSIS_EVENTS_1];
export const ISKITIM_EVENTS = [TEST_ANALYSIS_EVENTS_2];
export const INIT_OBJECT_ON_MAP: CheckboxItem[] = [
    {
        id: ImpactMapObjectType.posts,
        label: TEXTS.IMPACT.mapObjectTitle[ImpactMapObjectType.posts],
        selected: true,
    },
    {
        id: ImpactMapObjectType.points,
        label: TEXTS.IMPACT.mapObjectTitle[ImpactMapObjectType.points],
        selected: true,
    },
    {
        id: ImpactMapObjectType.sources,
        label: TEXTS.IMPACT.mapObjectTitle[ImpactMapObjectType.sources],
        selected: true,
    },
    {
        id: ImpactMapObjectType.regions,
        label: TEXTS.IMPACT.mapObjectTitle[ImpactMapObjectType.regions],
        selected: true,
    },
];
export const DATE_FOR_CITY = {
    date__gt: moment().subtract(20, 'minutes').toISOString(),
    date__lt: moment().toISOString(),
    mmtsList: [{ id: TEMP }, { id: PRES }, { id: HUM }, { id: AQI }],
};

export const REGIONS: Region[] = [
    {
        id: 'region_2',
        group_id: '213',
        location_id: 'locality_2',
        name: 'Калининский',
        color: '#66B333',
        contribution: 4,
        posts: [],
        area: 24.5,
        density: 0,
    },
    {
        id: 'region_3',
        group_id: '213',
        location_id: 'locality_2',
        name: 'Заельцовский',
        color: '#66B333',
        contribution: 8,
        posts: ['post_475'],
        area: 71,
        density: 0,
    },
    {
        id: 'region_4',
        group_id: '213',
        location_id: 'locality_2',
        name: 'Железнодорожный',
        color: '#F1C21F',
        contribution: 18,
        posts: ['post_6', 'post_62'],
        area: 83,
        density: 0,
    },
    {
        id: 'region_5',
        group_id: '213',
        location_id: 'locality_2',
        name: 'Советский',
        color: '#FF7344',
        contribution: 32,
        posts: ['post_9', 'post_30', 'post_495', 'post_528'],
        area: 36.4,
        density: 2,
    },
    {
        id: 'region_6',
        group_id: '213',
        location_id: 'locality_2',
        name: 'Дзержинский',
        color: '#448EEC',
        contribution: 4,
        posts: [],
        area: 70.3,
        density: 0,
    },
    {
        id: 'region_7',
        group_id: '213',
        location_id: 'locality_2',
        name: 'Центральный',
        color: '#FDA7CE',
        contribution: 16,
        posts: ['post_2'],
        area: 6.2,
        density: 2,
    },
    {
        id: 'region_8',
        group_id: '213',
        location_id: 'locality_2',
        name: 'Барышевский',
        color: '#448EEC',
        contribution: 20,
        posts: ['post_3', 'post_3'],
        area: 128.6,
        density: 2,
    },
];

export const COMMON_SOURCES_СONTRIBUTION = [
    { id: 219, name: 'Приборостроительный завод', value: 12 },
    { id: 220, name: 'Цементный завод', value: 38 },
    { id: 221, name: 'Склад ТБО', value: 24 },
    { id: 222, name: 'ТЭЦ-4', value: 16 },
    { id: 223, name: 'ТЭЦ-3', value: 8 },
    { id: 224, name: 'ТЭЦ-2', value: 2 },
    { id: 229, name: 'ТЭЦ-5', value: 2 },
    { id: 228, name: 'Первомайская', value: 0 },
    { id: 226, name: 'АГЗС', value: 0 },
    { id: 225, name: 'Агрокомплекс', value: 0 },
];

export const MAX_CONCENTRATION_DEMO_DATA = {
    PM25: [8, 12, 18, 24, 20, 31, 55, 68, 100, 115, 140, 165, 290, 450, 92, 200, 50, 30],
    CO: [
        450, 1250, 980, 2245, 2874, 3540, 4725, 4165, 3870, 3560, 1421, 1215, 1379, 2150, 2365,
        1754,
    ],
    NO2: [75, 145, 250, 28, 78, 150, 370, 489, 374, 350, 247, 20, 6, 8, 12, 24, 8, 5],
};
