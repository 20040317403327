<div class="list-wrapper">
    <div *ngIf="store.select(selectIsLoadingData) | async; else listTemplate" style="padding-top: 60px">
        <ca-spinner [color]="'#BFE2F8'"></ca-spinner>
    </div>
    <ng-template #listTemplate>
        <div class="correlation-body">
            <div class="search-wrapper">
                <search-input-basic
                    #searchInput
                    [textPlaceholderInner]="textSearch"
                    [textInner]="searchQuery"
                    [debounceTimeInner]="300"
                    (textChangeInner)="textChangeIn($event)"
                ></search-input-basic>
            </div>
            <ng-container *ngIf="store.select(selectErrorLoadCorrelationData) | async; else bodyTemplate">
                <div class="load-message" [innerHtml]="text.notFoundCorrelationData"></div>
            </ng-container>
            <ng-template #bodyTemplate>
                <div class="data scroll" #contentWrapper *ngIf="correlationData?.length else emptyDataTemp" >
                    <div class="table-header">
                        <shared-sort-list-item
                            class="point_list__sort_name"
                            (click)="setSortingCb('name')"
                            [sortingDirection]="sortField === 'name' ? sortDirection : 0"
                            [text]="text.sortHeader.name"
                        ></shared-sort-list-item>
                        <shared-sort-list-item
                            class="point_list__sort_dist"
                            (click)="setSortingCb('dist')"
                            [sortingDirection]="sortField === 'dist' ? sortDirection : 0"
                            [text]="text.sortHeader.dist"
                        ></shared-sort-list-item>
                        <shared-sort-list-item
                            class="point_list__sort_value"
                            (click)="setSortingCb('value')"
                            [sortingDirection]="sortField === 'value' ? sortDirection : 0"
                            [text]="text.sortHeader.cor"
                        ></shared-sort-list-item>
                    </div>
                    <div class="content-wrapper">
                        <ng-container *ngIf="correlationData | multipleSearchfilter : ['first_name', 'second_name'] : searchQuery as result">
                            <ng-container *ngIf="!result.length && searchQuery  else listTemplate">
                                <div class="no-data-wrapper">
                                    <search-result-placeholder [clearText]="textClear" (clear)="searchQuery = ''"></search-result-placeholder>
                                </div>
                            </ng-container>
                            <ng-template #listTemplate>
                                <div class="content" *ngFor="let item of result | orderBy : sortField : sortDirection"
                                    [ngClass]="{'active-pair': isActive(item)}"
                                    (click)="selectPair(item)"
                                >
                                    <div class="names">
                                        <span style="padding-bottom: 9px;">{{item.first_name}}</span><span>{{item.second_name}}</span>
                                    </div>
                                    <div class="distance">{{item.dist | distance : currentLang}}</div>
                                    <div class="value">
                                        <div class="color-block" [style.background-color]="getColor(item.value / 100)"></div>
                                        <div class="number-value">
                                            {{item.value / 100 |  number : '1.2-2': currentLang}}
                                        </div>
                                    </div>
                                    <div class="actions">
                                        <div class="close-wrapper" *ngIf="isActive(item)">
                                            <cross-button [size]="'small'" [color]="'#ffffff'" [bgGradient]="true" (click)="closeChart($event)"></cross-button>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>
                    </div>
                </div>
                <ng-template #emptyDataTemp>
                    <div class="no-data-wrapper">
                        <non-data-screen [text]="text.noData"></non-data-screen>
                    </div>
                </ng-template>
            </ng-template>
        </div>
    </ng-template>
</div>
<div class="popup-wrapper" *ngIf="showInfoPopup">
    <shared-ui-info-correlation-popup (closePopup)="showInfoPopup = false"></shared-ui-info-correlation-popup>
</div>
