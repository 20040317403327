import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { ChartData, ChartType, ChartConfiguration } from 'chart.js';
import { formatNumber } from '@angular/common';
import { LANGUAGE } from '@libs/common';
import { customNumberFormat } from '@libs/common';
const tooltipFn = (data) => {
    const formatVal = formatNumber(Number(data.raw), LANGUAGE, '1.2-2');
    return `${data.dataset.label}: ${customNumberFormat(formatVal, 2, LANGUAGE)}`;
};

@Component({
    selector: 'cityair-area-chart',
    templateUrl: './area-chart.component.html',
    styleUrls: ['./area-chart.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AreaChartComponent implements OnChanges {
    @Input() data: {
        datasets: number[];
        labels: string[];
    };
    @Input() legend = true;
    @Input() emptyText = '';
    title: string;
    public notEmptyData = false;
    public radarChartOptions: ChartConfiguration['options'] = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 20,
            },
        },
        scales: {
            r: {
                ticks: {
                    callback: function (value) {
                        const formatVal = formatNumber(Number(value), LANGUAGE, '1.2-2');
                        return customNumberFormat(formatVal, 2, LANGUAGE);
                    },
                },
                min: 0,
                max: 1,
                pointLabels: {
                    color: '#404655',
                    font: {
                        size: 10,
                        family: 'Inter',
                        weight: 'bold',
                    },
                    callback: function (label) {
                        if (label.length >= 16) {
                            return label.substring(0, 13) + '...';
                        }
                        return label;
                    },
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: tooltipFn,
                },
            },
        },
    };

    public radarChartData: ChartData<'radar'>;
    public radarChartType: ChartType = 'radar';

    ngOnChanges() {
        this.title = this.data?.labels[0];
        const labels = [...this.data?.labels].slice(1);
        this.notEmptyData = this.data?.datasets?.some((v) => v !== 0);
        if (this.notEmptyData) {
            this.radarChartData = {
                datasets: [
                    {
                        label: this.data?.labels[0],
                        data: this.data?.datasets ?? [],
                        fill: true,
                        backgroundColor: ['rgba(101, 60, 216, 0.2)'],
                        borderColor: '#653CD8',
                        pointBackgroundColor: 'rgb(101, 60, 216)',
                    },
                ],
                labels: labels,
            };
        }
    }
}
