import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, Subscription, take } from 'rxjs';
import {
    AQI_IN_ANALYTICS_GROUP_IDS,
    AqiType,
    IntervalEnum,
    TabModel,
    isRU,
    TEXTS,
    MeasureScheme,
    GroupExtConfigName,
    getDigitsAfterDot,
    GroupChartConfig,
    GroupTooltipsMmt,
    getDaysByInterval,
    isDisabledTabByIntervalSwitcher,
} from '@libs/common';
import {
    selectGlobalMeasurement,
    selectIsCityMode,
    isCompareMode,
    selectMeasurementsForPins,
    selectPdkForChart,
    selectSchemeAndMeasure,
    selectSourcesAsFeatures,
    selectTimelineDateTimes,
    selectTypeInterval,
    selectZone,
    selectQualityDataMode,
    selectQualityDataTimeline,
    selectCurrentTimeByIndex,
    selectShowAqiWidget,
    selectUserTimezoneLabel,
} from '@cityair/modules/core/store/selectors';
import { selectGroupId, selectMapStyleTypes, selectCurrentMapType } from '@libs/shared-store';
import {
    destroyMainMap,
    initMainMap,
    intervalUpdate,
    loadCity,
    openInstantAqiFaq,
    removeFromComparison,
    setComparisonMode,
    setGlobalMeasurement,
    setTypeInterval,
    toggleShowQualityDataInfo,
    updateTimeIndex,
} from '@cityair/modules/core/store/actions';
import { MapAdapterService } from '../../services/map-adapter.service';
import { GroupFeaturesService } from '@libs/shared-store';
import { clearCompareList } from '@cityair/modules/core/store/compared-list/compared-list.actions';
import { selectMapStyleLoading } from '@cityair/modules/core/store/map/map.feature';
import {
    getModulePageConfig,
    selectCurrentMeasureScheme,
    selectSidebarIsOpen,
    SharedCoreActions,
} from '@libs/shared-store';

@Component({
    selector: 'default-map',
    templateUrl: './default-map.component.html',
    styleUrls: ['./default-map.component.less'],
})
export class DefaultMapComponent implements OnInit, OnDestroy {
    tabsInterval: TabModel[] = [
        {
            title: TEXTS.TIME_NAMES.min5,
            type: IntervalEnum.min5,
            tooltip: TEXTS.TIME_NAMES_TOOLTIP.min5,
            isDisabled: false,
        },
        {
            title: TEXTS.TIME_NAMES.min20,
            type: IntervalEnum.min20,
            tooltip: TEXTS.TIME_NAMES_TOOLTIP.min20,
            isDisabled: false,
        },
        {
            title: TEXTS.TIME_NAMES.hour1,
            type: IntervalEnum.hour,
            tooltip: TEXTS.TIME_NAMES_TOOLTIP.hour1,
            isDisabled: false,
        },
        {
            title: TEXTS.TIME_NAMES.day1,
            type: IntervalEnum.day,
            isDisabled: false,
        },
    ];

    moduleConfig$ = this.store.select(getModulePageConfig);

    selectedTabInterval = null;
    groupTooltip: GroupTooltipsMmt;
    private subscriptions: Subscription[] = [];

    TEXTS = TEXTS;
    AqiType = AqiType;
    isCompare = false;

    isRU = isRU;

    chartSingleSelect = false;
    chartMinMax: GroupChartConfig;
    selectZone = selectZone;
    selectMeasurementsForPins = selectMeasurementsForPins;
    selectGlobalMeasurement = selectGlobalMeasurement;
    setGlobalMeasurement = setGlobalMeasurement;
    selectMeasureScheme = selectCurrentMeasureScheme;
    selectSchemeAndMeasure = selectSchemeAndMeasure;
    IntervalEnum = IntervalEnum;
    selectSidebarIsOpen = selectSidebarIsOpen;
    selectShowAqiWidget = selectShowAqiWidget;
    selectSourcesAsFeatures = selectSourcesAsFeatures;
    selectTimelineDateTimes = selectTimelineDateTimes;
    selectIsComparisonModeActive = isCompareMode;
    selectPdkForChart = selectPdkForChart;
    removeFromComparison = removeFromComparison;
    updateTimeIndex = updateTimeIndex;
    selectCurrentTimeByIndex = selectCurrentTimeByIndex;
    selectIsCityMode = selectIsCityMode;
    selectQualityDataMode = selectQualityDataMode;
    toggleShowQualityDataInfo = toggleShowQualityDataInfo;
    selectQualityDataTimeline = selectQualityDataTimeline;
    intervalUpdate = intervalUpdate;
    selectUserTimezone = selectUserTimezoneLabel;
    setMapStyleType = SharedCoreActions.setMapStyleType;
    selectCurrentMapStyleType = selectCurrentMapType;
    selectMapStyleTypes = selectMapStyleTypes;
    selectMapStyleLoading = selectMapStyleLoading;

    constructor(
        private mapAdapterService: MapAdapterService,
        private groupFeaturesService: GroupFeaturesService,
        readonly store: Store
    ) {}

    ngOnInit() {
        this.store.dispatch(initMainMap());

        this.groupFeaturesService.readyBehavior$
            .pipe(
                filter((val) => val),
                take(1)
            )
            .subscribe(() => {
                this.chartSingleSelect = this.groupFeaturesService.getConfig(
                    GroupExtConfigName.chartSingleSelect
                );
                this.chartMinMax = this.groupFeaturesService.getGroupChartConfig();
                this.groupTooltip = this.groupFeaturesService.getConfig(
                    GroupExtConfigName.chartTooltipByMeasurements
                );
            });

        this.store
            .select(selectGroupId)
            .pipe(
                filter((groupId) => !!groupId),
                take(1)
            )
            .subscribe(async (groupId) => {
                this.mapAdapterService.setDefaultMap();
                // TODO for demo indore(atmos)
                const isDemoAtmos = AQI_IN_ANALYTICS_GROUP_IDS.indexOf(groupId) >= 0 ? true : false;
                const datesUpdate = this.store
                    .select(selectTimelineDateTimes)
                    .subscribe((dates) => {
                        if (dates.length) {
                            this.tabsInterval = this.correctTabInterval(
                                dates[0],
                                dates[dates.length - 1],
                                isDemoAtmos
                            );
                        }
                    });
                this.subscriptions.push(datesUpdate);
                const typeIntervalSub = this.store.select(selectTypeInterval).subscribe((type) => {
                    this.selectedTabInterval = this.tabsInterval.find((t) => t.type === type);
                });
                this.subscriptions.push(typeIntervalSub);
            });
    }

    getDigits = (measureScheme: MeasureScheme) => (mmt) => getDigitsAfterDot(measureScheme, mmt);

    ngOnDestroy() {
        this.store.dispatch(destroyMainMap());

        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    setDataInterval(tab: TabModel) {
        this.store.dispatch(setTypeInterval({ payload: tab.type }));
    }

    toggleComparisonMode(isActive: boolean) {
        if (!isActive) {
            this.store.dispatch(clearCompareList());
        }

        this.store.dispatch(setComparisonMode({ payload: isActive }));
    }

    openAqiFaqLink(aqiType: AqiType) {
        if (aqiType === AqiType.instant) {
            this.store.dispatch(openInstantAqiFaq());
        }
    }

    onCompare() {
        this.store.dispatch(setComparisonMode({ payload: true }));
    }

    public goToCity(cityId: string) {
        this.store.dispatch(loadCity({ cityId, centringMap: true }));
    }

    private correctTabInterval(start: string, end: string, isDemo: boolean): TabModel[] {
        const duration = getDaysByInterval(start, end);
        this.tabsInterval.map((tab) => {
            tab.isDisabled = isDisabledTabByIntervalSwitcher(tab.type, duration);
        });
        // TODO for demo indore(atmos)
        if (isDemo) {
            this.tabsInterval = this.tabsInterval.filter(
                (tab) => tab.type !== IntervalEnum.min5 && tab.type !== IntervalEnum.min20
            );
        }

        return this.tabsInterval;
    }
}
