import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    changeAnalyticPage,
    destroy,
    indoorUpdateTimeIndex,
    init,
    initOutdoorPins,
    openDownloadPopup,
    selectPoint,
    updateTimeRange,
} from '@cityair/modules/indoor/store/actions';
import {
    indoorSelectors,
    selectIndoorMeasureScheme,
} from '@cityair/modules/indoor/store/selectors';
import {
    AqiType,
    GroupExtConfigName,
    TabModel,
    TEXTS,
    ComfortConditionSettings,
    IndoorConfig,
} from '@libs/common';
import { filter, take, takeUntil } from 'rxjs/operators';
import { GroupFeaturesService, setModuleConfig } from '@libs/shared-store';
import {
    analyticType,
    indoorNamesToGlobal,
    MMT_TEXT,
    mmtGetUnit,
} from '@cityair/modules/indoor/config';
import { combineLatest, Subject } from 'rxjs';
import { onIsEnabledChart } from '@libs/shared-ui';
import { selectIsCityMode, selectUserTimezoneLabel } from '@cityair/modules/core/store/selectors';
import { selectGroupId } from '@libs/shared-store';
import { indoorModuleConfig } from '@cityair/modules/indoor/module-config';

@Component({
    selector: 'indoor-main',
    templateUrl: './indoor-main.component.html',
    styleUrls: ['./indoor-main.component.less'],
})
export class IndoorMainComponent implements OnInit, OnDestroy {
    private onDestroy$ = new Subject<void>();
    isMobile = false;
    indoorSelectors = indoorSelectors;
    analyticType = analyticType;
    selectPoint = selectPoint;
    changeAnalyticPage = changeAnalyticPage;
    indoorOpenDownloadPopup = openDownloadPopup;
    onIsEnabledChart = onIsEnabledChart;
    selectMeasureScheme = selectIndoorMeasureScheme;
    indoorUpdateTimeIndex = indoorUpdateTimeIndex;
    selectIsCityMode = selectIsCityMode;
    selectUserTimezone = selectUserTimezoneLabel;

    TEXTS = TEXTS;
    indoorNamesToGlobal = indoorNamesToGlobal;
    AqiType = AqiType;
    analyticIsOpen = false;
    isEmptyPosts = false;
    showMap = true;
    configMap: IndoorConfig;
    comfortConditionSettings: ComfortConditionSettings;
    showPopup = false;

    cbShowPopup = () => (this.showPopup = true);

    legend: {
        unit: string;
        measure: string;
        colors: string[];
        values: number[];
        min?: string;
        isLimit?: boolean;
    };

    tabs: TabModel[] = [
        {
            title: 'Map',
            type: true,
        },
        {
            title: 'Dashboard',
            type: false,
        },
    ];

    getActiveTab() {
        return this.showMap ? this.tabs[0] : this.tabs[1];
    }

    openPage(tab: TabModel) {
        this.showMap = tab.type;
    }

    constructor(public store: Store, groupFeaturesService: GroupFeaturesService) {
        groupFeaturesService.readyBehavior$
            .pipe(
                filter((isLoad) => isLoad),
                take(1)
            )
            .subscribe(() => {
                const configMap = groupFeaturesService.getConfig(GroupExtConfigName.indoorConfig);
                if (this.isMobile && configMap && configMap.zoom > 4) {
                    configMap.zoom = configMap.zoom - 3;
                    configMap.minZoom = configMap.minZoom - 3 > 0 ? configMap.minZoom - 3 : 1;
                }

                this.configMap = configMap;
                this.comfortConditionSettings = groupFeaturesService.getConfig(
                    GroupExtConfigName.ComfortConditionSettings
                );
            });

        combineLatest([
            store.select(indoorSelectors.currentMmtAndScheme),
            store.select(indoorSelectors.getZone),
        ])
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(([props, zone]) => {
                this.legend = {
                    unit: mmtGetUnit(props.scheme)[props.mmt],
                    measure: MMT_TEXT[props.mmt],
                    colors: zone.colors,
                    values: zone.val,
                    min: zone.min ?? '',
                    isLimit: zone.isLimit ?? false,
                };
            });
        this.store
            .select(selectGroupId)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((data) => {
                if (data) {
                    this.store.dispatch(init());
                    if (
                        this.configMap?.outdoorPinsConfig &&
                        this.configMap.outdoorPinsConfig.length
                    ) {
                        const ids = this.configMap.outdoorPinsConfig.map((item) => item.id);
                        this.store.dispatch(initOutdoorPins({ ids: ids }));
                    }
                }
            });
        store
            .select(indoorSelectors.posts)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((posts) => {
                if (posts) {
                    this.isEmptyPosts = posts?.length ? false : true;
                }
            });
    }

    updateTimeRange(data: { begin: number; end: number }) {
        this.store.dispatch(
            updateTimeRange({
                timeBegin: data.begin,
                timeEnd: data.end,
            })
        );
    }

    ngOnInit() {
        this.store.dispatch(setModuleConfig({ config: indoorModuleConfig }));
    }

    ngOnDestroy() {
        this.store.dispatch(onIsEnabledChart({ payload: false }));
        this.store.dispatch(destroy());
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
