import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import {
    AqiType,
    getDigitsAfterDot,
    IntervalEnum,
    MeasureScheme,
    isRU,
    TEXTS,
    GroupExtConfigName,
    GroupChartConfig,
} from '@libs/common';
import { MapControlPins, MapPins, SourcePins } from '@cityair/namespace';
import {
    selectGlobalMeasurement,
    selectIsCityMode,
    selectPdkForChart,
    selectSourcesAsFeatures,
    selectUserTimezoneLabel,
} from '@cityair/modules/core/store/selectors';
import { MapAdapterService } from '@cityair/modules/map/services/map-adapter.service';
import {
    loadCity,
    openInstantAqiFaq,
    removeFromComparison,
} from '@cityair/modules/core/store/actions';
import { ControlPoint } from '@cityair/modules/plumes/services/control-point/models';
import { Station } from '@cityair/modules/plumes/services/station/models';
import { GroupFeaturesService } from '@libs/shared-store';
import {
    currentPlumesMmt,
    getControlPointValues,
    getStationsForMap,
    getStationsValues,
    isDraggableControlPoint,
    isWindLayerAvailable,
    isWindShowPlumesOnMap,
    selectActiveControlPointAsList,
    selectActiveStationAsList,
    selectCentringRun,
    selectChartData,
    selectChartDataLoading,
    selectChartLabelMode,
    selectControlPointReports,
    selectDatesPlumes,
    selectIsDisableWindButton,
    selectLoadingAllData,
    selectPlumesCurrentHeight,
    selectPlumesHeights,
    selectPlumesSchema,
    selectPlumesSchemaZones,
    selectPlumesZone,
    selectPlumeTime,
    selectActiveRunSources,
} from '@cityair/modules/plumes/store/selectors';
import { PlumesState } from '@cityair/modules/plumes/store/reducers';
import {
    setActiveStation,
    setCoordinates,
    isDisabledWindButton,
    toggleWindLayer,
    updateTimeIndex,
    setHeight,
    clickControlPointsOnMap,
} from '@cityair/modules/plumes/store/actions';
import { getColorFromZone } from '@cityair/utils/utils';
import {
    NEW_CONTROL_POINT_OBJ_TYPE,
    NUMBER_ROUND_COORDINATES,
} from '@cityair/modules/plumes/constants';
import MapboxActions from '@cityair/modules/map/components/mapbox/mapboxActions';
import { LngLat } from 'mapbox-gl';
import { selectMapStyleLoading } from '@cityair/modules/core/store/map/map.feature';
import {
    selectCurrentMapType,
    selectCurrentMeasureScheme,
    selectMapStyleTypes,
    SharedCoreActions,
    selectSidebarIsOpen,
} from '@libs/shared-store';

@Component({
    selector: 'plumes-map',
    templateUrl: './plumes-map.component.html',
    styleUrls: ['./plumes-map.component.less'],
})
export class PlumesMapComponent implements OnInit, OnDestroy {
    labelMode = false;
    labelModeGroup = false;

    isRU = isRU;
    AqiType = AqiType;
    selectGlobalMeasurement = selectGlobalMeasurement;
    currentPlumesMmt = currentPlumesMmt;
    MeasureScheme = MeasureScheme;
    IntervalEnum = IntervalEnum;
    isLoading;
    selectSidebarIsOpen = selectSidebarIsOpen;
    selectSourcesAsFeatures = selectSourcesAsFeatures;
    selectDatesPlumes = selectDatesPlumes;
    selectPdkForChart = selectPdkForChart;
    removeFromComparison = removeFromComparison;
    selectMeasureScheme = selectCurrentMeasureScheme;
    updateTimeIndex = updateTimeIndex;
    selectPlumeTime = selectPlumeTime;
    selectChartDataLoading = selectChartDataLoading;
    selectChartData = selectChartData;
    selectPlumesSchemaZones = selectPlumesSchemaZones;
    selectPlumesSchema = selectPlumesSchema;
    selectPlumesZone = selectPlumesZone;
    selectIsCityMode = selectIsCityMode;
    isWindLayerAvailable = isWindLayerAvailable;
    selectIsDisableWindButton = selectIsDisableWindButton;
    selectPlumesHeights = selectPlumesHeights;
    selectPlumesCurrentHeight = selectPlumesCurrentHeight;
    selectUserTimezone = selectUserTimezoneLabel;
    setHeight = setHeight;
    setMapStyleType = SharedCoreActions.setMapStyleType;
    selectCurrentMapStyleType = selectCurrentMapType;
    selectMapStyleTypes = selectMapStyleTypes;
    selectMapStyleLoading = selectMapStyleLoading;

    private subscriptions: Subscription[] = [];

    chartSingleSelect: boolean;

    chartMinMax: GroupChartConfig;
    public textScheme = TEXTS.MEASURES_SCHEME;
    public textNames = TEXTS.NAMES;
    public ngDestroyed$ = new Subject<void>();
    public getDigitsAfterDot = getDigitsAfterDot;
    public showWindOnMap: boolean;
    public textWindLayer = TEXTS.PLUMES.windLayerButtonText;
    public windTooltipTexts = TEXTS.PLUMES.tooltipWIndButton;
    public isPlayerTimeline = false;

    constructor(
        readonly store: Store,
        readonly storePlumes: Store<PlumesState>,
        private groupFeaturesService: GroupFeaturesService,
        private mapAdapterService: MapAdapterService,
        private mapboxActions: MapboxActions
    ) {
        const centeringActive = this.store
            .select(selectCentringRun)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                if (data) {
                    const center = new LngLat(data.lng, data.lat);
                    setTimeout(() => this.mapboxActions.moveMap(center, data.zoom), 500);
                }
            });
        store
            .select(selectLoadingAllData)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.isLoading = data;
            });

        this.subscriptions.push(centeringActive);

        const labelModeSub = this.store
            .select(selectChartLabelMode)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.labelMode = !data && this.labelModeGroup ? true : data;
            });
        this.subscriptions.push(labelModeSub);
        const isShowWindSub = store
            .select(isWindShowPlumesOnMap)
            .pipe(takeUntil(this.ngDestroyed$))
            .subscribe((data) => {
                this.showWindOnMap = data;
            });
        this.subscriptions.push(isShowWindSub);

        this.subscriptions.push(
            this.store.select(isWindLayerAvailable).subscribe((isOn) => {
                if (!isOn) {
                    this.store.dispatch(toggleWindLayer({ payload: false }));
                }
            })
        );
    }

    async ngOnInit() {
        this.groupFeaturesService.readyBehavior$
            .pipe(
                filter((val) => val),
                take(1)
            )
            .subscribe(() => {
                this.labelModeGroup = this.chartSingleSelect = this.groupFeaturesService.getConfig(
                    GroupExtConfigName.chartSingleSelect
                );
                this.chartMinMax = this.groupFeaturesService.getGroupChartConfig();
            });

        const controlPointPins: MapControlPins = {
            getPins: this.storePlumes.select(selectControlPointReports),
            selectDigitsAfterDot: this.store
                .select(selectPlumesSchema)
                .pipe(map((data) => getDigitsAfterDot(data.scheme, data.mmt))),
            getSelectedPinIds: this.storePlumes.select(selectActiveControlPointAsList),
            getValue: (pin) => this.storePlumes.select(getControlPointValues(pin)),
            getColor: (pin) =>
                combineLatest([
                    this.storePlumes.select(selectPlumesZone),
                    this.storePlumes.select(getControlPointValues(pin)),
                ]).pipe(map(([zone, value]) => getColorFromZone(zone, value))),
            clickCb: (pin) => {
                const point = pin as ControlPoint;
                if (point?.obj === NEW_CONTROL_POINT_OBJ_TYPE) {
                    return;
                }
                this.store.dispatch(clickControlPointsOnMap({ payload: point }));
            },
            isDraggable: (pin) => this.storePlumes.select(isDraggableControlPoint(pin)),
            dragEnd: ($event, pin) => {
                const lat = Array.isArray($event) ? $event[1] : $event?._lngLat.lat;
                const lng = Array.isArray($event) ? $event[0] : $event?._lngLat.lng;
                this.store.dispatch(
                    setCoordinates({
                        payload: {
                            lat: parseFloat(lat.toFixed(NUMBER_ROUND_COORDINATES)),
                            lon: parseFloat(lng.toFixed(NUMBER_ROUND_COORDINATES)),
                        },
                    })
                );
            },
        };

        const postPins: MapPins = {
            getPins: this.store.select(getStationsForMap),
            selectDigitsAfterDot: this.store
                .select(selectPlumesSchema)
                .pipe(map((data) => getDigitsAfterDot(data.scheme, data.mmt))),
            getSelectedPinIds: this.storePlumes.select(selectActiveStationAsList),
            getValue: (pin) => this.store.select(getStationsValues(pin as Station)),
            getColor: (pin) =>
                combineLatest([
                    this.store.select(selectPlumesZone),
                    this.store.select(getStationsValues(pin as Station)),
                ]).pipe(map(([zone, value]) => getColorFromZone(zone, value))),

            clickCb: (pin) => {
                const station = pin as Station;
                if (station?.data?.measurements) {
                    this.store.dispatch(setActiveStation({ payload: station }));
                }
                const coors = station?.geometry?.coordinates;
                if (coors) {
                    this.mapboxActions.centringOnMarker(coors[1], coors[0], false);
                }
            },
        };

        const sourcePins: SourcePins = {
            getPins: this.store.select(selectActiveRunSources),
        };

        this.mapAdapterService.set({
            controlPointPins,
            postPins,
            sourcePins,
            cityPins: null,
            groupFeaturesLayer: await this.mapAdapterService.getDefaultGroupFeaturesLayer(),
            onMapZoomChanged: this.mapAdapterService.defaultMapZoomChanged,
        });
    }

    ngOnDestroy() {
        this.ngDestroyed$.next();
        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        });
        this.store.dispatch(toggleWindLayer({ payload: false }));
    }

    getDigits = (measureScheme: MeasureScheme) => (mmt) => getDigitsAfterDot(measureScheme, mmt);

    openAqiFaqLink(aqiType: AqiType) {
        if (aqiType === AqiType.instant) {
            this.store.dispatch(openInstantAqiFaq());
        }
    }

    public goToCity(cityId: string) {
        this.store.dispatch(loadCity({ cityId, centringMap: true }));
    }

    toggleWindLayer() {
        this.store.dispatch(toggleWindLayer({ payload: !this.showWindOnMap }));
    }

    public getPlayingState(isPlaying) {
        this.isPlayerTimeline = isPlaying;
        this.store.dispatch(isDisabledWindButton({ payload: isPlaying }));
        if (this.showWindOnMap && isPlaying) {
            this.store.dispatch(toggleWindLayer({ payload: false }));
        }
    }
}
