import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { NetworkActions } from '@cityair/modules/network/store/network.feature';
import {
    DATA_MODELING,
    DOMAIN_BBOX,
    MMTS_LIST,
    ZONE_NETWORK,
} from '@cityair/modules/network/constants';
import { getGridLineByBbox } from '@cityair/modules/network/utils';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import {
    selectAllPosts,
    BasicApiService,
    selectGroupId,
    getActionBasicError,
} from '@libs/shared-store';

@Injectable()
export class NetworkEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private basicApi: BasicApiService
    ) {}

    updateRunDate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NetworkActions.initModule),
            switchMap(() => [NetworkActions.setIsAvailable({ payload: true })])
        )
    );

    getDataModeling$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NetworkActions.initModule),
            withLatestFrom(this.store.select(selectGroupId), this.store.select(selectAllPosts)),
            tap(() => this.store.dispatch(NetworkActions.setLoading({ payload: true }))),
            switchMap(([action, groupId, posts]) => {
                const actions = [];
                const domain = DOMAIN_BBOX[groupId];
                const data = DATA_MODELING[groupId];
                if (domain && data) {
                    const result = getGridLineByBbox(domain, data, posts, ZONE_NETWORK);
                    actions.push(NetworkActions.setDomain({ payload: domain }));
                    actions.push(NetworkActions.setData({ payload: result }));
                    actions.push(NetworkActions.setLoading({ payload: false }));
                }
                return actions;
            })
        )
    );

    setMmtsList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NetworkActions.initModule),
            withLatestFrom(this.store.select(selectGroupId)),
            filter(([_, group]) => group !== null && MMTS_LIST[group]),
            switchMap(([action, groupId]) => [
                NetworkActions.setAllMmts({ payload: MMTS_LIST[groupId] }),
            ])
        )
    );

    loadRegionGroup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NetworkActions.initModule),
            withLatestFrom(this.store.select(selectGroupId)),
            filter(([_, selectGroupId]) => !!selectGroupId),
            switchMap(([_, groupId]) =>
                this.basicApi.getAllRegion(groupId).pipe(
                    switchMap((res: any) => [NetworkActions.setRegionsApi({ payload: res?.data })]),
                    catchError((errorResponse: HttpErrorResponse) => {
                        const errorAction = getActionBasicError(errorResponse);
                        return of(errorAction);
                    })
                )
            )
        )
    );

    getAnalysisData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NetworkActions.initModule),
            withLatestFrom(this.store.select(selectGroupId)),
            filter(([action, groupId]) => groupId !== null),
            switchMap(([action, groupId]) =>
                this.basicApi.getNetworkAnalysis(groupId).pipe(
                    switchMap((response) => [
                        NetworkActions.setAnalysisData({ payload: response?.data }),
                    ]),
                    catchError((errorResponse: HttpErrorResponse) => {
                        const errorAction = getActionBasicError(errorResponse);
                        return of(errorAction);
                    })
                )
            )
        )
    );
}
