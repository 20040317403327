import { CommonModule } from '@angular/common';
import { MobileHeaderComponent } from './mobile-header.component';
import { NgModule } from '@angular/core';
import { AqiLegendMobileComponent } from '@cityair/components/aqi-legend-mobile/aqi-legend-mobile.component';
import { DirectivesModule } from '@libs/shared-ui';
import { CalendarModule } from '@cityair/libs/common/calendar/calendar.module';
import { MobileProfileComponent } from '@cityair/components/mobile-profile/mobile-profile.component';
import { UserCardComponent } from '@cityair/components/user-card/user-card.component';
import { LoginModule } from '@cityair/modules/login/login.module';
import { GroupListItemMobileModule } from '@cityair/components/group-list-item-mobile/group-list-item-m.module';
import { FeedbackModule } from '@libs/shared-ui';
import { InstantAqiLegendMobileModule } from '../instant-aqi-legend-mobile/instant-aqi-legend-mobile.module';
import { AqiIndicatorSimpleModule } from '../aqi-indicator-simple/aqi-indicator-simple.module';
import { CapiAqiLegendMobileModule } from '../capi-aqi-legend-mobile/instant-aqi-legend-mobile.module';
import { ButtonModule } from '@libs/shared-ui';
import { ButtonsSaveCancelModule } from '@libs/shared-ui';
import { BackButtonModule } from '@libs/shared-ui';
import { CrossButtonModule } from '@libs/shared-ui';
import { AnalyticModule } from '@libs/shared-ui';

@NgModule({
    imports: [
        CommonModule,
        CalendarModule,
        DirectivesModule,
        LoginModule,
        GroupListItemMobileModule,
        FeedbackModule,
        BackButtonModule,
        InstantAqiLegendMobileModule,
        AqiIndicatorSimpleModule,
        CapiAqiLegendMobileModule,
        ButtonModule,
        ButtonsSaveCancelModule,
        CrossButtonModule,
        AnalyticModule,
    ],
    exports: [MobileHeaderComponent, MobileProfileComponent],
    declarations: [
        MobileHeaderComponent,
        AqiLegendMobileComponent,
        MobileProfileComponent,
        UserCardComponent,
    ],
})
export class MobileHeaderModule {}
