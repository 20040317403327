<cityair-analysis-map-elements></cityair-analysis-map-elements>
<div class="line-temp-wrapper">
    <div class="line-charts">
        <div
            class="top"
            [class.line-panel]="store.select(selectSidebarIsOpen) | async"
        >
            <color-legend
                class="analysis-legend-timeline"
                *ngIf="(store.select(selectCurrentTab) | async) !== ANALYSIS_PAGES.summary"
                [unit]="''"
                [measure]="text.shortLabel"
                [colors]="COLORS_CORRELATION"
                [values]="CORRELATION_VALUE"
                [digitsAfterDot]="1"
                [showInfo]="true"
                [isLimit]="true"
                [tooltipInfoText]="text.chartTitleCorrelation"
                (clickInfo)="showInfoPopup = !showInfoPopup"
            ></color-legend>
            <map-style-selector *ngIf="store.select(selectMapStyleTypes) | async as types"
                [types]="types"
                [currentType]="store.select(selectCurrentMapStyleType) | async"
                [loading]="store.select(selectMapStyleLoading) | async"
                (setType)="store.dispatch(setMapStyleType({payload: $event}))"
            ></map-style-selector>
            <div class="chart-wrapper" *ngIf="store.select(selectIsShowTimelineChart) | async">
                <cityair-line-chart class="charts"
                    [timeline]="store.select(selectPostsLineChartData) | async"
                    [dates]="store.select(selectAnalysisDates) | async"
                    [correlationData]="store.select(selectCorrelationChartDataPair) | async"
                    [mmt]="currentMmt"
                    [period]="store.select(selectCurrentPeriod) | async"
                    [title]="text.chartTitleMmt(textNames[currentMmt])"
                    [isLoading]="store.select(selectIsLoadingChartPair) | async"
                    [emptyMsg]="noDataText"
                    (sizeUpdate)="updateChartSize($event)"
                    (closeChart)="closeChart()"
                ></cityair-line-chart>
                <div class="line-chart-panel"  #timelineTrack
                        [style.width.px]="(charSize$ | async)?.width"
                    >
                    <div class="line-chart-wrapper">
                        <div class="line-chart-data">
                            <ng-container
                                *ngIf="dates | calculateCurrentTickFreq : currentWidthTimeline as tickFreq"
                            >
                                <ng-container *ngIf="dates">
                                    <div class="line-chart-block-wrapper" *ngFor="let item of dates; index as i;">
                                        <ng-container *ngIf="i | needRenderTick : tickFreq.freq">
                                            <div
                                                *ngIf="i | needRenderTick : tickFreq.formatFreq; else timeLabel"
                                                class="label-time-block"
                                            ><span class="time-line-track">{{ item | shortDateTimeline  }}</span>
                                            <div class="tick-block"></div>
                                            </div>
                                            <ng-template #timeLabel>
                                                <div class="label-time-block">
                                                    <span class="time-line-track">{{ item | caTime }}</span>
                                                </div>
                                                <div class="tick-block"></div>
                                            </ng-template>
                                        </ng-container>
                                    </div>
                                </ng-container>
                           </ng-container>
                        </div>
                        <div class="timezone-info" *ngIf="store.select(selectUserTimezoneLabel) | async as timezone">{{textConfig}}: {{timezone}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div #tooltipLine class="tooltip-line-hover" *ngIf="activeLine[0] as line"
     [ngStyle]="{ 'top': line?.properties?.position?.top + 'px', left: line?.properties?.position?.left + 'px' }">
    <div class="correlation-tooltip-wrapper"
        (mouseenter)="onMouseEnterLineTooltip()"
        (mouseleave)="onMouseLeaveLineTooltip()"
    >
        <div class="line-tooltip">
            <div class="pin-name">{{line?.properties?.firstName}}</div>
            <div class="pin-name">{{line?.properties?.secondName}}</div>
            <div class="distance">{{text.sortHeader.dist}}: <span>{{line?.properties?.dist | distance : currentLang}}</span></div>
            <button class="ca-button" (click)="goToLink(line)">{{text.goToLinks}}</button>
        </div>
    </div>
</div>
<div class="popup-wrapper" *ngIf="showInfoPopup">
    <shared-ui-info-correlation-popup (closePopup)="showInfoPopup = false"></shared-ui-info-correlation-popup>
</div>
