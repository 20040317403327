import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, Subscription, take } from 'rxjs';
import { IntervalEnum, TEXTS } from '@libs/common';
import { MapAdapterService } from '@cityair/modules/map/services/map-adapter.service';
import {
    selectGlobalMeasurement,
    selectIsCityMode,
    isCompareMode,
    selectPdkForChart,
    selectSourcesAsFeatures,
    selectCurrentTimeByIndex,
    selectTimelineDateTimes,
    selectQualityDataMode,
    selectQualityDataTimeline,
    selectUserTimezoneLabel,
} from '@cityair/modules/core/store/selectors';
import {
    updateTimeIndex,
    destroyMainMap,
    initMainMap,
    loadCity,
} from '@cityair/modules/core/store/actions';
import {
    getModulePageConfig,
    selectGroupId,
    selectCurrentMeasureScheme,
    selectSidebarIsOpen,
} from '@libs/shared-store';

@Component({
    selector: 'default-map-mobile',
    templateUrl: './default-map-mobile.component.html',
    styleUrls: ['./default-map-mobile.component.less'],
})
export class DefaultMapMobileComponent implements OnInit, OnDestroy {
    selectMeasureScheme = selectCurrentMeasureScheme;
    IntervalEnum = IntervalEnum;
    selectSidebarIsOpen = selectSidebarIsOpen;
    selectSourcesAsFeatures = selectSourcesAsFeatures;
    selectTimelineDateTimes = selectTimelineDateTimes;
    selectIsComparisonModeActive = isCompareMode;
    selectPdkForChart = selectPdkForChart;
    updateTimeIndex = updateTimeIndex;
    selectCurrentTimeByIndex = selectCurrentTimeByIndex;
    selectGlobalMeasurement = selectGlobalMeasurement;
    selectIsCityMode = selectIsCityMode;
    selectQualityDataMode = selectQualityDataMode;
    selectQualityDataTimeline = selectQualityDataTimeline;
    selectUserTimezone = selectUserTimezoneLabel;

    moduleConfig$ = this.store.select(getModulePageConfig);

    private subscriptions: Subscription[] = [];

    TEXTS = TEXTS;
    isCompare = false;

    constructor(public store: Store, private mapAdapterService: MapAdapterService) {}

    ngOnInit() {
        this.store.dispatch(initMainMap());

        this.store
            .select(selectGroupId)
            .pipe(
                filter((g) => !!g),
                take(1)
            )
            .subscribe(async (g) => {
                this.mapAdapterService.setDefaultMap();
            });
    }

    ngOnDestroy() {
        this.store.dispatch(destroyMainMap());

        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    public goToCity(cityId: string) {
        this.store.dispatch(loadCity({ cityId, centringMap: true }));
    }
}
