import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { StoreModule } from '@ngrx/store';

import { DirectivesModule, SearchResultPlaceholderComponent } from '@libs/shared-ui';
import { LittleComponentsModule } from '@libs/shared-ui';
import { PopupsModule } from '@libs/shared-ui';
import { TooltipsModule } from '@libs/shared-ui';
import { CardModule } from '@libs/shared-ui';
import { CalendarModule } from '@cityair/libs/common/calendar/calendar.module';
import { ButtonAddModule } from '@libs/shared-ui';
import { PageTabsModule } from '@libs/shared-ui';
import { SearchInputModule } from '@libs/shared-ui';
import { CardActionsModule } from '@libs/shared-ui';
import { SelectboxModule } from '@libs/shared-ui';
import { InputDateRangeModule } from '@libs/shared-ui';
import { InputDropdownModule } from '@libs/shared-ui';
import { InputModule } from '@libs/shared-ui';
import { LoadersModule } from '@libs/shared-ui';
import { TagsListModule } from '@libs/shared-ui';
import { SwitchItemModule } from '@libs/shared-ui';

import { GradientSliderModule } from '@libs/shared-ui';
import { Notifications } from './components/notifications/notifications.component';
import { Subscriptions } from './components/notifications/subscriptions/subscriptions.component';
import { SubscriptionsMeasurements } from './components/notifications/subscriptions/subscriptions-measurements/subscriptions-measurements.component';
import { SubscriptionsService } from './components/notifications/subscriptions/subscriptions-service/subscriptions-service.component';
import { SubscriptionCard } from './components/notifications/subscriptions/subscription-card/subscription-card.component';
import { SubscriptionCardMeasurements } from './components/notifications/subscriptions/subscription-card-measurements/subscription-card-measurements.component';
import { SubscriptionCardService } from './components/notifications/subscriptions/subscription-card-service/subscription-card-service.component';
import { Settings } from './components/notifications/settings/settings.component';
import { SettingsMeasurements } from './components/notifications/settings/settings-measurements/settings-measurements.component';
import { SettingsService } from './components/notifications/settings/settings-service/settings-service.component';
import { notificationsReducer } from './store/reducers';
import { MultipleSearchFnsPipe, PipesModule, ButtonModule, TextareaModule } from '@libs/shared-ui';
import { ButtonsSaveCancelModule } from '@libs/shared-ui';
import { EffectsModule } from '@ngrx/effects';
import { BaseChartDirective } from 'ng2-charts';
import { NotificationsEffects } from '@cityair/modules/notifications/store/effects';
import { EventsComponent } from '@cityair/modules/notifications/components/notifications/events/events.component';
import { EventItemComponent } from '@cityair/modules/notifications/components/notifications/events/event-item/event-item.component';
import { CrossButtonModule } from '@libs/shared-ui';

@NgModule({
    imports: [
        StoreModule.forFeature('notifications', notificationsReducer),
        EffectsModule.forFeature([NotificationsEffects]),
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DirectivesModule,
        PipesModule,
        ScrollingModule,
        LittleComponentsModule,
        PopupsModule,
        TooltipsModule,
        CardModule,
        CalendarModule,
        SearchInputModule,
        ButtonAddModule,
        PageTabsModule,
        CardActionsModule,
        SelectboxModule,
        InputDateRangeModule,
        LoadersModule,
        TagsListModule,
        SwitchItemModule,
        GradientSliderModule,
        ButtonModule,
        ButtonsSaveCancelModule,
        InputDropdownModule,
        InputModule,
        CrossButtonModule,
        BaseChartDirective,
        TextareaModule,
        SearchResultPlaceholderComponent,
    ],
    exports: [Notifications, Subscriptions, GradientSliderModule],
    providers: [MultipleSearchFnsPipe],
    declarations: [
        Notifications,
        Subscriptions,
        SubscriptionsMeasurements,
        SubscriptionsService,
        SubscriptionCard,
        SubscriptionCardMeasurements,
        SubscriptionCardService,
        Settings,
        SettingsMeasurements,
        SettingsService,
        EventsComponent,
        EventItemComponent,
    ],
})
export class NotificationsModule {}
