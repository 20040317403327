<div class="chart-content" *ngIf="notEmptyData else emptyChartData">
    <canvas baseChart
        [data]="radarChartData"
        [options]="radarChartOptions"
        [type]="radarChartType"
        [legend]="legend"
    ></canvas>
</div>
<ng-template #emptyChartData>
    <div class="no-data-wrapper" *ngIf="emptyText" [innerHtml]="emptyText">
    </div>
</ng-template>
